import React from 'react';
import { motion } from "framer-motion";
import { useInView } from 'react-intersection-observer';

import './Headline.css';
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";

const Headline = (props) => {

  const [headlineRef, headlineInView] = useInView();
  const navigate = useNavigate();

  return (
    <div className="Headline" id={props.title} style={{backgroundColor: props.styleBackground} } >
        <div className="Headline__container">
            <motion.div
              ref={headlineRef}
              animate={{ y: headlineInView ? 0 : 40, opacity: headlineInView ? 1 : 0, animationDelay: 0.5}}
              transition={{ duration: 0.5 }}
            >
              <h2 style={{color: props.styleTitle}} className='Headline__container__title'>{props.title}</h2>
              <p style={{color: props.styleParagraph}} className='Headline__container__text'>{props.paragraph}</p>
                {props.button ?
                    <Button onClick={() => navigate(props.buttonLink)} variant="outlined" size="medium" sx={{color:"black", border: "1px solid rgb(0 0 0 / 50%)", textTransform:'none',fontFamily:"MBCorpoATitle"}}>
                        {props.button}
                    </Button>
                    :
                    ''
                }
            </motion.div>

            {props.children}

        </div>
    </div>
  )
}

export default Headline
import img1 from '@/assets/images/gallery/gallery1.jpg';
import img2 from '@/assets/images/gallery/gallery2.jpg';
import img3 from '@/assets/images/gallery/gallery3.jpg';
import img4 from '@/assets/images/gallery/gallery4.jpg';

import {faCar, faGears, faLocationDot, faRoad} from "@fortawesome/free-solid-svg-icons";

export const GalleryData = [
    {
        link: '#',
        imageURL: img1,
        title: 'Découvrez la Classe A',
        paragraph1: 'Détails de la Classe A dans votre pays',
        paragraph2: 'Explorez maintenant',
        icon: faCar,
    },
    {
        link: `/configurator`,
        imageURL: img2,
        title: 'Configurateur',
        paragraph1: 'Votre Classe A personnelle',
        paragraph2: 'Configurez maintenant',
        icon: faGears,
    },
    {
        link: '#',
        imageURL: img3,
        title: 'Recherche de revendeur',
        paragraph1: 'Revendeurs Infinity-Life près de chez vous',
        paragraph2: 'Trouver un détaillant maintenant',
        icon: faLocationDot,
    },
    {
        link: '#',
        imageURL: img4,
        title: 'Essai routier',
        paragraph1: 'Testez la Classe A',
        paragraph2: 'Réservez un essai routier maintenant',
        icon: faRoad,
    },
]
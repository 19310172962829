import Axios from "./caller.service";

let getAllMaintenances = () => {
    return Axios.get('api/mecaMaintenances')
}

let getMyMaintenances = () => {
    return Axios.get('api/myMaintenances')
}

let checkToken = (token) => {
    return Axios.get('api/maintenances?token='+token)
}

let getMaintenanceById = (id) => {
    return Axios.get('api/maintenances/'+id)
}

let updateStatusMaintenance = (idMaintenance, formValue) => {

    return Axios.put('api/maintenances/'+idMaintenance, formValue)
}

let postMaintenance = (formValue) => {

    return Axios.post('api/maintenances/', formValue)
}

export const maintenanceService = {
    getAllMaintenances, getMyMaintenances, getMaintenanceById, updateStatusMaintenance, checkToken, postMaintenance
}
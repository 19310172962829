import React, {Fragment, useEffect, useState} from 'react'
import './Navbar.css';
import './NavbarSmallMenu.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faBars } from "@fortawesome/free-solid-svg-icons";
import { disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import {Divider, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import {Logout, Person, PersonAdd, Settings} from "@mui/icons-material";
import {accountService} from "../../_services/account.service";
import {Link, useNavigate} from "react-router-dom";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LoginIcon from '@mui/icons-material/Login';
import AssignmentIcon from '@mui/icons-material/Assignment';
import InventoryIcon from '@mui/icons-material/Inventory';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EngineeringIcon from '@mui/icons-material/Engineering';
import GarageIcon from '@mui/icons-material/Garage';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

const Navbar = () => {

    const [language, setLanguage] = useState('Deutsch');
    const [isMenu, setIsMenu] = useState(false);

    const navigate = useNavigate();

    const changeLanguage = (event) => {
        event.preventDefault();
        if(language === 'Deutsch'){
            setLanguage('English');
        }else{
            setLanguage('Deutsch');
        }
    }

    const viewMenu = (event) => {
        event.preventDefault();
        setIsMenu((prevState) => !prevState);
    }

    /*
    Line below referes to third party library called 'body-scroll-lock'. Prevents scroling, when small menu is open.
    Alternatively, overflow: hidden, on body element can be used in this situation.
    Manipulating body doesn't equal manipulating DOM, because react manipulates DOM only in App component.
    I used this library tho, due to overflow: hidden not working on IOS mobile
    */
    useEffect(() => {
        isMenu ? disableBodyScroll(document) : enableBodyScroll(document)
    },[isMenu])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [background, setBackground] = useState('black');
    const [backgroundCart, setBackgroundCart] = useState('black');

    const handleHover = () => {
        setBackground('#262626');
    };

    const handleMouseLeave = () => {
        setBackground('black');
    };

    const handleCartHover = () => {
        setBackgroundCart('#262626');
    };

    const handleCartMouseLeave = () => {
        setBackgroundCart('black');
    };

    const [anchorElPlanning, setAnchorElPlanning] = React.useState(null);
    const openPlanning = Boolean(anchorElPlanning);
    const handleClickPlanning = (event) => {
        setAnchorElPlanning(event.currentTarget);
    };
    const handleClosePlanning = () => {
        setAnchorElPlanning(null);
    };

    const [anchorElEntretiens, setAnchorElEntretiens] = React.useState(null);
    const openEntretiens = Boolean(anchorElEntretiens);
    const handleClickEntretiens = (event) => {
        setAnchorElEntretiens(event.currentTarget);
    };
    const handleCloseEntretiens = () => {
        setAnchorElEntretiens(null);
    };

    const [anchorElExpertises, setAnchorElExpertises] = React.useState(null);
    const openExpertises = Boolean(anchorElExpertises);
    const handleClickExpertises = (event) => {
        setAnchorElExpertises(event.currentTarget);
    };
    const handleCloseExpertises = () => {
        setAnchorElExpertises(null);
    };

  return (
    <Fragment>
    <nav className='nav' id='AClassSaloon'>
        <div className="navbar">
        <a href='/' className="navbar__left">
            <div className="navbar__left__logo">
                <img className="navbar__left__logoIMG" src={require("@/assets/images/logo-InfinityLife2.png")} alt="infinity_life_logo" />
            </div>
            <div className="navbar__left__logoname">
                <img className="navbar__left__logonameIMG" src={require("@/assets/images/napis2.png")}  alt="s" />
            </div>
        </a>
        <div className="navbar__right">
            <div className="navbar__right__upper">
                {/*<ul className='navbar__right__upper__list'>*/}
                {/*    <li><a href="#"><FontAwesomeIcon icon={faInfoCircle}/> <span className='big-screen'>Provider/Privacy Statement</span></a></li>*/}
                {/*    <li><a href="#" onClick={changeLanguage}>{language}</a></li>*/}
                {/*</ul>*/}

                <ul className='navbar__right__upper__list'>
                    {/*<li><a href="#"><FontAwesomeIcon icon={faInfoCircle}/> <span className='big-screen'>Provider/Privacy Statement</span></a></li>*/}
                    <li style={{alignSelf:'center'}}><a href="#">
                        <span className='big-screen' style={{fontSize:'10px'}}>© 2023. Infinity-Life AG. Tous droits réservés (mentions légales)</span></a>
                    </li>
                    <li >
                        <Tooltip title="Espace compte">
                            <IconButton

                                onClick={handleClick}
                                size="small"
                                sx={{ padding: 'unset', mr:1, ml:1 }}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <Avatar sx={{ width: 32, height: 32, backgroundColor: background, borderRadius:'0px'}} onMouseEnter={handleHover}
                                        onMouseLeave={handleMouseLeave} >
                                    <Person />
                                </Avatar>
                            </IconButton>
                        </Tooltip>
                    </li>
                </ul>

                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    {accountService.isLogged() ? [
                            <MenuItem key="profile" onClick={() => navigate('/garagiste')}>
                                <DashboardIcon sx={{mr:2}} /> Tableau de bord
                            </MenuItem>,
                            <Divider key="divider" />,
                            <MenuItem key="moncompte" onClick={() => navigate('/garagiste/entretiens')}>
                                <EngineeringIcon sx={{mr:2}} /> Historique Entretien
                            </MenuItem>,
                            <MenuItem key="moncompte" onClick={() => navigate('/garagiste/expertises')}>
                                <EngineeringIcon sx={{mr:2}} /> Historique Expertise
                            </MenuItem>,
                            <Divider key="divider" />,
                            <MenuItem key="deconnexion" onClick={() => accountService.logout()}>
                                {/*    Rajouter une notif pour montrer qu'on est déco     */}
                                <Logout sx={{mr:2}} /> Déconnexion
                            </MenuItem>,
                        ]: [
                        <MenuItem key="connexion" onClick={() => navigate('/auth/login')} >
                            <LoginIcon sx={{mr:2}} /> Connexion
                        </MenuItem>,
                        <MenuItem key="inscription" onClick={() => navigate('/auth/register')} >
                            <AssignmentIcon sx={{mr:2}} /> Inscription
                        </MenuItem>,
                        ]}
                </Menu>

            </div>
            <div className="navbar__right__lower">
                <ul className='navbar__right__lower__list'>
                    {/* MENU 1 */}
                    <li className='big-screen'>
                        <a
                            href={'#'}
                            onClick={handleClickPlanning}
                            aria-controls={openPlanning ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openPlanning ? 'true' : undefined}
                        >
                            Planning
                        </a>
                    </li>
                    <Menu
                        anchorEl={anchorElPlanning}
                        id="account-menu"
                        open={openPlanning}
                        onClose={handleClosePlanning}
                        onClick={handleClosePlanning}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 5,
                                width: {xs:'100%', md:'25%', lg:'25%'},
                                left:'5% !important',
                                top:'10% !important',
                                padding:'10px',
                                height:'80%',
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },

                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        sx={{background:'rgba(0,0,0,0.65)'}}
                    >
                        <MenuItem sx={{fontFamily:'MBCorpoATitle', fontSize:'2rem', textDecoration:'none'}}>
                            Planning
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={() => {
                            setIsMenu(false)
                            navigate('/')
                        }}>
                            Rendez-vous à venir
                        </MenuItem>

                    </Menu>
                    {/* -------------------------------------------- */}
                    {/* MENU 2 */}
                    <li className='big-screen'>
                        <a
                            href={'#'}
                            onClick={handleClickEntretiens}
                            aria-controls={openEntretiens ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openEntretiens ? 'true' : undefined}
                        >
                            Entretiens
                        </a>
                    </li>
                    <Menu
                        anchorEl={anchorElEntretiens}
                        id="account-menu"
                        open={openEntretiens}
                        onClose={handleCloseEntretiens}
                        onClick={handleCloseEntretiens}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 5,
                                width: {xs:'100%', md:'25%', lg:'25%'},
                                left:'5% !important',
                                top:'10% !important',
                                padding:'10px',
                                height:'80%',
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        sx={{background:'rgba(0,0,0,0.65)'}}
                    >
                        <MenuItem sx={{fontFamily:'MBCorpoATitle', fontSize:'2rem', textDecoration:'none'}}>
                            Entretiens
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={() => {
                            setIsMenu(false)
                            navigate('/garagiste/entretiens')
                        }}>
                            Tous les entretiens
                        </MenuItem>
                    </Menu>
                    {/* -------------------------------------------- */}
                    {/* MENU 3 */}
                    <li className='big-screen'>
                        <a
                            href={'#'}
                            onClick={handleClickExpertises}
                            aria-controls={openExpertises ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openExpertises ? 'true' : undefined}
                        >
                            Expertises
                        </a>
                    </li>
                    <Menu
                        anchorEl={anchorElExpertises}
                        id="account-menu"
                        open={openExpertises}
                        onClose={handleCloseExpertises}
                        onClick={handleCloseExpertises}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 5,
                                width: {xs:'100%', md:'25%', lg:'25%'},
                                left:'5% !important',
                                top:'10% !important',
                                padding:'10px',
                                height:'80%',
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        sx={{background:'rgba(0,0,0,0.65)'}}
                    >
                        <MenuItem sx={{fontFamily:'MBCorpoATitle', fontSize:'2rem', textDecoration:'none'}}>
                            Expertises
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={() => {
                            setIsMenu(false)
                            navigate('/garagiste/expertises')
                        }}>
                            Toutes les expertises
                        </MenuItem>
                    </Menu>
                    {/* -------------------------------------------- */}
                    <li className='media'><a href="#" onClick={viewMenu}><FontAwesomeIcon icon={faBars}/></a></li>
                </ul>
            </div>
        </div>
        </div>
    </nav>
    {isMenu && (
            <div className="navbar__smallMenu">
                <ul className="navbar__smallMenu__ul">
                    <li className="navbar__smallMenu__ul__li"><a href="#" onClick={handleClickPlanning}>Planning</a></li>
                    <li className="navbar__smallMenu__ul__li"><a href="#" onClick={handleClickEntretiens}>Entretiens</a></li>
                    <li className="navbar__smallMenu__ul__li"><a href="#" onClick={handleClickExpertises}>Expertises</a></li>
                </ul>
            </div>
        )
    }
    </Fragment>
  )
}

export default Navbar;
import React from 'react';
import './HeroPage.css';

const HeroPage = (props) => {

  return (
    <div className='HeroPage' style={{color:'black'}}>
        <div className='HeroPage__overlay'>
          <div className='HeroPage__overlay__upperText' id='AClassSaloon'>
            <h1 className='HeroPage__overlay__upperText__h1' >Berline Classe A.</h1>
            <p className='HeroPage__overlay__upperText__p'>Entrée compacte dans le monde des berlines haut de gamme.</p>
            </div>
          <div className='HeroPage__overlay__lowerText'>
            <div className='HeroPage__overlay__lowerText__left'>
              <p>Système d'infodivertissement</p>
              <p className='HeroPage__overlay__lowerText__left__large-text'>MBUX</p>
            </div>
            <div className='HeroPage__overlay__lowerText__right'>
              <p>Disponible en</p>
              <p className='HeroPage__overlay__lowerText__right__large-text'>Hybride rechargeable</p>
            </div>
          </div>
      </div>
      {props.children}
    </div>
  )
}

export default HeroPage
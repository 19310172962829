import React from 'react';
import { Document, Page, Text, View, Image, StyleSheet, Font } from '@react-pdf/renderer';
import font from '../assets/fonts/MBCorpoATitle.ttf'
import myImage from "@/images/InfinityLogoBlack2.png";

Font.register({ family: 'MBCorpoATitle', src: font })

const styles = StyleSheet.create({
    page: {
        fontFamily: 'MBCorpoATitle',
        padding: 40,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
    },
    logo: {
        width: 100,
        height: 100,
    },
    title: {
        fontSize: 24,
    },
    section: {
        marginBottom: 10,
    },
    sectionTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 5,
    },
    sectionContent: {
        fontSize: 14,
    },
    table: {
        marginTop: 20,
        borderWidth: 1,
        borderColor: '#000',
    },
    tableRow: {
        flexDirection: 'row',
    },
    tableHeader: {
        backgroundColor: '#f0f0f0',
        padding: 8,
        flex: 1,
        fontWeight: 'bold',
    },
    tableCell: {
        padding: 8,
        flex: 1,
    },
    footer: {
        position: 'absolute',
        bottom: 40,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
    },
    footerText: {
        textAlign: 'center',
    },
});

function PDFContentOrderPartAccessories(props) {
    const { invoiceNumber, invoiceDate, customerName, customerAddress, totalAmount, products } = props;


    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.header}>
                    <Image source={myImage} style={styles.logo} />
                    <Text style={styles.title}>Facture</Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>Informations du client :</Text>
                    <Text style={styles.sectionContent}>{customerName}</Text>
                    <Text style={styles.sectionContent}>{customerAddress}</Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>Détails de la facture :</Text>
                    <Text style={styles.sectionContent}>Numéro de facture : {invoiceNumber}</Text>
                    <Text style={styles.sectionContent}>Date de commande : {invoiceDate}</Text>
                    <Text style={styles.sectionContent}>Montant total : {totalAmount} €</Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>Produits :</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableHeader}>Produit</Text>
                            <Text style={styles.tableHeader}>Quantité</Text>
                            <Text style={styles.tableHeader}>Prix unitaire</Text>
                        </View>
                        {products.map((product, index) => (
                            <View style={styles.tableRow} key={index}>
                                <Text style={styles.tableCell}>{product.partAccessory.name}</Text>
                                <Text style={styles.tableCell}>{product.quantity}</Text>
                                <Text style={styles.tableCell}>{product.partAccessory.price}</Text>
                            </View>
                        ))}
                    </View>
                </View>
                {/* Pied de page */}
                <View style={styles.footer}>
                    <Text style={styles.footerText}>
                        @2023 - Infinity-Life
                    </Text>
                </View>
            </Page>
        </Document>
    );
}

export default PDFContentOrderPartAccessories;
import React from 'react';
import Login from "@/pages/Auth/Login";
import {Route, Routes} from "react-router-dom";
import Error from "@/_utils/Error";
import LoginGuard from "@/_helpers/LoginGuard";
import Register from "@/pages/Auth/Register";
import ForgetPassword from "./ForgetPassword";
import ResetPassword from "./ResetPassword";

const AuthRouter = () => {
    return (
        <Routes>
            <Route path="login" element={
                <LoginGuard>
                    <Login/>
                </LoginGuard>
            }/>
            <Route path="register" element={
                <LoginGuard>
                    <Register />
                </LoginGuard>
            }/>
            <Route path="forgetPassword" element={
                <LoginGuard>
                    <ForgetPassword />
                </LoginGuard>
            }/>
            <Route path="resetPassword/:token" element={
                <LoginGuard>
                    <ResetPassword />
                </LoginGuard>
            }/>
            <Route path="*" element={<Error/>}/>
        </Routes>
    );
};

export default AuthRouter;

import React from 'react';
import { formatPrice } from '@/_utils/utils';
// Styles
import './Footer.css';
// Icons
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';

export default function Footer(props) {
    return (
        <div className="footer">
            <div>
                <button
                    onClick={props.onClickPrev}
                    disabled={props.disablePrev}
                >
                    <MdNavigateBefore />
                    <span>Précedent</span>
                </button>
            </div>
            <div>
                <span>{formatPrice(props.totalPrice, '-')}</span>
            </div>
            <div>
                <button
                    onClick={props.onClickNext}
                    disabled={props.disableNext}
                >
                    <span>Suivant</span>
                    <MdNavigateNext />
                </button>
            </div>
        </div>
    )
}

import React, {useState} from 'react';
// Styles
import './Menu.css';
// Icons
import { FaMoon, FaSun } from 'react-icons/fa';
import fond from '@/assets/images/logo-InfinityLife2.png'

export default function Menu(props){

    const [state, setState] = useState({
        darkMode: false,
    });
    const handleOnChangeMode = () => {
        setState(prevState => ({
          ...prevState,
          darkMode: !prevState.darkMode,
        }));
    };

    const ModeIcon = state.darkMode ? FaSun : FaMoon;

    const brandLogo = state.darkMode
      ? fond
      : `${process.env.REACT_APP_PUBLIC_URL}/media/logo/logo-white-infinity.png`;

    return (
      <div className="menu-container">
        <a className="logo">
          <img src={brandLogo} alt="Configurator" />
        </a>
        <ul className="menu-nav">
          {props.items.map((item, i) => (
            <li
              key={item}
              onClick={() => props.onSelectItem(i)}
              className={props.selectedItem === i ? 'selected' : null}
            >
              <h2>{item}</h2>
            </li>
          ))}
        </ul>
        {/*<ModeIcon className="mode-icon" onClick={handleOnChangeMode} />*/}
        <ModeIcon className="mode-icon"/>
      </div>
    );
}



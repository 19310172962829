import React, { useState, useEffect } from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {
    Alert,
    AlertTitle, Collapse,
    Container,
    FormControl,
    InputLabel,
    List,
    ListItemButton, ListItemIcon, ListItemText,
    ListSubheader,
    Select
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {ExpandLess, ExpandMore, Person} from "@mui/icons-material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import 'leaflet/dist/leaflet.css';
import MapComp from "../../components/public/MapComp";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import myImage from "@/images/InfinityLogoBlack2.png";
import VillaIcon from '@mui/icons-material/Villa';
import AdjustIcon from '@mui/icons-material/Adjust';
import Button from "@mui/material/Button";
import {carService} from "../../_services/car.service";
import {useSnackbar} from "notistack";
import Loading from '@/_utils/Loading';

const CarOrderConfirm = () => {
    const { id } = useParams(); // Récupération de l'ID dans l'URL avec useParams de react-router-dom
    //TODO : test si la ref existe bien
    const [open, setOpen] = React.useState(true);
    const [isUserLoading, setIsUserLoading] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const [magasins, setMagasins] = useState([
        {
            id: 1,
            nom: "Infinity-Life Paris",
            latitude: 48.8584,
            longitude: 2.2945,
        },
        {
            id: 2,
            nom: "Infinity-Life Londres",
            latitude: 51.5074,
            longitude: -0.1278,
        },
        {
            id: 3,
            nom: "Infinity-Life Berlin",
            latitude: 52.5200,
            longitude: 13.4050,
        },
        // Ajoutez d'autres magasins ici
    ]);
    const [selectedMagasin, setSelectedMagasin] = useState('');
    const [zoom, setZoom] = useState(5);

    const [coords, setCoords] = useState([45.8534, 5.2488]);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        setZoom(15);
    }, [coords]);

    const handleChange = (e) => {
        const target = e.target.value;
        const magasin = magasins.find((m) => m.id === target);
        setCoords([magasin.latitude, magasin.longitude]);
        setSelectedMagasin(e.target.value);
    };

    const validChoice = (event) => {
        event.preventDefault()

        setIsUserLoading(true);
        const magasin = magasins.find((m) => m.id === selectedMagasin);
        const ref = id

        const formValue = {
            agenceName: magasin.nom,
            carOrderAdresse: magasin.nom,
            statut: "Confirmed"
        }

        carService.getCarByRef(ref)
            .then((res) => {

                carService.validAgence(res.data.id, formValue)
                    .then((res) => {
                        enqueueSnackbar('Votre commande est confirmé !', {
                            variant: 'success', // or variant: warning | error | info
                            autoHideDuration: 5000,
                            anchorOrigin : {horizontal: "right", vertical: "top"}
                        })
                        navigate('/user/dashboard/considering')
                    })
                    .catch((err) =>{
                        enqueueSnackbar("Une erreur s'est produite !", {
                            variant: 'error', // or variant: warning | error | info
                            autoHideDuration: 5000,
                            anchorOrigin : {horizontal: "right", vertical: "top"}
                        })
                        navigate('/')
                    })
                    .finally(() => {
                        setIsUserLoading(false);
                    })

            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <Box sx={{background:'white'}}>
            {isUserLoading ? (<Loading />) : null}
            <AppBar position="relative" sx={{background:'white', color:'black'}}>
                <Toolbar sx={{ml:10}}>
                    <Person sx={{ mr: 2 }} />
                    <Typography variant="h6" color="inherit" noWrap>
                        Finaliser votre achat
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container maxWidth='lg' sx={{paddingTop:'20px', paddingBottom:'20px', background:'white'}}>

                <Alert severity="info">
                    <AlertTitle>Info</AlertTitle>
                    Pour finaliser votre achat vous allez devoir choisir une concession pour  <strong>le paiement et la livraison de votre véhicule</strong>
                </Alert>

                <FormControl sx={{mt:3,mb:3, width:'100%' }}>
                    <InputLabel id="magasin-select-label">Magasin</InputLabel>
                    <Select
                        labelId="magasin-select-label"
                        id="magasin-select"
                        value={selectedMagasin}
                        onChange={handleChange}
                    >
                        {magasins.map((magasin) => (
                            <MenuItem key={magasin.id} value={magasin.id}>{magasin.nom}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {
                    selectedMagasin && (
                        <>

                        <MapComp selectedMagasin={selectedMagasin} coords={coords} zoom={zoom} magasins={magasins} />

                        <Card sx={{
                            justifyContent:'space-between',
                            mt:5,
                            display: { xs: 'block', sm: 'flex', md:'flex', lg:'flex' }
                        }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', width:'100%' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h5">
                                        Récapitulatif :
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                        Pour le choix de votre paiement et de livraison vous avez choisis :
                                        <List
                                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                            component="nav"
                                            aria-labelledby="nested-list-subheader"
                                            subheader={
                                                <ListSubheader component="div" id="nested-list-subheader">
                                                    Concession
                                                </ListSubheader>
                                            }
                                        >
                                            <ListItemButton onClick={handleClick}>
                                                <ListItemIcon>
                                                    <VillaIcon sx={{color: 'black'}} />
                                                </ListItemIcon>
                                                <ListItemText primary={magasins.find((m) => m.id === selectedMagasin).nom} />
                                                {open ? <ExpandLess /> : <ExpandMore />}
                                            </ListItemButton>
                                            <Collapse in={open} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <AdjustIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Modèle de la voiture" />
                                                    </ListItemButton>
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon>
                                                            <AdjustIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Ouvert de 09 heures à 18 heures" />
                                                    </ListItemButton>
                                                </List>
                                            </Collapse>
                                        </List>

                                    </Typography>
                                </CardContent>
                                <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
                                    <Button onClick={(e) => validChoice(e)} fullWidth variant="contained">Valider mon choix</Button>
                                </Box>
                            </Box>
                            <CardMedia
                                component="img"
                                sx={{ width: '100%' }}
                                image={myImage}
                                alt="Live from space album cover"
                            />
                        </Card>
                        </>

                    )
                }




            </Container>
        </Box>
    );
};

export default CarOrderConfirm;

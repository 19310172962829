import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Card, CardContent, CardMedia, Container, Skeleton} from "@mui/material";
import Grid from "@mui/material/Grid";
import {useNavigate} from "react-router-dom";
import {userService} from "@/_services/user.service";
import HeadlineAccount from "../global/HeadlineAccount";
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { useTheme } from '@mui/material/styles';
import Pagination from '@/_utils/Pagination'
import {useSnackbar} from "notistack";
import CardActionArea from "@mui/material/CardActionArea";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import PDFContentOrderPartAccessories from '../../_utils/PDFContentOrderPartAccessories';
import { PDFDownloadLink } from "@react-pdf/renderer";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const AccountContent = () => {

    // TODO : revoir les appels api 🛒
    const [isUserLoading, setIsUserLoading] = useState(false);
    const [user, setUser] = useState([]);
    const [datas, setDatas] = useState([]);
    const [activeSteps, setActiveSteps] = React.useState({});
    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage] = useState(3) //3 Per Page
    const [isLoading, setIsLoading] = useState(true);
    const [isOrderLoading, setIsOrderLoading] = useState(true);

    const flag = useRef(false)
    let navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if(flag.current === false)
        {
            setIsLoading(true);
            setIsOrderLoading(true);

            userService.getMe()
                .then((data) => {
                    setUser(data.data);
                })
                .catch((err) =>{
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    navigate('/')
                })
                .finally(() => {
                    setIsLoading(false);
                })

            userService.getMyOrders()
                .then((data) => {
                    console.log(data.data)
                    setDatas(data.data);
                })
                .finally(() => setIsOrderLoading(false))
        }

        return () => flag.current = true
    }, []);

    function formatDate(newDate) {
        const months = {
            0: 'Janvier',
            1: 'Février',
            2: 'Mars',
            3: 'Avril',
            4: 'Mai',
            5: 'Juin',
            6: 'Juillet',
            7: 'Août',
            8: 'Septembre',
            9: 'Octobre',
            10: 'Novembre',
            11: 'Décembre',
        }
        const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']

        const d = new Date(newDate)
        const year = d.getFullYear()
        const date = d.getDate()
        const monthIndex = d.getMonth()
        const monthName = months[d.getMonth()]
        const dayName = days[d.getDay()] // Thu
        const dayNumber = d.getDay() // Thu
        const formatted = `${date} ${monthName} ${year}`

        return formatted.toString()
    }

    const theme = useTheme();

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const orders = datas.slice(indexOfFirstPost, indexOfLastPost)
    const howManyPages = Math.ceil(datas.length/postsPerPage)

    if (isLoading || isOrderLoading) {
        return (
            <Container maxWidth='lg' sx={{paddingTop:'20px', paddingBottom:'20px', background:'transparent'}}>
                {/* For other variants, adjust the size with `width` and `height` */}
                <Skeleton variant="rounded" height={150} sx={{ m: 2 }} />
                <Skeleton variant="rounded" height={150} sx={{ m: 2 }} />
                <Skeleton variant="rounded" height={150} sx={{ m: 2 }} />
            </Container>
        );
    }

    return (
        <Container maxWidth='lg' sx={{paddingTop:'20px', paddingBottom:'20px', background:'transparent'}}>
            {datas.length === 0 ? (
                <Box>
                    <Grid item xs={12} md={6}>
                        <CardActionArea component="a" href="#">
                            <Card sx={{ display: {lg:'flex',xs:'block'} }}>
                                <CardContent sx={{ flex: 1 }}>
                                    <Typography component="span" variant="h5" sx={{fontSize:'1rem',lineHeight:'1.5rem', color:'#767676', marginRight:2, fontFamily:'MBCorpoATitle'}}>
                                        Vous n'avez actuellement aucune commande enregistrée
                                    </Typography>
                                    <br/>
                                    <br/>
                                    <Typography component="span" variant="h5" sx={{fontSize:'1rem',lineHeight:'1.5rem', color:'#767676'}}>
                                        <b>Infinity-Life me</b> vous propose de regarde le catalogue des accessoires.
                                    </Typography>
                                </CardContent>
                                <CardMedia sx={{textAlignLast:'center',alignSelf:'center', margin:1}}>
                                    <Button
                                        variant="contained"
                                        sx={{borderRadius:'inherit'}}
                                        startIcon={<CarRepairIcon />}
                                        size="large"
                                        onClick={() => {navigate('/accessoires')}}
                                    >
                                        Voir les Accessories
                                    </Button>
                                </CardMedia>
                            </Card>
                        </CardActionArea>
                    </Grid>
                </Box>
                ) :(
                <>
                    <h3>Mes commandes</h3>

                    <br/>

                    {orders.map((data) =>
                        <Box sx={{marginBottom:5 }} >
                            <Card sx={{ display: 'flex', background:'#f3f3f3', borderLeft:'3px solid #f3f3f3',borderRight:'3px solid #f3f3f3',  borderRadius:'10px 10px 0px 0px'}}>
                                <Grid container spacing={{ xs: 1, md: 2}} columns={{xs:1, sm:4, md:8}}>
                                    <Grid item xs={1} md={6}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <CardContent sx={{ flex: '1 0 auto'}}>
                                                <Typography component="div" variant="h5" sx={{fontFamily:'MBCorpoATitle' }}>
                                                    Commande du <span id={'orderDate'}>{formatDate(data.orderDate)}</span> - <span style={{color: data.statut === 'Confirmed' ? 'green' : 'red' }} id={'sendStatus'}>{data.statut}</span>
                                                </Typography>
                                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                                    Référence : <span id={'reference'}>{data.reference}</span>
                                                </Typography>
                                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                                    Vendu et expédié par : <span id={'brand'} style={{color:'blue'}}>Infinity-Life</span>
                                                </Typography>
                                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                                    <span id={'totalAmount'}>{data.totalAmount}</span> € payé par <span id={'cardType'}>{data.cardType}</span>
                                                </Typography>
                                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                                    Nombre d'article(s) dans la commande : <span id={'totalQte'}>{data.orderLines.length}</span>
                                                </Typography>
                                            </CardContent>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={1} md={2} sx={{alignSelf:'center'}}>

                                        <Box>
                                            <PDFDownloadLink document={<PDFContentOrderPartAccessories

                                                invoiceNumber={data.reference}
                                                invoiceDate={formatDate(data.orderDate)}
                                                customerName={user.firstname+ ' '+user.lastname}
                                                customerAddress={data.orderAdresse}
                                                totalAmount={data.totalAmount}
                                                products={data.orderLines}

                                            />} fileName="preuve-achat.pdf">
                                                <Button variant="outlined" size="large" sx={{width:'100%',mb:1, fontSize:'12px'}}>
                                                    Imprimer la preuve d'achat
                                                </Button>
                                            </PDFDownloadLink>

                                            <PDFDownloadLink document={<PDFContentOrderPartAccessories

                                                invoiceNumber={data.reference}
                                                invoiceDate={formatDate(data.orderDate)}
                                                customerName={user.firstname+ ' '+user.lastname}
                                                customerAddress={data.orderAdresse}
                                                totalAmount={data.totalAmount}
                                                products={data.orderLines}

                                            />} fileName="facture.pdf">
                                                <Button variant="outlined" size="large" sx={{width:'100%',mt:1, fontSize:'12px'}}>
                                                    Demander une facture
                                                </Button>
                                            </PDFDownloadLink>
                                        </Box>
                                    </Grid>

                                </Grid>
                            </Card>
                            {data.orderLines.map((orderLine, orderLineIndex) =>
                                <>
                                    <Card sx={{width:'100%', borderLeft:'3px solid #f3f3f3',borderRight:'3px solid #f3f3f3', borderRadius:'unset' }}  >
                                        <CardContent>
                                            <Typography sx={{ fontSize: 20, color:'green' }} gutterBottom>
                                                Livraison prévue entre le <span id={'firstDateExecepted'}>{formatDate(data.firstDateExpected)}</span> et le <span id={'secondDateExecepted'}>{formatDate(data.secondDateExpected)}</span>
                                            </Typography>
                                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                <b>Livraison à domicile :</b> <span id={'name'}>{user.firstname} {user.lastname}</span>, <span id={'adresse'}>{data.orderAdresse}</span>
                                            </Typography>
                                        </CardContent>
                                    </Card>

                                    <Card sx={{ display: 'flex', background:'white', padding:2, borderLeft:'3px solid #f3f3f3',borderRight:'3px solid #f3f3f3', borderRadius:'unset' }}>

                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={4} lg={4}>
                                                <Box sx={{width:'100%'}}>

                                                    <AutoPlaySwipeableViews
                                                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                                        index={activeSteps[orderLine.partAccessory.id] || 0}
                                                        onChangeIndex={(index) =>
                                                            setActiveSteps({
                                                                ...activeSteps,
                                                                [orderLine.partAccessory.id]: index,
                                                            })
                                                        }
                                                        enableMouseEvents
                                                    >
                                                        {orderLine.partAccessory.images.map((image, index) =>
                                                            <div>
                                                                {Math.abs((activeSteps[orderLine.partAccessory.id] ?? 0) - index) <= 2 ? (
                                                                    <Box
                                                                        component="img"
                                                                        sx={{
                                                                            height: 255,
                                                                            display: 'block',
                                                                            maxWidth: 400,
                                                                            overflow: 'hidden',
                                                                            width: '100%',
                                                                        }}
                                                                        // src={'https://mui.com/static/images/cards/live-from-space.jpg'}
                                                                        src={process.env.REACT_APP_PUBLIC_URL+image.contentUrl}
                                                                        alt={'Infinity'}
                                                                    />
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    </AutoPlaySwipeableViews>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={8} lg={8}>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', width:'100%'}}>

                                                    <CardContent sx={{ flex: '1 0 auto'  }}>
                                                        <Typography component="div" variant="h5">
                                                            <span id={'articleName'}><b>{orderLine.name}</b></span>
                                                        </Typography>
                                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                                            <span id={'totalAmount'}>{orderLine.partAccessory.price}</span> € / unit.
                                                        </Typography>
                                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                                            Quantité : <span id={'nbQuantity'}>{orderLine.quantity}</span>
                                                        </Typography>
                                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                                            Type :  <Button variant="contained" style={{background:'blue' ,borderRadius:'20px', fontSize:'10px', height:'15px', color:'white'}}>
                                                            {orderLine.partAccessory.type}
                                                        </Button>
                                                        </Typography>
                                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                                            Nom : <span id={'name'}>{orderLine.partAccessory.name}</span>
                                                        </Typography>
                                                    </CardContent>
                                                </Box>
                                            </Grid>
                                        </Grid>


                                    </Card>
                                </>
                            )}
                            <Card sx={{ display: 'flex', background:'white', padding:2, borderLeft:'3px solid #f3f3f3',borderRight:'3px solid #dedede', borderRadius:'0px 0px 10px 10px !important' }} />

                        </Box>
                    )}

                    <Pagination pages = {howManyPages} setCurrentPage={setCurrentPage}/>

                    <HeadlineAccount  />
                </>
            )}

        </Container>
);
}

export default AccountContent;
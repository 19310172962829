import React from 'react';
import { motion } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { animateScroll as scroll } from "react-scroll";

import './Footer.css';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaFacebook, FaTwitter, FaYoutube, FaInstagram, FaLinkedin } from "react-icons/fa";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import gif from "@/assets/images/giphy.gif";

const Footer = () => {

    const [footerRef, footerInView] = useInView();
    const [footerRef2, footerInView2] = useInView();
    const [footerRef3, footerInView3] = useInView();

    const scrollToTop = () => {
        scroll.scrollToTop(); 
    };

  return (
    <div className='Footer'>
        <motion.div
          ref={footerRef}
          animate={{ y: footerInView ? 0 : 40, opacity: footerInView ? 1 : 0, animationDelay: 2}}
          transition={{ duration: 0.7 }}
        >
        </motion.div>
        <motion.div
              ref={footerRef2}
              animate={{ y: footerInView2 ? 0 : 40, opacity: footerInView2 ? 1 : 0, animationDelay: 2}}
              transition={{ duration: 0.7 }}
        >
        <section className="Social">
          <div className="Social__title">
            <h2>Rester informé</h2>
          </div>
          <div className="Social__links">
            <p>Suivez-nous et utilisez les plateformes de médias sociaux suivantes pour entrer en contact avec nous et partager votre passion pour la marque, les produits et les services d'Infinity-Life.</p>
            <ul className="Social__links__list">
              <li><a href="#"><FaFacebook className='socialIcon' /></a></li>
              <li><a href="#"><FaTwitter className='socialIcon' /></a></li>
              <li><a href="#"><FaYoutube className='socialIcon' /></a></li>
              <li><a href="#"><FaInstagram className='socialIcon' /></a></li>
              <li><a href="#"><FaLinkedin className='socialIcon' /></a></li>
            </ul>
          </div>
          <div className="Social__spacer"></div>
          <div className="Social__bottom">
            <div className="Social__bottom__paragraph">
              <p>Ceci est le site Web international d'Infinity-Life AG. Visiteurs de France, veuillez visiter notre site Web Français.</p>
            </div>
            <div className='Social__bottom__section'>
                {/*<div className="Social__bottom_language">*/}
                {/*    <p><a href="">Deutsch</a><span> | </span><a href="">English</a></p> */}
                {/*</div>*/}
                <div className="Social__bottom__links">
                    <ul className="Social__bottom__links__list">
                        <li><a href="">Fournisseur</a></li>
                        <li><a href="">Mention légale</a></li>
                        <li><a href="">Cookies</a></li>
                        <li><a href="">Déclaration de confidentialité</a></li>
                    </ul>
                    <ul className="Social__bottom__links__list">
                        <li><a href="#">Fil d'actualité (RSS)</a></li>
                        <li><a href="#">Contact</a></li>
                        <li><a href="#">Carrières</a></li>
                        <li><a href="#">Relations avec les investisseurs</a></li>
                    </ul>
                    <a href="#" onClick={scrollToTop} className="Social__bottom__navigation"><img src={gif} width={50}/></a>
                </div>
            </div>
          </div>

        </section>
        </motion.div>
    </div>
  )
}

export default Footer
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {initialConfig} from '@/assets/data/data';
import Menu from '@/components/public/configurator/Menu';
import Footer from '@/components/public/configurator/Footer';
import Settings from '@/components/public/configurator/Settings';
import Summary from '@/components/public/configurator/Summary';
import Preview from '@/components/public/configurator/Preview';
import InteriorPreview from '@/components/public/configurator/InteriorPreview';
import {carService} from "../../_services/car.service";
import {Card, CardContent, Container, LinearProgress, Skeleton} from "@mui/material";
import {useLocation} from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CardActionArea from "@mui/material/CardActionArea";
import Typography from "@mui/material/Typography";

export default function Configurator() {
    const [progress, setProgress] = React.useState(0);
    const [buffer, setBuffer] = React.useState(10);

    const progressRef = React.useRef(() => {});
    React.useEffect(() => {
        progressRef.current = () => {
            if (progress > 100) {
                setProgress(0);
                setBuffer(10);
            } else {
                const diff = Math.random() * 10;
                const diff2 = Math.random() * 10;
                setProgress(progress + diff);
                setBuffer(progress + diff + diff2);
            }
        };
    });

    React.useEffect(() => {
        const timer = setInterval(() => {
            progressRef.current();
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const model = searchParams.get('model');


    const [state, setState] = useState({
        currentStep: 0,
        config: initialConfig?.[model] ? initialConfig?.[model] ?? null : initialConfig?.['classea'] ?? null
    });

    const [models, setModels] = useState([])
    const flag = useRef(false)
    let [isUserLoading, setIsUserLoading] = useState(true)

    useEffect(() => {
        if(flag.current === false) {

            setIsUserLoading(true);
            carService.getCars()
                .then((res) => {
                    setModels(res.data)
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    getSteps()
                    setIsUserLoading(false)
                })
        }

        return () => flag.current = true
    }, [])

    const getSelectedModel = () => {
        return models.find(model =>
            model?.carKey === state.config?.model)
    }

    const getSteps = () => {
        return [
            {
                name: "Voiture",
                key: "car",
                settings: [
                    {
                        label: "Choisir une voiture",
                        type: "text",
                        prop: "model",
                        options: models.map(model => ({
                            value: model.carKey,
                            label: model.name
                        }))
                    },
                    {
                        label: "Choisir un pack",
                        type: "text",
                        prop: "car_type",
                        options: getSelectedModel()?.carPacks ?? [],
                        disclaimer_1: "Toutes les voitures ont une suspension pneumatique adaptative, un intérieur et un son haut de gamme.",
                        disclaimer_2: "Les véhicules Infinity-Life comprennent les frais d'immatriculation du véhicule neuf (55,00 €), le carburant (50,00 €, TVA incluse) et les plaques d'immatriculation (25,00 €, TVA incluse)"
                    }
                ]
            },
            {
                name: "Extérieur",
                key: "exterior",
                settings: [
                    {
                        label: "Choisir une couleur",
                        type: "color",
                        prop: "color",
                        options: getSelectedModel()?.carColors ?? []
                    },
                    {
                        label: "Choisir une jante",
                        type: "image",
                        prop: "wheels",
                        options: getSelectedModel()?.carWheels ?? []
                    }
                ]
            },
            {
                name: "Intérieur",
                key: "interior",
                settings: [
                    {
                        label: "Choisir un intérieur",
                        type: "text",
                        prop: "interior_color",
                        options: getSelectedModel()?.interiorCarColors ?? []
                    },
                    {
                        label: "Select interior layout",
                        type: "text",
                        prop: "interior_layout",
                        options: getSelectedModel()?.interiorCarLayouts ?? []
                    },
                ]
            },
            {
                name: "Récapitulatif",
                key: "summary"
            }
        ];

    }

    const totalPrice = () => {
        const basePrice = getSelectedModel()?.carPacks?.find(
            type => type.value === state.config?.car_type
        )?.price ?? 0;
        const colorPrice = getSelectedModel()?.carColors?.find(
            color => color.value === state.config?.color
        )?.price ?? 0;
        const wheelsPrice = getSelectedModel()?.carWheels?.find(
            wheels => wheels.value === state.config?.wheels
        )?.price ?? 0;
        const interiorColorPrice = getSelectedModel()?.interiorCarColors?.find(
            interiorColor => interiorColor.value === state.config?.interior_color
        )?.price ?? 0;
        const interiorLayoutPrice = getSelectedModel()?.interiorCarLayouts?.find(
            interiorLayout => interiorLayout.value === state.config?.interior_layout
        )?.price ?? 0;

        return parseInt(basePrice)  + parseInt(colorPrice) + parseInt(wheelsPrice) + parseInt(interiorColorPrice) + parseInt(interiorLayoutPrice);
    };

    const goToStep = (step) => {
        return setState(prevState => ({
            ...prevState,
            currentStep: step
        }));
    };

    const goToPrevStep = () => {
        const newStep = state.currentStep > 0
            ? state.currentStep-1
            : state.currentStep;

        return setState(prevState => ({
            ...prevState,
            currentStep: newStep
        }));
    };

    const goToNextStep = () => {
        const newStep = state.currentStep < getSteps().length - 1
            ? state.currentStep+1
            : state.currentStep;

        return setState(prevState => ({
            ...prevState,
            currentStep: newStep
        }));
    };

    const handleChangeModel = (model) => {
        return setState(prevState => ({
            ...prevState,
            config: initialConfig[model]
        }));
    };

    const handleOnSelectOption = (prop, value) => {
        if (prop === "model") {
            return handleChangeModel(value);
        }
        else {
            return setState(prevState => ({
                ...prevState,
                config: {
                    ...prevState.config,
                    [prop]: value
                }
            }));
        }
    };

    const isFirstStep = state.currentStep === 0;
    const isLastStep = state.currentStep === getSteps().length - 1;

    if (isUserLoading)
    {
        return (
            <Box sx={{background:'white'}}>
                <Container maxWidth='lg' sx={{paddingTop:'20px', paddingBottom:'20px', background:'transparent'}}>
                    {/* For other variants, adjust the size with `width` and `height` */}
                    <Skeleton variant="rounded" height={100} sx={{ m: 2 }} />
                    <Box sx={{display:'flex', justifyContent:'space-evenly'}}>
                        <Skeleton variant="rounded" width={1000} height={500} sx={{ m: 2 }} />
                        <Skeleton variant="rounded" width={300} height={500} sx={{ m: 2 }} />
                    </Box>
                    <Skeleton variant="rounded" height={50} sx={{ m: 2 }} />
                </Container>
            </Box>
        )
    }

    return (
        <>
            <div className="conf-car-order" style={{display:"none"}}>
                <Container maxWidth='lg' sx={{paddingTop:'20px', paddingBottom:'20px'}}>
                    <Grid item xs={12} md={6}>
                        <CardActionArea component="div" sx={{textAlign:'center'}}>
                            <Card sx={{}}>
                                <CardContent sx={{}}>
                                    <Typography component="span" variant="subtitle1" sx={{fontSize:'1rem',lineHeight:'1.5rem', color:'#767676'}}>
                                        Votre configuration est en cours de traitement !
                                    </Typography>
                                    <Box sx={{ width: '100%',mt:1 }}>
                                        <LinearProgress />
                                    </Box>
                                </CardContent>
                            </Card>
                        </CardActionArea>
                    </Grid>
                </Container>
            </div>
            <div className="conf-app">

                <Menu
                    items={getSteps().map(step => step.name)}
                    selectedItem={state.currentStep}
                    onSelectItem={(step) => goToStep(step)}
                />
                <main className="app-content">
                    {
                        getSteps()[state.currentStep]?.key === "interior" ? (
                            <InteriorPreview
                                interior={getSelectedModel()?.interiorCarColors.find(
                                    interiorColor => interiorColor.value === state.config.interior_color
                                )}
                            />
                        ) : (
                            <Preview
                                config={state.config}
                                models={models}
                                showAllModels={isFirstStep}
                                showSpecs={!isLastStep}
                                onChangeModel={(model) => handleChangeModel(model)}
                            />
                        )
                    }
                    {
                        isLastStep ? (
                            <Summary
                                config={state.config}
                                models={models}
                                totalPrice={totalPrice()}
                            />
                        ) : (
                            <Settings
                                config={state.config}
                                settings={getSteps()[state.currentStep].settings}
                                onSelectOption={(prop, vol) => handleOnSelectOption(prop,vol)}
                            />
                        )
                    }
                </main>
                <Footer
                    totalPrice={totalPrice()}
                    disablePrev={isFirstStep}
                    disableNext={isLastStep}
                    onClickPrev={() => goToPrevStep()}
                    onClickNext={() => goToNextStep()}
                />
            </div>
        </>
    );
}
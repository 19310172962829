import Axios from "./caller.service";

let getMediaOjectFromOrder = (uid) => {
    return Axios.get('api/media_objects/'+uid)
}

let getMyPendingOrder = () => {
    return Axios.get('api/user/pending_order')
}

let postOrder = (address, items, state, totalAmount) => {

    let adrsL1 = address['addressLine1'] + ', ' + address['city'] + ' ' + address['zip'] + ', ' + address['country']

    let orderLines = []
    items.forEach(item => {
        orderLines.push({idPartAccessory: item['partAccessory']['id'], quantity: item['quantity']})
    })

    let formData = {
        totalAmount: totalAmount,
        cardType: state.type,
        orderAdresse: adrsL1,
        orderLines: orderLines

    }

    return Axios.post('api/user/orders', formData)
}



export const orderService = {
    getMediaOjectFromOrder, getMyPendingOrder, postOrder
}
import Axios from "./caller.service";

let postPersonalCar = (personalCar) => {
    const personalCarString = JSON.stringify(personalCar);

    return Axios.post('/api/personal_cars', personalCarString ,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

let editPersonalCar = (personalCar) => {
    const personalCarString = JSON.stringify(personalCar);

    return Axios.put('/api/personal_cars/'+personalCar.id, personalCarString ,{
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

let deletePersonalCar = (id) => {
    return Axios.delete('/api/personal_cars/'+id)
}

let getMyPersonalCar = () => {
    return Axios.get('api/user/personal_car')
}

export const personalCarService = {
    postPersonalCar, getMyPersonalCar, editPersonalCar, deletePersonalCar
}
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {Divider} from "@mui/material";
import {useNavigate} from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import RequestNewMaintenanceContent from "@/components/admin/RequestNewMaintenanceContent";

const RequestNewMaintenance = () => {

    const navigate = useNavigate()

    return (
    <>
        <AppBar position="relative" sx={{background:'white', color:'black', boxShadow:'none', borderBottom:'1px solid #e3e3e3'}}>
            <Toolbar sx={{ml:10}}>
                <Typography variant="div" color="inherit" noWrap>
                    <IconButton aria-label="delete" size="large" sx={{borderRadius:'inherit'}}>
                        <KeyboardBackspaceIcon onClick={() => {navigate('/user/mes-entretiens/')} } fontSize="inherit"/>
                    </IconButton>
                </Typography>
                <Divider orientation="vertical" flexItem sx={{margin:2}} />
                <Typography variant="div" color="inherit" noWrap>
                    Demande d'un entretien
                </Typography>
            </Toolbar>
        </AppBar>

        <RequestNewMaintenanceContent />


    </>
        );
};

export default RequestNewMaintenance;
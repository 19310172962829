import React from 'react';
import Typography from "@mui/material/Typography";
import {Card, CardActions, CardContent, Container} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import fond from '@/images/fond.png';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import {personalCarService} from "@/_services/personalCar.service";
import {useSnackbar} from "notistack";

const DeletePersonalCar = () => {

    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate()
    const {uid} = useParams()

    const handleDelete = () => {
        personalCarService.deletePersonalCar(uid)
            .then((data) => {

                enqueueSnackbar('Votre véhicule personnel est supprimée !', {
                    variant: 'success', // or variant: warning | error | info
                    autoHideDuration: 5000,
                    anchorOrigin : {horizontal: "right", vertical: "top"}

                })

                navigate('/user/dashboard')

            })
    }

    const handleCancel = (e) => {
        e.preventDefault()

        enqueueSnackbar('Votre action a bien été annulée !', {
            variant: 'warning', // or variant: warning | error | info
            autoHideDuration: 5000,
            anchorOrigin : {horizontal: "right", vertical: "top"}

        })

        navigate('/user/dashboard')

    }

    return (
        <>
            <Container maxWidth='xl' sx={{p:15,backgroundImage: `url(${fond})`,backgroundRepeat: 'no-repeat' }}>
                <Box component="form">
                    <Grid item xs={12} md={6}>
                        <Card sx={{ display: 'flex' }}>
                            <CardContent sx={{ flex: 1 }}>
                                <Typography component="span" variant="h5" sx={{fontSize:'1.8rem',lineHeight:'1.5rem', color:'#767676', marginRight:2, fontFamily:'MBCorpoATitle'}}>
                                    <b>Vous allez supprimer votre voiture personnel</b>
                                </Typography>
                                <br/>
                                <br/>
                                <Typography component="span" variant="h5" sx={{fontSize:'1rem',lineHeight:'1.5rem', color:'#767676'}}>
                                    Vous-êtes sur de vouloir supprimer votre véhicule personnel ?
                                </Typography>
                            </CardContent>
                            <CardActions sx={{alignSelf:'center', margin:1}}>
                                <Button
                                    variant="contained"
                                    sx={{borderRadius:'inherit', m:1, textTransform:'initial',background:'green' }}
                                    startIcon={<CheckIcon />}
                                    size="large"
                                    onClick={(event) => handleDelete(event)}
                                >
                                    Oui
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{borderRadius:'inherit', m:1, textTransform:'initial',background:'red'}}
                                    startIcon={<CancelIcon />}
                                    size="large"
                                    onClick={(event) => handleCancel(event)}
                                >
                                    Non
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Box>
            </Container>

        </>
    );
};

export default DeletePersonalCar;
import axios from "axios";
import {accountService} from "./account.service";

const Axios = axios.create({
    baseURL: process.env.REACT_APP_PUBLIC_URL,
    headers: {
        'accept': 'application/json'
    }
})
axios.defaults.headers.put['Content-Type'] = 'application/json';
/**
 * Intercepteur pour le token
 */
Axios.interceptors.request.use(request => {

    if (accountService.isLogged()){
        request.headers.Authorization = 'Bearer '+ accountService.getToken()
        request.headers.Accept = 'application/json'
    }

    return request
})

Axios.interceptors.response.use(response => {
    return response
}, error => {

    const location = window.location.pathname;

    if (
        error.response.data.code === 401 &&
        (
            error.response.data.message === 'Expired JWT Token'
            ||
            error.response.data.message === 'Invalid credentials.'
        ) &&
        location !== '/auth/login' &&
        location !== '/user/profile/changePassword'
    ){

        accountService.logout()
        window.location = '/auth/login'

    }else{
        return Promise.reject(error)
    }
})


export default Axios
import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {Route, Routes, useNavigate} from "react-router-dom";
import Error from "@/_utils/Error";
import { GLayout} from '@/pages/Garagiste/RouterConfig';
import {userService} from "@/_services/user.service";
import Home from "../Home";
import AllMaintenances from "../AllMaintenances";
import AllExpertises from "../AllExpertises";
import NewMaintenance from "../NewMaintenance";
import StartMaintenance from "../StartMaintenance";
import StartExpertise from "../StartExpertise";
import Test from "../test";
import {useSnackbar} from "notistack";
import {accountService} from "../../../_services/account.service";

const GaragisteRouter = () => {

    const [isUserLoading, setIsUserLoading] = useState(false);
    const [userRole, setUserRole] = useState('');
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();
    const flag = useRef(false)

    useLayoutEffect(() => {
        if(flag.current === false) {
            const token = localStorage.getItem('token');
            if (token) {
                setIsUserLoading(true);
                userService.getMe()
                    .then((response) => {
                        setUserRole(response.data.roles)
                        if (response.data.roles[0] === 'ROLE_USER') {
                            enqueueSnackbar("Vous allez être redirigé vers le panel garagiste", {
                                variant: 'success', // or variant: warning | error | info
                                autoHideDuration: 5000,
                                anchorOrigin: {horizontal: "right", vertical: "top"}
                            })
                            navigate('/')
                        }else if (response.data.roles[0] === 'ROLE_ADMIN'){
                            accountService.logout()
                        }
                    })
                    .catch((err) => {
                        enqueueSnackbar("Une erreur s'est produite !", {
                            variant: 'error', // or variant: warning | error | info
                            autoHideDuration: 5000,
                            anchorOrigin: {horizontal: "right", vertical: "top"}
                        })
                        navigate('/')
                    })
                    .finally(() => {
                        setIsUserLoading(false);
                    })
            }
        }
        return () => flag.current = true

    }, []);

    return (
        <Routes>
            <Route element={<GLayout/>}>
                <Route index element={<Home />}/>
                <Route path="/maintenance/:id" element={<NewMaintenance />}/>
                <Route path="/start/maintenance/:id" element={<StartMaintenance />}/>
                <Route path="/test" element={<Test />}/>
                <Route path="/entretiens" element={<AllMaintenances />}/>
                <Route path="/expertises" element={<AllExpertises />}/>
                <Route path="/start/expertise/:id" element={<StartExpertise />}/>

                <Route path="*" element={<Error/>}/>
            </Route>
        </Routes>
    );
};

export default GaragisteRouter;

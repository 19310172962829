import React, {useEffect, useRef, useState} from 'react';
import {
    TextField,
    Button,
    Box,
    Typography,
    Card,
    CardMedia,
    FormControl,
    InputLabel,
    Container, Select
} from '@mui/material';
import Toolbar from "@mui/material/Toolbar";
import {Person} from "@mui/icons-material";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import {useNavigate} from "react-router-dom";
import {carModelService} from "../../_services/carModel.service";
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {useSnackbar} from "notistack";
import {usedCarService} from "../../_services/usedCar.service";
import Loading from '@/_utils/Loading';


const NewAnnonceUsedCar = () => {
    const [isUserLoading, setIsUserLoading] = useState(true);
    const [images, setImages] = useState([{ files: null}]);
    const [models, setModels] = useState([]);
    const [adModel, setAdModel] = useState('');
    const [adTitle, setAdTitle] = useState('');
    const [adManufactureDate, setAdManufactureDate] = useState(dayjs(Date.now()));
    const [adMileage, setAdMileage] = useState('');
    const [adDescription, setAdDescription] = useState('');
    const [adCity, setAdCity] = useState('');
    const [adPrice, setAdPrice] = useState('');
    const [adFuel, setAdFuel] = useState('');
    const [adGearBox, setAdGearBox] = useState('');


    const flag = useRef(false)

    let navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const inputProps = {
        min: 1,
        max: 1000000
    };

    useEffect(() => {
        if(flag.current === false) {
            setIsUserLoading(true);
            carModelService.getAllCarModels()
                .then((data) => {
                    setModels(data);
                })
                .catch((err) =>{
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    navigate('/')
                })
                .finally(() => {
                    setIsUserLoading(false);
                })
        }
        return () => flag.current = true
    }, []);

    const handleImageChange = (index, event) => {
        const newImages = [...images];
        newImages[index].files = event.target.files[0];
        setImages(newImages);
    };

    const handleAddImage = () => {
        setImages([...images, { files: null}]);
    };
    const handleRemoveImage = (index) => {
        const newImages = [...images];
        newImages.splice(index, 1);
        setImages(newImages);
    };
    const handleTitleChange = (event) => {
        setAdTitle(event.target.value);
    };
    const handleMileageChange = (event) => {
        setAdMileage(event.target.value);
    };
    const handleDescriptionChange = (event) => {
        setAdDescription(event.target.value);
    };
    const handleCityChange = (event) => {
        setAdCity(event.target.value);
    };
    const handlePriceChange = (event) => {
        setAdPrice(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsUserLoading(true);

        let find = false
        images.forEach(image => {
            if (!image.files)
            {
                find = true;
            }
        })

        if(find)
        {
            enqueueSnackbar("Vous devez rajouter au moins une image !", {
                variant: 'warning', // or variant: warning | error | info
                autoHideDuration: 5000,
                anchorOrigin : {horizontal: "right", vertical: "top"}

            })
            return;
        }

        const formData = new FormData();
        formData.append("title", adTitle);
        formData.append("brand", "Infinity-Life");
        formData.append("model", adModel);
        formData.append("mileage", adMileage);
        formData.append("manufactureDate", adManufactureDate);
        formData.append("description", adDescription);
        formData.append("city", adCity);
        formData.append("price", adPrice);
        formData.append("fuel", adFuel);
        formData.append("gearbox", adGearBox);

        images.map((file) => (
            formData.append("files[]", file.files, file.files.name)
        ))

        usedCarService.postNewAnnonceUsedCar(formData)
            .then((res) => {
                enqueueSnackbar("Votre annonce à bien été ajouté !", {
                    variant: 'success', // or variant: warning | error | info
                    autoHideDuration: 5000,
                    anchorOrigin : {horizontal: "right", vertical: "top"}

                })
                enqueueSnackbar("Elle sera mise en ligne après validation !", {
                    variant: 'success', // or variant: warning | error | info
                    autoHideDuration: 5000,
                    anchorOrigin : {horizontal: "right", vertical: "top"}

                })
                navigate('/occasion')
            })
            .catch(err => {
                enqueueSnackbar("Une erreur s'est produite !", {
                    variant: 'error', // or variant: warning | error | info
                    autoHideDuration: 5000,
                    anchorOrigin : {horizontal: "right", vertical: "top"}
                })
                navigate('/')
            })
            .finally(() => {
                setIsUserLoading(false);
            })
    };

    const fuels = [
        'Essence',
        'Diesel'
    ];

    const gearBoxes = [
        'Manuelle',
        'Automatique'
    ];
    function formatDate(newDate,typeClass = false) {
        const months = {
            0: 'Janvier',
            1: 'Février',
            2: 'Mars',
            3: 'Avril',
            4: 'Mai',
            5: 'Juin',
            6: 'Juillet',
            7: 'Août',
            8: 'Septembre',
            9: 'Octobre',
            10: 'Novembre',
            11: 'Décembre',
        }
        const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']

        const d = new Date(newDate)
        const year = d.getFullYear()
        const date = d.getDate().toString().padStart(2,'0')
        const monthIndex = d.getMonth() + 1
        const monthNumber = monthIndex.toString().padStart(2,'0')
        const monthName = months[d.getMonth()]
        const dayName = days[d.getDay()] // Thu
        const dayNumber = d.getDay() // Thu
        let formatted = ''
        if (!typeClass)
        {
            formatted = `${date} ${monthName} ${year}`
        } else {
            formatted = `${date}/${monthNumber}/${year}`
        }
        return formatted.toString()
    }

    return (
        <>
            {isUserLoading ? (<Loading />) : null}
            <AppBar position="relative" sx={{background:'white', color:'black'}}>
                <Toolbar sx={{ml:10}}>
                    <Person sx={{ mr: 2 }} />
                    <Typography variant="h6" color="inherit" noWrap>
                        Déposer une annonce
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box component="form" onSubmit={handleSubmit}>
                <Container maxWidth='lg' sx={{mt: 3, textAlign:'start', backgroundColor:'white', padding:'8px', borderRadius:'4px',boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)' }} >
                    <h3 style={{fontFamily:"MBCorpoATitle"}}>Commençons par l’essentiel !</h3>

                    <Box noValidate sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="title-label"
                                    name="title"
                                    autoComplete="title"
                                    label={"Titre de l'annonce"}
                                    onChange={handleTitleChange}
                                    fullWidth
                                    value={adTitle}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Container>

                <Container maxWidth='lg' sx={{mt: 3, textAlign:'start', backgroundColor:'white', padding:'8px', borderRadius:'4px',boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)' }} >
                    <h3 style={{fontFamily:"MBCorpoATitle"}}>Vous pouvez ajouter jusqu'à trois images !</h3>

                    <Box noValidate sx={{ mt: 3 }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            placeContent: 'center'
                            // Cela va afficher une direction de colonne pour les écrans xs,
                            // et une direction de ligne pour les écrans plus grands que sm
                        }}>
                            {images.map((image, index) => (
                                    <Box key={index} sx={{ m:5, maxWidth:'25%' }}>
                                        <Box sx={{ }}>
                                            {image.files ? (
                                                <Card>
                                                    <CardMedia component="img" image={URL.createObjectURL(image.files)} />
                                                </Card>
                                            ): (
                                                <Card>
                                                    <CardMedia component="img" image={`${process.env.REACT_APP_PUBLIC_URL}/media/usedCar/upload/usedCarPlaceholder.png`}/>
                                                </Card>
                                            )}
                                        </Box>

                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <Button variant="contained" component="label" fullWidth>
                                                {image.files ? 'Change Image' : 'Télécharger une image'}
                                                <input
                                                    hidden
                                                    accept="image/*"
                                                    type="file"
                                                    name ='file'
                                                    id={`image-${index}`}
                                                    onChange={(event) => handleImageChange(index, event)}
                                                />
                                            </Button>

                                            {index > 0 && (
                                                <Button variant="contained" color="error" onClick={() => handleRemoveImage(index)}>
                                                    Supprimer l'image
                                                </Button>
                                            )}
                                        </Box>
                                    </Box>
                            ))}
                        </Box>

                        {images.length < 3 && (
                            <Button variant="outlined" fullWidth onClick={handleAddImage}>
                                Ajouter une image
                            </Button>
                        )}

                    </Box>
                </Container>

                <Container maxWidth='lg' sx={{mt: 3, textAlign:'start', backgroundColor:'white', padding:'8px', borderRadius:'4px',boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)' }} >
                    <h3 style={{fontFamily:"MBCorpoATitle"}}>Alimentons notre annonce !</h3>

                    <Box noValidate sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="brand-label"
                                    name="brand"
                                    label={'Marque'}
                                    autoComplete="brand"
                                    fullWidth
                                    disabled
                                    value={"Infinity-Life"}
                                    required
                                />

                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" sx={{width:'100%'}}>
                                    <InputLabel id="model-label">Modèle</InputLabel>
                                    <Select
                                        labelId="model-label"
                                        id="demo-simple-select-filled"
                                        value={adModel}
                                        onChange={(e) => setAdModel(e.target.value)}
                                        MenuProps={MenuProps}
                                        label={'Modèle'}
                                        required
                                    >
                                        {models.map((name) => (
                                            <MenuItem
                                                key={name.id}
                                                value={name.name}
                                            >
                                                {name.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="mileage-label"
                                    name="mileage"
                                    autoComplete="mileage"
                                    type='number'
                                    onChange={handleMileageChange}
                                    inputProps={inputProps}
                                    value={adMileage}
                                    fullWidth
                                    required
                                    label={'Kilométrage'}
                                />

                            </Grid>
                            <Grid item xs={12} sm={6}>

                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DatePicker
                                        label="Date de mis en circulation"
                                        inputFormat="DD/MM/YYYY"
                                        value={adManufactureDate}
                                        onChange={(e) => setAdManufactureDate(e)}
                                        renderInput={(params) => <TextField variant="outlined" {...params} fullWidth required />}
                                    />
                                </LocalizationProvider>

                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="city-label"
                                    name="city"
                                    autoComplete="city"
                                    onChange={handleCityChange}
                                    value={adCity}
                                    fullWidth
                                    required
                                    label={'Ville'}
                                />

                            </Grid>
                            <Grid item xs={12} sm={6}>

                                <TextField
                                    id="price-label"
                                    name="price"
                                    autoComplete="price"
                                    onChange={handlePriceChange}
                                    value={adPrice}
                                    fullWidth
                                    required
                                    label={'Prix'}
                                />

                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" sx={{width:'100%'}}>
                                    <InputLabel id="fuel-label">Carburant</InputLabel>
                                    <Select
                                        labelId="fuel-label"
                                        id="demo-simple-select-filled"
                                        value={adFuel}
                                        onChange={(e) => setAdFuel(e.target.value)}
                                        MenuProps={MenuProps}
                                        required
                                        label={'Carburant'}
                                    >
                                        {fuels.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" sx={{width:'100%'}}>
                                    <InputLabel id="gearbox-label">Boite de vitesse</InputLabel>
                                    <Select
                                        labelId="gearbox-label"
                                        id="demo-simple-select-filled"
                                        value={adGearBox}
                                        onChange={(e) => setAdGearBox(e.target.value)}
                                        MenuProps={MenuProps}
                                        label={'Boite de vitesse'}
                                        required
                                    >
                                        {gearBoxes.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    label="Description"
                                    id="description"
                                    name="description"
                                    rows={5}
                                    autoComplete="description"
                                    onChange={handleDescriptionChange}
                                    value={adDescription}
                                    multiline
                                    fullWidth
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Container>

                <Container maxWidth='lg' sx={{mt: 3, textAlign:'start', backgroundColor:'white', padding:'8px', borderRadius:'4px',boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)' }} >
                    <h3 style={{fontFamily:"MBCorpoATitle"}}>Votre annonce est bientôt en ligne !</h3>

                    <Box noValidate sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Button variant="contained" type="submit" sx={{ mt: 2 }}>
                                    Créer ma nouvelle annonce !
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <small>
                                    <a href="#">Me renseigner</a> sur les finalités du traitement de mes données personnelles,
                                    les destinataires, le responsable de traitement, les durées de conservation, les coordonnées du DPO et mes droits.
                                </small>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default NewAnnonceUsedCar;

import Axios from "./caller.service";
import {userService} from "./user.service";
let login  = (formValue) => {
    return Axios.post('auth', formValue)
}
let forgetPassword = (email) => {

    const formValue = {
        email: email
    }

    return Axios.post('api/user/forget/password', formValue)
}
let resetPassword = (token,password) => {

    const formValue = {
        token: token,
        plainPassword: password
    }

    return Axios.post('api/user/reset/password', formValue)
}

let register  = (email, plainPassword) => {

    const user = {
        email: email,
        plainPassword: plainPassword
    };
    const userString = JSON.stringify(user);

    return Axios.post('register', userString)
}

let saveToken = (token) => {
    localStorage.setItem('token', token)
}

let logout = () => {
    localStorage.removeItem('token')
    window.location = '/auth/login'
}

let isLogged = () => {
    let token = localStorage.getItem('token')
    return !!token
}

let getToken = () => {
    return localStorage.getItem('token')
}

let getUserId = () => {
    const token = localStorage.getItem("token");

    if (token) {
        return userService.getMe()
            .then(response => {
                return response.data.id;
            })
            .catch(error => {
                console.log(error);
                return null;
            });

    } else {
        logout();
        return null;
    }
}

export const accountService = {
    saveToken, logout, isLogged, getToken, login, register, getUserId, forgetPassword, resetPassword
}
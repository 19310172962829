import React from 'react';
import {Outlet} from "react-router-dom";
import NavbarGaragiste from "@/components/global/NavbarGaragiste";
import FooterGaragiste from "@/components/global/FooterGaragiste";

const GLayout = () => {
    return (
        <div className="layout">

            <NavbarGaragiste  />

            <div className="admin_body">
                <Outlet/>
            </div>

            <FooterGaragiste />

        </div>
    );
};

export default GLayout;

import Axios from "./caller.service";
let getMyCart = () => {
    return Axios.get('api/carts')
}
let changeCartQuantity = (cartDetailId, quantity) => {

    const formValue = {
        quantity: quantity
    }

    return Axios.put('api/carts/'+cartDetailId, formValue)
}

let deleteCartItem = (cartDetailId) => {

    return Axios.delete('api/carts/'+cartDetailId)
}
let postItemInCart = (itemId) => {

    const formValue = {
        partAccessory : "api/part_accessories/"+itemId,
        quantity : 1
    }

    return Axios.post('api/carts', formValue)
}

export const cartService = {
    getMyCart, changeCartQuantity, deleteCartItem, postItemInCart
}
import React, {useEffect, useRef, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from "@mui/material/Toolbar";
import {Person} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import {useNavigate, useParams} from "react-router-dom";
import {maintenanceService} from "../../_services/maintenance.service";
import {useSnackbar} from "notistack";
import Loading from '@/_utils/Loading';
import {userService} from "@/_services/user.service";
import QrCode from "react-qr-code";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const NewMaintenance = () => {

    const [isDatasLoading, setIsDatasLoading] = useState(true);
    const [isUserLoading, setIsUserLoading] = useState(true);
    const [inputValue, setInputValue] = useState('');
    const [modalData, setModalData] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [maintenance, setMaintenance] = React.useState([]);
    const [user, setUser] = useState([]);

    const {id} = useParams()
    const navigate = useNavigate()
    const flag = useRef(false)
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if(flag.current === false)
        {

            setIsDatasLoading(true);
            setIsUserLoading(true);

            userService.getMe()
                .then((data) => {
                    setUser(data.data);
                })
                .catch((err) =>{
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    navigate('/')
                })
                .finally(() => {
                    setIsUserLoading(false);
                })

            maintenanceService.getMaintenanceById(id)
                .then((res) => {
                    setMaintenance(res.data)

                    if (res.data.done){
                        enqueueSnackbar("L'entretien a déjà été effectué ", {
                            variant: 'success', // or variant: warning | error | info
                            autoHideDuration: 5000,
                            anchorOrigin : {horizontal: "right", vertical: "top"}
                        })
                        navigate('/garagiste')
                    }

                })
                .catch((error) => {
                    console.log(error)
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    navigate('/garagiste')
                })
                .finally(() => {
                    setIsDatasLoading(false)
                });
        }

        return () => flag.current = true

    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await maintenanceService.getMaintenanceById(id);
                // Traitez la réponse de la requête ici
                const data = response.data;

                // Vérifiez le changement d'état et effectuez l'action souhaitée
                if (data.status === 'scanned') {
                    navigate('/garagiste/start/maintenance/'+data.id)
                    // Effectuez l'action ici
                }
            } catch (error) {
                // Gérez les erreurs de requête ici
            }
        };

        const interval = setInterval(() => {
            fetchData();
        }, 1000); // Exécute fetchData toutes les secondes

        // Nettoyez l'intervalle lors du démontage du composant
        return () => {
            clearInterval(interval);
        };

        // Le reste de votre composant
    }, []);

    return (
    <>
        {isDatasLoading || isUserLoading ? <Loading /> : (
            <>
                <AppBar position="relative" sx={{background:'white', color:'black'}}>
                    <Toolbar sx={{ml:10}}>
                        <Person sx={{ mr: 2 }} />
                        <Typography variant="h6" color="inherit" noWrap>
                            Confirmation rendez-vous
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Box
                sx={{ textAlign:'center', p:2 }}
                >
                    {maintenance.status === 'scanned' ? (
                        <>
                            <Typography variant={'h5'} >
                                Déjà scanner
                            </Typography>
                        </>
                    ):(
                        <>
                            <Alert severity="warning" sx={{mb:2, textAlign:'start'}}>
                                <AlertTitle>Information</AlertTitle>
                                Le client doit scanner ce QrCode pour confirmer la prise en charge de son véhicule par le garagiste
                            </Alert>

                            <QrCode
                                value={maintenance.token}
                            />
                        </>
                    )}

                </Box>
            </>
        )}

    </>
    );
};



export default NewMaintenance;
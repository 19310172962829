import React, {useEffect, useRef, useState} from 'react';
import { styled } from '@mui/material/styles';
import {
    Typography,
    Paper,
    Box,
    Button, FormControl, InputLabel, Select, FormHelperText, Modal
} from '@mui/material';
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Divider from '@mui/material/Divider';
import {cartService} from "../../_services/cart.service";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import { useFormik } from "formik";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Container from '@mui/material/Container';
import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
    formatFormData,
    getCreditCardType
} from "@/_utils/CardPayment";
import "react-credit-cards/es/styles-compiled.css";
import Cards from "react-credit-cards";
import {orderService} from "../../_services/order.service";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import Loading from '@/_utils/Loading';
import {userService} from "../../_services/user.service";

const Root = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));

const Left = styled('div')(({ theme }) => ({
    flex: '1 1 0',
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        marginBottom: theme.spacing(3),
        width:'100%'
    },
}));

const Right = styled('div')(({ theme }) => ({
    flex: '0 1 300px',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.grey[100],
    [theme.breakpoints.down('sm')]: {
        flex: '1 1 0',
        width:'100%'
    },
}));

const Total = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const validationSchema = Yup.object({
    firstName: Yup.string().required("Le prénom est obligatoire"),
    lastName: Yup.string().required("Le nom est obligatoire"),
    addressLine1: Yup.string().required("La ligne d'adresse est obligatoire"),
    city: Yup.string().required("La ville est obligatoire"),
    zip: Yup.string().required("Le code postal est obligatoire"),
    country: Yup.string().required("Le pays est obligatoire"),
    phone: Yup.string().required("Le numéro de tel est obligatoire"),
});

const Cart = () => {

    const [isUserLoading, setIsUserLoading] = useState(true);
    const [isCartLoading, setIsCartLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [user, setUser] = useState([]);

    const flag = useRef(false)
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if(flag.current === false)
        {
            setIsUserLoading(true);
            setIsCartLoading(true);

            userService.getMe()
                .then((data) => {
                    setUser(data.data);
                })
                .catch((err) =>{
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    navigate('/')
                })
                .finally(() => {
                    setIsUserLoading(false);
                })

            cartService.getMyCart()
                .then((data) => {
                    setItems(data.data)
                    if (data.data.length === 0)
                    {
                        navigate('/user/cart')
                    }
                })
                .catch((err) =>{
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    navigate('/')
                })
                .finally(() => {
                    setIsCartLoading(false);
                })
        }

        return () => flag.current = true
    }, []);

    const getTotal = (items) => {
        return items.reduce((accumulator, item) => {
            return accumulator + item.partAccessory.price * item.quantity;
        }, 0);
    };

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            addressLine1: "",
            city: "",
            zip: "",
            country: "",
            phone: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setIsCartLoading(true);

            if (!state.type)
            {
                state.type = 'CB'
            }

            orderService.postOrder(values, items, state, getTotal(items))
                .then((res) => {

                    if (res.status === 200)
                    {
                        enqueueSnackbar("Votre commande a bien été enregistrée ! 🛒", {
                            variant: 'success', // or variant: warning | error | info
                            autoHideDuration: 5000,
                            anchorOrigin : {horizontal: "right", vertical: "top"}
                        })

                        navigate('/user/account')
                    }

                })
                .catch((err) =>{
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    navigate('/')
                })
                .finally(() => {
                    setIsCartLoading(false);
                })
        },
    });
    const formRef = useRef(null);

    const [open, setOpen] = useState(false);
    const handleOpenModal = () => {
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
        setState((prev) => ({ ...prev, focused: 'number' }));
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [state, setState] = useState({
        number: '',
        name: '',
        expiry: '',
        cvc: '',
        issuer: '',
        focused: '',
        formData: null,
        type :''
    });

    const handleCallback = ({ issuer }, isValid) => {
        if (isValid) {
            setState({ issuer });
        }
    };

    const handleInputFocus = ({ target }) => {
        setState((prev) => ({ ...prev, focused: target.name }));
    };

    const handleInputChange = ({ target }) => {

        if (target.name === "number") {
            target.value = formatCreditCardNumber(target.value);
        } else if (target.name === "expiry") {
            target.value = formatExpirationDate(target.value);
        } else if (target.name === "cvc") {
            target.value = formatCVC(target.value);
        }

        setState((prev) => ({ ...prev, [target.name]: target.value }));
        if (target.name === "number") {
            setState((prev) => ({...prev, type: getCreditCardType(target.value)}));
        }
    };
    return (
        <Box>
            {isUserLoading || isCartLoading ? (<Loading />) : null}
            <AppBar position="relative" sx={{background:'white', color:'black'}}>
                <Toolbar sx={{ml:10}}>
                    <ShoppingCartIcon sx={{ mr: 2 }} />
                    <Typography variant="h6" color="inherit" noWrap>
                        Choisir votre livraison
                    </Typography>
                </Toolbar>
            </AppBar>
            <Root>
                <Left>
                    <Paper elevation={3}>
                        <Container maxWidth="xl" sx={{p:3}}>
                            <Typography variant="h5" gutterBottom>
                                Récapitulatif des articles
                            </Typography>
                            <Box sx={{ maxWidth: '100%', overflow: 'auto', display:'flex', p:1 }}>
                                {items.map((item) => (
                                    <Box key={item.id}>
                                        <Card sx={{ maxWidth: 345, mr:2, background:'transparent', boxShadow:'none' }}>
                                            <CardActionArea >
                                                <CardMedia
                                                    component="img"
                                                    height="80"
                                                    image={process.env.REACT_APP_PUBLIC_URL+item.partAccessory.images[0].contentUrl}
                                                    alt="green iguana"
                                                />
                                                <CardContent>
                                                    <Typography variant="body2" color="text.secondary" sx={{textAlign:'center'}}>
                                                        {item.partAccessory.name}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary" sx={{textAlign:'center'}}>
                                                        {item.partAccessory.price} € <small>(prix unitaire)</small>
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Box>
                                ))}
                            </Box>
                        </Container>
                    </Paper>
                    <Divider sx={{mt:3,mb:3}} />
                    <Paper elevation={3}>
                        <Container maxWidth="xl" sx={{p:3}}>
                            <Typography variant="h5" gutterBottom>
                                Informations de livraison
                            </Typography>
                            <form ref={formRef} onSubmit={formik.handleSubmit} >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="filled"
                                            id="firstName"
                                            name="firstName"
                                            label="Prénom"
                                            required
                                            fullWidth
                                            value={formik.values.firstName}
                                            onChange={formik.handleChange}
                                            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                            helperText={formik.touched.firstName && formik.errors.firstName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="filled"
                                            id="lastName"
                                            name="lastName"
                                            label="Nom"
                                            required
                                            fullWidth
                                            value={formik.values.lastName}
                                            onChange={formik.handleChange}
                                            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                            helperText={formik.touched.lastName && formik.errors.lastName}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="filled"
                                            id="addressLine1"
                                            name="addressLine1"
                                            label="Adresse"
                                            required
                                            fullWidth
                                            value={formik.values.addressLine1}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.addressLine1 && Boolean(formik.errors.addressLine1)
                                            }
                                            helperText={
                                                formik.touched.addressLine1 && formik.errors.addressLine1
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="filled"
                                            id="city"
                                            name="city"
                                            label="Ville"
                                            required
                                            fullWidth
                                            value={formik.values.city}
                                            onChange={formik.handleChange}
                                            error={formik.touched.city && Boolean(formik.errors.city)}
                                            helperText={formik.touched.city && formik.errors.city}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl variant="filled" fullWidth required error={formik.touched.country && Boolean(formik.errors.country)}>
                                            <InputLabel id="demo-simple-select-label">Pays</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                name="country"
                                                value={formik.values.country}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                placeholder={formik.touched.country && formik.errors.country}
                                            >
                                                <MenuItem value="france">France</MenuItem>
                                                <MenuItem value="belgique">Belgique</MenuItem>
                                                <MenuItem value="suisse">Suisse</MenuItem>
                                            </Select>
                                            {formik.touched.country && Boolean(formik.errors.country) && (
                                                <FormHelperText htmlFor="form-selector" error={formik.touched.country && Boolean(formik.errors.country)}>
                                                    {formik.errors.country}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="filled"
                                            required
                                            fullWidth
                                            label="Code postal"
                                            name="zip"
                                            value={formik.values.zip}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.zip && Boolean(formik.errors.zip)}
                                            helperText={formik.touched.zip && formik.errors.zip}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="filled"
                                            required
                                            fullWidth
                                            label="Téléphone"
                                            name="phone"
                                            value={formik.values.phone}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.phone && Boolean(formik.errors.phone)}
                                            helperText={formik.touched.phone && formik.errors.phone}
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                        </Container>
                    </Paper>
                    <Divider sx={{mt:3,mb:3}} />
                    <Paper elevation={3}>
                    <Container maxWidth="xl" sx={{p:3}}>
                        <Typography variant="h5" gutterBottom>
                            Information de paiement
                        </Typography>
                        {state.number && state.name && state.expiry && state.cvc && state.focused ? (
                                <Card sx={{boxShadow:'none', textAlign:'center' }}>
                                    <CardContent>
                                        <Cards
                                            number={state.number}
                                            name={state.name}
                                            expiry={state.expiry}
                                            cvc={state.cvc}
                                            focused={state.focused}
                                            callback={handleCallback}
                                        />

                                        <Button sx={{mt:2}} variant="contained" color="primary" onClick={handleOpenModal}>
                                            Modifier les informations de la carte
                                        </Button>
                                    </CardContent>
                                </Card>
                            ) : (
                                <Button variant="contained" color="primary" onClick={handleOpenModal}>
                                    Saisir les informations de la carte
                                </Button>
                            )
                        }
                        <Modal
                            open={open}
                            onClose={handleCloseModal}
                            aria-labelledby="modal-modal-title"
                        >
                            <Box sx={modalStyle}>
                                <div key="Payment">
                                    <div className="App-payment">
                                        <Cards
                                            number={state.number}
                                            name={state.name}
                                            expiry={state.expiry}
                                            cvc={state.cvc}
                                            focused={state.focused}
                                            callback={handleCallback}
                                        />
                                        <form>
                                            <div className="form-group">
                                                <TextField
                                                    fullWidth
                                                    type="tel"
                                                    name="number"
                                                    value={state.number}
                                                    className="form-control"
                                                    placeholder="Card Number"
                                                    pattern="[\d| ]{16}"
                                                    required
                                                    inputProps={{ maxLength: 19 }}
                                                    onChange={handleInputChange}
                                                    onFocus={handleInputFocus}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <TextField
                                                    fullWidth
                                                    type="text"
                                                    name="name"
                                                    className="form-control"
                                                    placeholder="Name"
                                                    value={state.name}
                                                    required
                                                    onChange={handleInputChange}
                                                    onFocus={handleInputFocus}
                                                />
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <TextField
                                                        fullWidth
                                                        type="tel"
                                                        name="expiry"
                                                        className="form-control"
                                                        placeholder="Valid Thru"
                                                        pattern="\d\d/\d\d"
                                                        value={state.expiry}
                                                        required
                                                        onChange={handleInputChange}
                                                        onFocus={handleInputFocus}
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <TextField
                                                        fullWidth
                                                        type="tel"
                                                        name="cvc"
                                                        className="form-control"
                                                        placeholder="CVC"
                                                        pattern="\d{3,4}"
                                                        value={state.cvc}
                                                        required
                                                        onChange={handleInputChange}
                                                        onFocus={handleInputFocus}
                                                    />
                                                </div>
                                            </div>
                                            <input type="hidden" name="issuer" value={state.issuer} />
                                            <div className="form-actions">
                                                <Button type={'button'} fullWidth variant="contained" className="btn btn-primary btn-block" onClick={handleCloseModal}>Enregistrer</Button>
                                            </div>
                                        </form>
                                        {state.formData && (
                                            <div className="App-highlight">
                                                {formatFormData(state.formData).map((d, i) => (
                                                    <div key={i}>{d}</div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Box>
                        </Modal>
                    </Container>
                </Paper>
                </Left>
                <Right>
                    <Typography variant="h5" gutterBottom>
                        Total
                    </Typography>
                    <Box>
                        <Total variant="subtitle1" gutterBottom>
                            {getTotal(items)} €
                        </Total>
                        <ButtonWrapper>
                            <form ref={formRef} onSubmit={formik.handleSubmit} >
                                <Button type="submit" variant="contained" color="primary">
                                    Procéder au paiement
                                </Button>
                            </form>
                        </ButtonWrapper>
                    </Box>
                </Right>
            </Root>

        </Box>
    );
};

export default Cart;

import React from 'react';
import {Outlet} from "react-router-dom";
import Navbar from "../../../components/global/Navbar";
import Footer from "../../../components/global/Footer";

const ALayout = () => {
    return (
        <div className="layout">

            <Navbar  />

            <div className="admin_body">
                <Outlet/>
            </div>

            <Footer />

        </div>
    );
};

export default ALayout;

import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    IconButton,
    Grid,
    TableRow,
    TableCell, TableContainer, Table, TableBody, TableHead
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Paper from "@mui/material/Paper";
import CardActionArea from "@mui/material/CardActionArea";
import {useRef} from "react";
import {useNavigate} from "react-router-dom";
import Chip from "@mui/material/Chip";

const CarCard = ({ car }) => {
    const cardActionAreaRef = useRef(null);
    const navigate = useNavigate();


    const handleClick = () => {
        navigate('/occasion/'+car.id)
    };

    return (

        <Card sx={{mb:3}}>
            <CardActionArea ref={cardActionAreaRef} onClick={handleClick}>
                <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Grid item xs={12} md={3} lg={3}>
                        <CardMedia
                            component="img"
                            image={process.env.REACT_APP_PUBLIC_URL+car.files[0].contentUrl}
                            alt={"d"}
                            sx={{ width: 160 }}

                        />
                    </Grid>

                    <Grid item xs={12} md={9} lg={9}>
                        <CardContent sx={{ flexGrow: 1 }}>
                            <Typography variant="h5" component="h2" align="left">
                                {car.title} <br/>
                                <Chip label={car.model} color="primary" size="small"/>
                            </Typography>
                            <Typography variant="subtitle1" color="text.secondary" align="left">
                                {car.price} €
                            </Typography>
                            <TableContainer component={Paper} sx={{ mt: 2, boxShadow:'unset' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom:'none', fontSize:'0.8rem', paddingLeft:0}}>Année</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom:'none'}}>Kilométrage</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom:'none'}}>Carburant</TableCell>
                                            <TableCell sx={{ borderBottom:'none'}}>Boîte de vitesse</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom:'none' , fontSize:'0.8rem', paddingLeft:0, paddingTop:0}}>{car.manufactureDate.slice(0, 4)}</TableCell>

                                            <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom:'none', paddingTop:0}}>{car.mileage} km</TableCell>

                                            <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom:'none', paddingTop:0}}>{car.fuel}</TableCell>

                                            <TableCell sx={{ borderBottom:'none', paddingTop:0}}>{car.gearbox}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Typography variant="subtitle1" color="text.secondary" align="left" >
                                 
                            </Typography>
                            <Typography variant="subtitle1" color="text.secondary" align="left">
                                {car.city}
                                <IconButton aria-label="location" size="small">
                                    <LocationOnIcon />
                                </IconButton>
                            </Typography>
                        </CardContent>
                    </Grid>
                </Grid>
            </CardActionArea>
        </Card>
    );
};

export default CarCard;

import React from 'react';
import { motion } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { animateScroll as scroll } from "react-scroll";

import './Footer.css';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaFacebook, FaTwitter, FaYoutube, FaInstagram, FaLinkedin } from "react-icons/fa";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import gif from "@/assets/images/giphy.gif";

const Footer = () => {

    const [footerRef, footerInView] = useInView();
    const [footerRef2, footerInView2] = useInView();
    const [footerRef3, footerInView3] = useInView();

    const scrollToTop = () => {
        scroll.scrollToTop(); 
    };

  return (
    <div className='Footer'>
        <motion.div 
          ref={footerRef} 
          animate={{ y: footerInView ? 0 : 40, opacity: footerInView ? 1 : 0, animationDelay: 2}} 
          transition={{ duration: 0.7 }}
        >
        <section className='Links'>
        <div className="Links__column">
            <div className="Links__column__headline">
                <h2 className='Links__column__headline__title'>Plus de sujets</h2>
            </div>
            <div className="Links__column__links">
                <ul className="Links__column__links__list">
                    <li><a href="#">Innovation</a></li>
                    <li><a href="#">Conception</a></li>
                    <li><a href="#">Des expositions</a></li>
                    <li><a href="#">Musée & Histoire</a></li>
                    <li><a href="#">Sports</a></li>
                    <li><a href="#">Événements de conduite</a></li>
                    <li><a href="#">Portail Infinity-Life me</a></li>
                </ul>
            </div>
        </div>

        <div className="Links__column">
            <div className="Links__column__headline">
                <h2 className='Links__column__headline__title'>Achats</h2>
            </div>
            <div className="Links__column__links">
                <ul className="Links__column__links__list">
                    <li><a href="#">Recherche de concessionnaire</a></li>
                    <li><a href="#">Accessoires Infinity Life</a></li>
                    <li><a href="#">Collection Infinity-Life</a></li>
                    <li><a href="#">Boutique Infinity-Life me connect</a></li>
                    <li><a href="#">Entretien et pièces</a></li>
                    <li><a href="#">Boutique Formule 1</a></li>
                    <li><a href="#">Centre Classique Infinity-Life</a></li>
                </ul>
            </div>
        </div>

        <div className="Links__column">
            <div className="Links__column__headline">
                <h2 className='Links__column__headline__title'>Tout sur les voitures</h2>
            </div>
            <div className="Links__column__links">
                <ul className="Links__column__links__list">
                    <li><a href="#">Présentation du modèle</a></li>
                    <li><a href="#">Configurateur</a></li>
                    <li><a href="#">Essai routier</a></li>
                    <li><a href="#">Infinity-Life-Maybach</a></li>
                    <li><a href="#e">Infinity-Life me connecte</a></li>
                    <li><a href="#">Infinity-Life Ex-Factory Driving Aids</a></li>
                    <li><a href="#">WLTP</a></li>
                    <li><a href="#">RDE</a></li>
                    <li><a href="#">Situation de l'approvisionnement en semi-conducteurs</a></li>
                    <li><a href="#">Li-Ion UN38.3</a></li>
                </ul>
            </div>
        </div>

        <div className="Links__column">
            <div className="Links__column__headline">
                <h2 className='Links__column__headline__title'>Découvrir plus</h2>
            </div>
            <div className="Links__column__links">
                <ul className="Links__column__links__list">
                    <li><a href="#">Rédaction</a></li>
                    <li><a href="#">Presse</a></li>
                    <li><a href="#">Carrière</a></li>
                    <li><a href="#">heycar</a></li>
                    <li><a href="#">PARTAGE MAINTENANT</a></li>
                    <li><a href="#">Vulnerability Reporting Policy</a></li>
                    <li><a href="#">Énergie de la vie infinie</a></li>
                    <li><a href="#">Banque Infinity-Life</a></li>
                    <li><a href="#">Ventes de flotte</a></li>
                    <li><a href="#">Formation mondiale Infinity-Life</a></li>
                </ul>
            </div>
        </div>
    </section>
    </motion.div>
    <motion.div 
          ref={footerRef2} 
          animate={{ y: footerInView2 ? 0 : 40, opacity: footerInView2 ? 1 : 0, animationDelay: 2}} 
          transition={{ duration: 0.7 }}
    >
    <section className="Social">
      <div className="Social__title">
        <h2>Rester informé</h2>
      </div>
      <div className="Social__links">
        <p>Suivez-nous et utilisez les plateformes de médias sociaux suivantes pour entrer en contact avec nous et partager votre passion pour la marque, les produits et les services d'Infinity-Life.</p>
        <ul className="Social__links__list">
          <li><a href="#"><FaFacebook className='socialIcon' /></a></li>
          <li><a href="#"><FaTwitter className='socialIcon' /></a></li>
          <li><a href="#"><FaYoutube className='socialIcon' /></a></li>
          <li><a href="#"><FaInstagram className='socialIcon' /></a></li>
          <li><a href="#"><FaLinkedin className='socialIcon' /></a></li>
        </ul>
      </div>
      <div className="Social__spacer"></div>
      <div className="Social__bottom">
        <div className="Social__bottom__paragraph">
          <p>Ceci est le site Web international d'Infinity-Life AG. Visiteurs de France, veuillez visiter notre site Web Français.</p>
        </div>
        <div className='Social__bottom__section'>
            {/*<div className="Social__bottom_language">*/}
            {/*    <p><a href="">Deutsch</a><span> | </span><a href="">English</a></p> */}
            {/*</div>*/}
            <div className="Social__bottom__links">
                <ul className="Social__bottom__links__list">
                    <li><a href="">Fournisseur</a></li>
                    <li><a href="">Mention légale</a></li>
                    <li><a href="">Cookies</a></li>
                    <li><a href="">Déclaration de confidentialité</a></li>
                </ul>
                <ul className="Social__bottom__links__list">
                    <li><a href="#">Fil d'actualité (RSS)</a></li>
                    <li><a href="#">Contact</a></li>
                    <li><a href="#">Carrières</a></li>
                    <li><a href="#">Relations avec les investisseurs</a></li>
                </ul>
                <a href="#" onClick={scrollToTop} className="Social__bottom__navigation"><img src={gif} width={50}/></a>
            </div>
        </div>
      </div>

    </section>
    </motion.div>
    </div>
  )
}

export default Footer
import * as React from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import {Container} from "@mui/material";
import Grid from "@mui/material/Grid";
import { styled } from '@mui/material/styles';
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import {Edit} from "@mui/icons-material";
import {useEffect, useRef, useState} from "react";
import {userService} from "../../_services/user.service";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Badge from "@mui/material/Badge";
import Loading from '@/_utils/Loading';
import {useSnackbar} from "notistack";

export default function ProfileSecurityContent() {

    const [isUserLoading, setIsUserLoading] = useState(false);
    const [user, setUser] = useState([]);

    const flag = useRef(false)
    const { enqueueSnackbar } = useSnackbar();

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));


    useEffect(() => {
        if(flag.current === false)
        {
            setIsUserLoading(true);

            userService.getMe()
                .then((data) => {
                    setUser(data.data);
                })
                .catch((err) =>{
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    navigate('/')
                })
                .finally(() => {
                    setIsUserLoading(false);
                })
        }

        return () => flag.current = true
    }, []);

    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            right: -3,
            top: 13,
            border: `2px solid ${theme.palette.background.paper}`,
            padding: '0 4px',
        },
    }));

    const navigate = useNavigate();

    return (
        <>
            <Container maxWidth='lg' sx={{paddingTop:'20px',paddingBottom:'20px'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <Item sx={{textAlign:'start',padding:'5px'}}>

                            <Button onClick={() => navigate('/user/profile')} size="large" sx={{color:"#959595",textTransform:'none',fontFamily:"MBCorpoATitle"}}>
                                Détails du compte
                            </Button>
                            <br/>
                            <Button onClick={() => navigate('/user/profile/adresses')} size="large" sx={{color:"#959595",textTransform:'none',fontFamily:"MBCorpoATitle"}}>
                                Adresses
                            </Button>
                            <br/>
                            <Button size="large" sx={{color:"#959595",textTransform:'none',fontFamily:"MBCorpoATitle", background:'#e8e8e8'}}>
                                Connexion et sécurité
                            </Button>
                        </Item>
                    </Grid>
                    {isUserLoading ? (<Loading />) : (
                        <Grid item xs={12} md={9}>
                            <Item sx={{textAlign:'start'}}>
                                <Container maxWidth='lg'>
                                    <h1 style={{fontFamily:"MBCorpoATitle"}}>Connexion et sécurité</h1>
                                    <Box sx={{height: 50}}>
                                        <span>Maintenez vos informations de connexion à jour.</span>
                                    </Box>

                                    <Box sx={{height: 50}}>
                                        <p><b>Information de connexion</b></p>
                                    </Box>

                                    <p style={{fontSize:'10px'}}>Adresse email</p>

                                    <Grid item sx={{margin:0}} container spacing={2}>
                                        <Grid item xs={9} md={11}>
                                            <div style={{display:'flex'}}>
                                                <div>
                                                    {user.email}
                                                </div>
                                                <div style={{marginLeft:'10px'}}>
                                                    <CheckCircleIcon color={'success'} sx={{fontSize:'1.0rem'}} />
                                                </div>
                                                <div style={{fontSize:'12px', marginLeft:'5px'}}>
                                                    <b style={{color:'green'}}>Vérifié</b>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3} md={1}>
                                            <Button sx={{padding:0}} onClick={() => navigate('/user/profile/changeEmail')}>
                                                <Edit color={'disabled'} />
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    <br/>
                                    <hr style={{borderTop:'1px dashed grey'}} />
                                    <br/>
                                    <p style={{fontSize:'10px'}}>Mot de passe</p>

                                    <Grid item sx={{margin:0}} container spacing={2}>
                                        <Grid item xs={9} md={11}>
                                            <span>Votre mot passe n'a pas été modifé depuis <b>12/03/2023</b></span>
                                        </Grid>
                                        <Grid item xs={3} md={1}>
                                            <Button sx={{padding:0}} onClick={() => navigate('/user/profile/changePassword')}>
                                                <Edit color={'disabled'} />
                                            </Button>

                                        </Grid>
                                    </Grid>
                                    <br/>
                                    <hr style={{borderTop:'1px dashed grey'}} />
                                    <br/>

                                </Container>
                            </Item>
                        </Grid>
                    )}
                </Grid>
            </Container>
        </>
    );
}
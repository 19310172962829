import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Card, CardContent, CardMedia, Container, Skeleton} from "@mui/material";
import Grid from "@mui/material/Grid";
import {useNavigate} from "react-router-dom";
import {userService} from "@/_services/user.service";
import {styled} from '@mui/material/styles';
import Paper from "@mui/material/Paper";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import fond from '@/images/fond.png';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import SettingsIcon from '@mui/icons-material/Settings';
import CardActionArea from '@mui/material/CardActionArea';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import {carService} from "../../_services/car.service";
import Chip from "@mui/material/Chip";
import { formatPrice } from '@/_utils/utils';
import "@/css/slick/slick.css";
import {useSnackbar} from "notistack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from '@mui/icons-material/Warning';

const ConsideringContent = () => {

    const [user, setUser] = useState([]);
    const [cars, setCars] = useState([]);
    const [isUserLoading, setIsUserLoading] = useState(true);
    const [isCarsLoading, setIsCarsLoading] = useState(true);

    const flag = useRef(false)
    let navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if(flag.current === false) {
            setIsUserLoading(true);
            setIsCarsLoading(true);

            userService.getMe()
                .then((data) => {
                    setUser(data.data);
                })
                .catch((err) =>{
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    navigate('/')
                })
                .finally(() => {
                    setIsUserLoading(false);
                })

            const fetchData = async () => {
                try {
                    const resp1 = await carService.getCarsConsidering()
                    const result1 = await resp1.data;

                    // Parcourir les données de la première requête
                    const newData = [];
                    for (const item of result1) {
                        const response2 = await carService.getCarsPackByValue(item.carPack)
                        const result2 = await response2.data[0];
                        newData.push({...item, packs: {...result2}});
                    }

                    setCars(newData);
                    setIsCarsLoading(false);
                } catch (error) {
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                }
            };

            fetchData();
        }

        return () => flag.current = true
    }, []);

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    function formatDate(newDate) {
        const months = {
            0: 'Janvier',
            1: 'Février',
            2: 'Mars',
            3: 'Avril',
            4: 'Mai',
            5: 'Juin',
            6: 'Juillet',
            7: 'Août',
            8: 'Septembre',
            9: 'Octobre',
            10: 'Novembre',
            11: 'Décembre',
        }

        const d = new Date(newDate)
        const year = d.getFullYear()
        const date = d.getDate()
        const monthName = months[d.getMonth()]
        const formatted = `${date} ${monthName} ${year}`

        return formatted.toString()
    }

    if (isUserLoading || isCarsLoading) {
        return (
            <Container maxWidth='lg' sx={{paddingTop:'20px', paddingBottom:'20px', background:'transparent'}}>
                {/* For other variants, adjust the size with `width` and `height` */}
                <Skeleton variant="rounded" height={200} sx={{ m: 2 }} />
                <Box sx={{display:'flex', justifyContent:'space-evenly'}}>
                    <Skeleton variant="circular" width={50} height={50} sx={{ m: 2 }} />
                    <Skeleton variant="circular" width={50} height={50} sx={{ m: 2 }} />
                    <Skeleton variant="circular" width={50} height={50} sx={{ m: 2 }} />
                </Box>
                <Skeleton variant="rounded" height={150} sx={{ m: 2 }} />
            </Container>
        );
    }

    return (
        <Box sx={{background:'#f4f4f4'}}>
            <Grid container spacing={2} >
                <Grid item xs={12} md={12} lg={12} sx={{}}>

                    <Item sx={{textAlign:'start',padding:'5px', boxShadow:'0 0 2px 0 rgba(0,0,0,0.1),0 4px 8px 0 rgba(0,0,0,0.1)'}}>

                        <Button onClick={() => navigate('/user/dashboard/owning')} size="large" sx={{color:"#959595",textTransform:'none',fontFamily:"MBCorpoATitle"}}>
                            Mes véhicules
                        </Button>
                        <Button size="large" sx={{ background:'#e8e8e8',color:"#959595",textTransform:'none',fontFamily:"MBCorpoATitle"}}>
                            Votre prochain véhicule
                        </Button>

                    </Item>
                </Grid>
            </Grid>

            {cars.length > 0 ? (
            <Container maxWidth='xl'  sx={{paddingTop:'20px', paddingBottom:'20px',backgroundRepeat: 'no-repeat', backgroundImage: `url(${fond})`, backgroundSize: 'contain'}}>
                <Slider {...settings}>
                    {cars.map((car) => (
                        <>
                            <div>
                                <img
                                    src={`${process.env.REACT_APP_PUBLIC_URL}/media/cars/models/model_${car.carModel}/model_${car.carModel}_${car.carColor}_${car.carWheels}.png`}
                                    style={{width:'-webkit-fill-available'}}
                                    alt={car.carModel}
                                />
                                <div style={{textAlign:'center'}}>
                                    <p style={{margin:'10px'}}> <Chip icon={<SettingsIcon />} label="Configuration" /></p>

                                    {car.statut === 'Pending' ? (
                                        <p style={{margin:'10px'}}> <Chip sx={{background:'orange', color:'white'}}  icon={<WarningIcon sx={{ color: '#fff !important' }}  />} label="Voiture non Réservé" /></p>
                                    ) : (
                                        <p style={{margin:'10px'}}> <Chip sx={{background:'green', color:'white'}} icon={<CheckCircleIcon sx={{ color: '#fff !important' }}  />} label="Voiture Réservé" /></p>
                                    ) }

                                    <p>{car.carName}</p>
                                    <p>Infinity-Life</p>
                                </div>
                            </div>
                            <Container maxWidth='xl' sx={{paddingTop:'20px', paddingBottom:'20px', backgroundImage : {fond} }}>

                                <Box>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4} md={4} lg={4}>
                                            <Item sx={{display:'flex',justifyContent:'end',alignItems:'center',background:'transparent',boxShadow:'none'}}>
                                                <ElectricCarIcon sx={{fontSize:'1rem', marginRight:1}} />
                                                <span><b>{car.packs.puissanceDin} ch</b></span>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4}>
                                            <Item sx={{display:'flex',justifyContent:'center',alignItems:'center',background:'transparent',boxShadow:'none'}}>
                                                <LocalGasStationIcon sx={{fontSize:'1rem', marginRight:1}} />
                                                <span><b>{car.packs.fuel}</b></span>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4}>
                                            <Item sx={{display:'flex',justifyContent:'start',alignItems:'center',background:'transparent',boxShadow:'none'}}>
                                                <SettingsIcon sx={{fontSize:'1rem', marginRight:1}} />
                                                <span><b>{car.packs.gearBox}</b></span>
                                            </Item>
                                        </Grid>
                                    </Grid>
                                    <Typography component="p" sx={{textAlign:'center',fontSize:'0.75rem',color:'#767676'}}>
                                        Consommation combinée: 4,6 l/100km (WLTP); Émissions totales de CO₂ (combinées): 120 g/km (WLTP)
                                    </Typography>
                                </Box>

                                <Box>
                                    <Grid item xs={12} md={6}>
                                        <CardActionArea component="a" href="#">
                                            <Card sx={{ display: { xs: 'block', md: 'block', lg: 'flex' } }}>
                                                <CardContent sx={{ flex: 1, display: 'flex'  }}>
                                                    <Typography component="span" variant="h5" sx={{fontSize:'1rem',lineHeight:'1.5rem', color:'#767676', marginRight:2}}>
                                                        en ce qui concerne le prix
                                                        <br/>
                                                        <span style={{fontSize:'1.5rem',lineHeight:'2.25rem', color:'black'}}><b>{formatPrice(car.totalPrice)}</b></span>
                                                    </Typography>
                                                    <Typography component="span" variant="h5" sx={{fontSize:'1rem',lineHeight:'1.5rem', color:'#767676'}}>
                                                        Loyers mensuels
                                                        <br/>
                                                        <span style={{fontSize:'1.5rem',lineHeight:'2.25rem', color:'black'}}><b>{formatPrice(parseInt((car.totalPrice / 12).toFixed(0)))}</b></span>
                                                    </Typography>
                                                </CardContent>
                                                <CardMedia sx={{alignSelf:'center', margin:1}}>
                                                    <Button
                                                        variant="contained"
                                                        sx={{borderRadius:'inherit'}}
                                                        startIcon={<CarRepairIcon />}
                                                        size="large"
                                                        onClick={() => {navigate('/configurator')}}
                                                    >
                                                        Véhicules de stock similaires disponnibles
                                                    </Button>
                                                </CardMedia>
                                            </Card>
                                        </CardActionArea>
                                    </Grid>
                                </Box>
                            </Container>
                        </>
                    ))}
                </Slider>
            </Container>) : (
            <Container maxWidth='lg' sx={{paddingTop:'20px', paddingBottom:'20px',backgroundImage: `url(${fond})`,backgroundRepeat: 'no-repeat' }}>
                <Box>
                    <Grid item xs={12} md={6}>
                        <CardActionArea component="a" href="#">
                            <Card sx={{ display: {lg:'flex',xs:'block'} }}>
                                <CardContent sx={{ flex: 1 }}>
                                    <Typography component="span" variant="h5" sx={{fontSize:'1rem',lineHeight:'1.5rem', color:'#767676', marginRight:2, fontFamily:'MBCorpoATitle'}}>
                                        Ajouter une nouvelle configuration
                                    </Typography>
                                    <br/>
                                    <br/>
                                    <Typography component="span" variant="h5" sx={{fontSize:'1rem',lineHeight:'1.5rem', color:'#767676'}}>
                                        <b>Infinity-Life me</b> vous propose de laissez place à vos envies de création en vous laissant configurer votre propre voiture.
                                    </Typography>
                                </CardContent>
                                <CardMedia sx={{textAlignLast:'center',alignSelf:'center', margin:1}}>
                                    <Button
                                        variant="contained"
                                        sx={{borderRadius:'inherit'}}
                                        startIcon={<CarRepairIcon />}
                                        size="large"
                                        onClick={() => {navigate('/configurator')}}
                                    >
                                        Configurer une voiture
                                    </Button>
                                </CardMedia>
                            </Card>
                        </CardActionArea>
                    </Grid>
                </Box>
            </Container>
            )}
        </Box>
    );
}
export default ConsideringContent;
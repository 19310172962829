import React from 'react';
import { formatPrice } from '@/_utils/utils';
// Styles
import './Settings.css';
// Components
import Option from '@/components/public/configurator/Option';

export default function Settings (props)  {

    // console.log(props.settings)

  const selectedOptions = props.settings?.reduce(
    (acc, setting) => ({
      ...acc,
      [setting.prop]: setting.options.find(option =>
        option.value === props.config[setting.prop]
      ) ?? []
    }),
    {}
  );

  return (
    <div className="settings">
      {
          props.settings?.map(setting => {
          if (!setting.options || setting.options.length === 0) {
            return null;
          }
          return (
            <div
              key={setting.label}
              className="settings-group"
            >
              <h3>{setting.label}</h3>
              {
                setting.disclaimer_1 ? (
                  <p className="settings-group-disclaimer">
                    {setting.disclaimer_1}
                  </p>
                ) : null
              }
              <div className={`settings-options settings-options-${setting.type}`}>
               {
                  setting.options.map(option => (
                    <Option
                      {...option}
                      key={option.value}
                      type={setting.type}
                      price={formatPrice(option.price)}
                      active={props.config?.[setting.prop] === option.value}
                      onSelectOption={(value) =>
                          props.onSelectOption(setting.prop, value)
                      }
                    />
                  ))
                }
              </div>
              {
                setting.type !== "text" ? (
                  <div className="settings-group-label">
                    <span>{selectedOptions?.[setting.prop]?.label}</span>
                    <span className="price">
                      {formatPrice(selectedOptions?.[setting.prop]?.price)}
                    </span>
                  </div>
                ) : null
              }
              {
                selectedOptions?.[setting.prop]?.benefits ? (
                  <div className="settings-group-benefits">
                      <p>Le model <b>{selectedOptions.model.label}</b> - <b>{selectedOptions[setting.prop].label}</b> inclus :</p><br/>
                    <ul>
                      {
                        selectedOptions?.[setting.prop]?.benefits?.map((benefit, i) => (
                          <li key={i}>
                            {benefit}
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                ) : null
              }
              {
                setting.disclaimer_2 ? (
                  <p className="settings-group-disclaimer">
                    {setting.disclaimer_2}
                  </p>
                ) : null
              }
            </div>
        )})
      }
    </div>
  );
};

import React, {useEffect, useState} from 'react';
import { formatPrice } from '@/_utils/utils';
// Styles
import './Summary.css';
import Button from "@mui/material/Button";
import {Divider, Skeleton} from "@mui/material";
import Box from "@mui/material/Box";
import {carService} from "../../../../_services/car.service";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import {accountService} from "../../../../_services/account.service";
import Typography from "@mui/material/Typography";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export default function Summary (props)  {
    const selectedModel = props.models?.find(model => model?.carKey === props.config?.model);
    const selectedType = selectedModel?.carPacks?.find(type => type.value === props.config?.car_type);
    const selectedColor = selectedModel?.carColors?.find(color => color.value === props.config?.color);
    const selectedWheels = selectedModel?.carWheels?.find(wheels => wheels.value === props.config?.wheels);
    const selectedInteriorColor = selectedModel?.interiorCarColors?.find(interiorColor => interiorColor.value === props.config?.interior_color);
    const selectedInteriorLayout = selectedModel?.interiorCarLayouts?.find(interiorLayout => interiorLayout.value === props.config?.interior_layout);

    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();
    const sleep = ms => new Promise(r => setTimeout(r, ms));

    async function addCarConfig() {

        const carConfig = document.querySelector('.conf-app');
        carConfig.style.display = 'none';

        const carConfirm = document.querySelector('.conf-car-order');
        carConfirm.style.display = 'block';

        await sleep(5000)

        const formValue = {
          carName : selectedModel.name,
          carModel : selectedModel.carKey,
          carPack: selectedType.value,
          carColor : selectedColor.value,
          carWheels : selectedWheels.value,
          carInteriorColor: selectedInteriorColor.value,
          carInteriorLayout: selectedInteriorLayout?.value ? selectedInteriorLayout?.value : null,
          totalPrice: props.totalPrice
        }


        carService.postCarOrder(formValue)
            .then((res) => {
              enqueueSnackbar("Votre configuration viens d'être prise en compte  !", {
                variant: 'success', // or variant: warning | error | info
                autoHideDuration: 15000,
                anchorOrigin : {horizontal: "right", vertical: "top"}
              })
              enqueueSnackbar("Vous allez être redirigé vers la prochaine étape !", {
                variant: 'success', // or variant: warning | error | info
                autoHideDuration: 15000,
                anchorOrigin : {horizontal: "right", vertical: "top"}

              })
              navigate('/carOrder/confirm/'+res.data.orderRef)

            })
            .catch((err) => {
              console.log(err)
            })

    }

    async function registreCarConfig() {

        const carConfig = document.querySelector('.conf-app');
        carConfig.style.display = 'none';

        const carConfirm = document.querySelector('.conf-car-order');
        carConfirm.style.display = 'block';

        await sleep(5000)

        const formValue = {
            carName : selectedModel.name,
            carModel : selectedModel.carKey,
            carColor : selectedColor.value,
            carWheels : selectedWheels.value,
            carPack: selectedType.value,
            carInteriorColor: selectedInteriorColor.value,
            carInteriorLayout: selectedInteriorLayout?.value ? selectedInteriorLayout?.value : null,
            totalPrice: props.totalPrice
        }

        carService.postCarOrder(formValue)
            .then((res) => {
                enqueueSnackbar("L'enregistrement de votre configuration est bien prise en compte  !", {
                    variant: 'success', // or variant: warning | error | info
                    autoHideDuration: 15000,
                    anchorOrigin : {horizontal: "right", vertical: "top"}
                })
                navigate('/user/dashboard/considering')

            })
            .catch((err) => {
                console.log(err)
            })

    }

  return (
      <div className="summary">
          <h1>Votre {selectedModel?.name}</h1>
          <p className="summary-edd">Livraison estimée: 5-9 semaines</p>
          <div className="summary-content">
              <p>Récapitulatif</p>
              <ul>
                  <li>
                      <span>{selectedModel?.name} - {selectedType?.label}</span>
                      <span>{formatPrice(selectedType?.price)}</span>
                  </li>
                  <li>
                      <span>{selectedColor?.label}</span>
                      <span>{formatPrice(selectedColor?.price)}</span>
                  </li>
                  <li>
                      <span>{selectedWheels?.label}</span>
                      <span>{formatPrice(selectedWheels?.price)}</span>
                  </li>
                  <li>
                      <span>{selectedInteriorColor?.label}</span>
                      <span>{formatPrice(selectedInteriorColor?.price)}</span>
                  </li>
                  <li>
                      <span>{selectedInteriorLayout?.label}</span>
                      <span>{formatPrice(selectedInteriorLayout?.price)}</span>
                  </li>
              </ul>
              <p>
                  <span>Prix total</span>
                  <span>{formatPrice(props.totalPrice)}</span>
              </p>
              {
                  accountService.isLogged() ? (
                      <Box sx={{mt:5}}>
                          <Button variant="contained" fullWidth onClick={() => addCarConfig()} >Valider l'achat</Button>
                          <Divider />
                          <Button sx={{mt:2}} variant="contained" fullWidth onClick={() => registreCarConfig()} >Enregistrer la configuration</Button>
                      </Box>
                  ) : (
                      <Box sx={{mt:5}}>
                          <Alert severity="warning">
                            <AlertTitle>Attention</AlertTitle>
                            Connectez-vous pour valider ou enregistrer votre configuration - <b>{selectedModel?.name}</b>
                          </Alert>
                          <br/>
                          <Button variant="contained" fullWidth onClick={() => navigate('/auth/login')} >Se connecter</Button>
                      </Box>
                  )
              }

          </div>
      </div>
  );


};

import Axios from "./caller.service";

let getAllUsers = async () => {
    const {data} = await Axios.get('api/users')
    return data
}

let getUser = (uid) => {
    return Axios.get('api/users/'+uid)
}

let getMe = () => {
    return Axios.get('api/me')
}

let updateProfileAccount = (uid, form) => {
    return Axios.put('api/users/'+uid, form)
}

let getMyOrders = () => {
    return Axios.get('api/user/orders?order[orderDate]=desc')
}

let getAllGaragistes = () => {
    return Axios.get('api/user/getAllGaragistes')
}

export const userService = {
    getAllUsers, getUser, getMe, updateProfileAccount,getMyOrders,getAllGaragistes
}
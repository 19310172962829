import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import {Grid, Drawer, List, ListItem, ListItemText, Typography, ListItemIcon} from '@mui/material';
import {carModelService} from "../../_services/carModel.service";
import {carService} from "../../_services/car.service";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import GarageIcon from '@mui/icons-material/Garage';
import ListItemButton from '@mui/material/ListItemButton';
import CardActionArea from "@mui/material/CardActionArea";
import {useNavigate} from "react-router-dom";
import { formatPrice } from '@/_utils/utils';
import Loading from '@/_utils/Loading';

export default function CarsTabsTest() {
    const [cars, setCars] = useState([]);
    const [models, setModels] = useState([]);
    const flag = useRef(false)
    const flag2 = useRef(false)
    const [isModelLoading, setIsModelLoading] = useState(true);
    const [isCarLoading, setIsCarLoading] = useState(true);


    useEffect(() => {
        if(flag.current === false) {
            setIsModelLoading(true);
            setIsCarLoading(true);

            carService.getCars()
                .then(response => {
                    setCars(response.data);
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    setIsCarLoading(false)
                });

            carModelService.getAllCarModels()
                .then(response => {
                    setModels(response);
                })
                .catch(error => {
                    console.log(error);
                }).finally(() => {
                    setIsModelLoading(false)
                });
        }
        return () => flag.current = true

    }, []);

    function handleModelSelect(model) {
        if(flag2.current === false) {
            setIsModelLoading(true)
            carService.getCarsByModel(model)
                .then(response => {
                    setCars(response.data);
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    setIsModelLoading(false)
                });
        }
        return () => flag2.current = true

    }

    return (
        <Box sx={{color:'black'}}>
            {isCarLoading || isModelLoading ? <Loading /> : null}
            <AppBar position="static" sx={{backgroundColor:'transparent', boxShadow:'none'}}>
                <Toolbar sx={{ p:'0 !important' }}>
                    <List sx={{ display: 'flex', backgroundColor:'black', width : { xs:'-webkit-fill-available', lg:'auto'} }}>
                        <ListItem button key={'allModels'} onClick={() => handleModelSelect('')}>
                            <ListItemText primary={"Tous les modèles"}  />
                        </ListItem>
                    </List>
                </Toolbar>
            </AppBar>
            <Typography variant="h6" sx={{ p: 2, mt: 2, pl:0  }} >
                Modèles
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3} sx={{color:'black', p:'0 !important',  borderRight : {xs : 'none', lg : '1px solid #e8e8e8'}, borderBottom : {xs : '1px solid #d8d8d8', lg : 'none'}}}>
                    <List sx={{display: { xs:'flex', lg:'block'}, overflow: { xs:'scroll', lg:'hidden'} }}>
                        {models.map(model => (
                            <ListItemButton key={model.id} onClick={() => handleModelSelect(model.name)}>
                                <ListItemIcon sx={{minWidth:'40px', display: { xs:'none', lg:'block'}}} className={'iconGarage'}>
                                    <GarageIcon />
                                </ListItemIcon>
                                <ListItemText primary={model.name} />
                            </ListItemButton>
                        ))}
                    </List>
                </Grid>
                <Grid item xs={12} md={9}>
                    <Grid container spacing={2}>
                        {cars.map(car => (
                            <Grid item key={car.id} xs={12} sm={6} md={4} lg={3}>
                                <CarCard car={car} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

function CarCard({ car }) {
    function getRandomElement(arr) {
        const randomIndex = Math.floor(Math.random() * arr.length);
        return arr[randomIndex];
    }
    let navigate = useNavigate()

    const totalPrice = () => {
        const basePrice = car.carPacks[0].price;
        const colorPrice = car.carColors[0].price;
        const wheelsPrice = car.carWheels[0].price;
        const interiorColorPrice = car.interiorCarColors[0].price;
        const interiorLayoutPrice = car.interiorCarLayouts[0]?.price ?? 0;

        return parseInt(basePrice)  + parseInt(colorPrice) + parseInt(wheelsPrice) + parseInt(interiorColorPrice) + parseInt(interiorLayoutPrice);
    };

    return (
        <Card key={car.id} sx={{ maxWidth: 345 }}>
            <CardActionArea onClick={() => navigate('/car/'+car.carKey)} >
                <CardMedia
                    component="img"
                    image={`${process.env.REACT_APP_PUBLIC_URL}/media/cars/models/model_${car.carKey}/model_${car.carKey}_${getRandomElement(car.carColors).value}_${getRandomElement(car.carWheels).value}.png`}
                    alt={car.model.name}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {car.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {/*{car.price}*/}
                        A partir de {formatPrice(totalPrice())}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Typography variant="caption" color="text.secondary">
                            Voir plus
                        </Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

import React, {useEffect, useRef, useState} from 'react';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
    Alert,
    Card,
    CardContent, FilledInput,
    FormControl,
    InputAdornment,
    InputLabel,
    Stack,
    Tooltip
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {HelpOutline, Visibility, VisibilityOff} from "@mui/icons-material";
import {useLocation, useNavigate} from "react-router-dom";
import {accountService} from "@/_services/account.service";
import {userService} from "../../_services/user.service";
import IconButton from "@mui/material/IconButton";
import Loading from '@/_utils/Loading';
import {useSnackbar} from "notistack";

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const ChangePassword = () => {

    const [user, setUser] = useState([]);
    const [showPassword, setShowPassword] = React.useState(false);
    const [actualPassword, setActualPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isUserLoading, setIsUserLoading] = useState(false);

    const location = useLocation();
    const theme = createTheme();
    const { enqueueSnackbar } = useSnackbar();
    const flag = useRef(false)
    let navigate = useNavigate();

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        if (location.pathname === '/user/profile/changePassword') {
            document.body.classList.add('LoginbodyCover');
            var elements = document.getElementsByClassName('admin_body')
            for (var i = 0; i < elements.length; i++) {
                elements[i].style.background = 'none';
            }

        }
        return () => {
            if (location.pathname === '/user/profile/changePassword') {
                document.body.classList.remove('LoginbodyCover');
                var elements = document.getElementsByClassName('admin_body')
                for (var i = 0; i < elements.length; i++) {
                    elements[i].style.background = '#f8f8f8';
                }
            }
        }
    }, [location.pathname])


    useEffect(() => {
        if(flag.current === false)
        {
            setIsUserLoading(true);

            userService.getMe()
                .then((data) => {
                    setUser(data.data);
                })
                .catch((err) =>{
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    navigate('/')
                })
                .finally(() => {
                    setIsUserLoading(false);
                })
        }

        return () => flag.current = true
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsUserLoading(true);

        if (actualPassword.length === 0 || newPassword.length === 0 || confirmPassword.length === 0)
        {
            enqueueSnackbar("Tous les champs sont obligatoire !", {
                variant: 'warning', // or variant: warning | error | info
                autoHideDuration: 5000,
                anchorOrigin : {horizontal: "right", vertical: "top"}
            })
            setIsUserLoading(false);
            return;
        }

        //Check mdp actuel

        const formValue ={
            email: user.email,
            password: actualPassword
        }

        accountService.login(formValue)
            .then(res => {
                    accountService.saveToken(res.data.token)

                    enqueueSnackbar("Votre mot de passe actuel est validé !", {
                        variant: 'success', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })

                    // Insert du nouveau mdp et redirection
                    const pwd = PWD_REGEX.test(newPassword)
                    const cpwd = PWD_REGEX.test(confirmPassword)

                    if (!pwd || !cpwd)
                    {

                        enqueueSnackbar("Votre nouveau mot de passe à un format incorrect !", {
                            variant: 'warning', // or variant: warning | error | info
                            autoHideDuration: 5000,
                            anchorOrigin : {horizontal: "right", vertical: "top"}
                        })
                        setIsUserLoading(false);
                        return
                    }

                    if (newPassword !== confirmPassword)
                    {

                        enqueueSnackbar("Les nouveau mot de passe ne correspondent pas !", {
                            variant: 'warning', // or variant: warning | error | info
                            autoHideDuration: 5000,
                            anchorOrigin : {horizontal: "right", vertical: "top"}
                        })
                        setIsUserLoading(false);
                        return
                    }

                    if (actualPassword === newPassword)
                    {

                        enqueueSnackbar("Le nouveau mot de passe ne doit pas être similaire à l'ancien !", {
                            variant: 'warning', // or variant: warning | error | info
                            autoHideDuration: 5000,
                            anchorOrigin : {horizontal: "right", vertical: "top"}
                        })
                        setIsUserLoading(false);
                        return
                    }

                    let body = {
                        plainPassword: newPassword
                    }

                    userService.updateProfileAccount(user.id, body)
                        .then(res => {

                            enqueueSnackbar("Votre nouveau mot de passe est jour !", {
                                variant: 'success', // or variant: warning | error | info
                                autoHideDuration: 5000,
                                anchorOrigin : {horizontal: "right", vertical: "top"}
                            })
                            accountService.logout()
                        })
                        .catch(err => {
                            enqueueSnackbar("Une erreur s'est produite !", {
                                variant: 'error', // or variant: warning | error | info
                                autoHideDuration: 5000,
                                anchorOrigin : {horizontal: "right", vertical: "top"}
                            })
                            navigate('/')
                        })
                }
            )
            .catch(error => {
                console.log(error)
                if (error.response.status === 401){
                    enqueueSnackbar("Votre mot de passe actuel n'est pas correct !", {
                        variant: 'warning', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })

                }else{
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                }
            })
            .finally(() => {
                setIsUserLoading(false);
            })

    }


    return (
        <>
            {isUserLoading ? (<Loading />) : null }
                <ThemeProvider theme={theme}>
                    <Container component="main" maxWidth="md">
                        <Box
                            sx={{
                                marginTop: 15,
                                marginBottom: 15,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                backgroundColor:'white'
                            }}
                        >
                            <Box sx={{ width: 1 }}>
                                <Card sx={{ backgroundColor:'black', borderRadius:'0px'}} >
                                    <CardContent sx={{padding:'0px !important', margin:1 }}>
                                        <Typography sx={{ fontSize: 15, color:'white',margin:0,textAlign:'center'  }} gutterBottom>
                                            Modifier votre Adresse Mail
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Box>
                            <Box sx={{ width: 1 }}>
                                <Card sx={{  borderRadius:'0px', boxShadow:'none'}} >
                                    <CardContent sx={{padding:'0px !important', margin:1 }}>
                                        <Typography sx={{ fontSize: 30,margin:0,textAlign:'center'  }} gutterBottom>
                                            Infinity-Life me ID
                                            <Tooltip title="L'infinity-Life me ID vous permet de disposer d'un accès central à nos services ainsi que d'un profil intégré dans lequel vous pouvez modifier vos données.">
                                                <HelpOutline color="secondary" fontSize="small" />
                                            </Tooltip>

                                        </Typography>
                                        <Typography sx={{ fontSize: 15, margin:0,textAlign:'center'  }} gutterBottom>
                                            Veuillez saisir votre nouvelle adresse e-mail et la confirmer
                                        </Typography>
                                        <Stack sx={{ width: '100%'}} >
                                            <Alert severity="warning" sx={{justifyContent:'center'}}>Si vous modifier votre adresse mail vous allez devoir vous reconnecter</Alert>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Box>
                            <Box component="form" className={'form'} sx={{ mt: 1, maxWidth:'75%' }} onSubmit={handleSubmit}>

                                <FormControl sx={{width: '100%', m:1}} variant="filled">
                                    <InputLabel htmlFor="filled-adornment-password">Mot de passe actuel</InputLabel>
                                    <FilledInput
                                        id="filled-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        name="actualPassword"
                                        onChange={(e) => setActualPassword(e.target.value)}
                                        value={actualPassword}
                                        required
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>

                                <FormControl sx={{width: '100%', m:1}} variant="filled">
                                    <InputLabel htmlFor="filled-adornment-password">Nouveau Mot de passe</InputLabel>
                                    <FilledInput
                                        id="filled-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        name="newPassword"
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        value={newPassword}
                                        required
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>

                                <FormControl sx={{width: '100%', m:1}} variant="filled">
                                    <InputLabel htmlFor="filled-adornment-password">Confirmer le Mot de passe</InputLabel>
                                    <FilledInput
                                        id="filled-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        name="newPassword"
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        value={confirmPassword}
                                        required
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ m:1 }}
                                >
                                    Enregistrer
                                </Button>
                            </Box>
                            <Box sx={{ width: 1 }}>
                                <Card sx={{ backgroundColor:'black', borderRadius:'0px'}} >
                                    <CardContent sx={{padding:'0px !important', margin:1, textAlign:'end' }}>
                                        <Button variant="text" style={{fontSize:12, color:'white',margin:0}}>
                                            Mentions légales
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Box>
                        </Box>
                    </Container>
                </ThemeProvider>
        </>
    );
};

export default ChangePassword;
import React from 'react';
import OwningContent from "@/components/admin/OwningContent";

const Owning = () => {

    return (
        <>
            <OwningContent />
        </>
    );
};

export default Owning;
import React from 'react';
// Styles
import './Option.css';

const types = ["text", "color", "image"];

export default function Option (props)  {
  if (!types.includes(props.type)) return null;

  let classNames = `option ${props.type}-option`;
  if (props.active) {
    classNames += ' active';
  }

  // console.log(props)

  const RenderContent = () => {
    switch(props.type) {
      case "text":
        return (
          <>
            <span>{props.label}</span>
            {props.price ? <span className="price">{props.price}</span> : null}
          </>
        );
      case "image":
        return <img src={process.env.REACT_APP_PUBLIC_URL+props.src} alt={props.label} title={props.label} />;
      case "color":
        return <div className={props.value} title={props.label} />;
      default:
        return null;
    }
  }

  return (
    <div
      role="button"
      className={classNames}
      onClick={() => props.onSelectOption(props.value)}
    >
      <RenderContent />
    </div>
  );
};

import React, {useEffect, useState} from 'react';
// Styles
import './Slideshow.css';
import {log} from "@craco/craco/dist/lib/logger";
import {ref} from "yup";
import {Skeleton} from "@mui/material";

export default function Slideshow (props)  {

    // const [items, setItems] = useState([]);
    //
    // const [isLoading, setIsLoading] = useState(true);
    //
    // useEffect(() => {
    //     if (props.items.length > 0)
    //     {
    //         setItems(props.items)
    //         setIsLoading(false)
    //     }
    //
    // }, []);
    //
    // if (isLoading) {
    //     //TODO : faire une belle page laoding
    //     return (
    //         <div>
    //             {/* For variant="text", adjust the height via font-size */}
    //             <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
    //
    //             {/* For other variants, adjust the size with `width` and `height` */}
    //             <Skeleton variant="circular" width={40} height={40} />
    //             <Skeleton variant="rectangular" width={210} height={60} />
    //             <Skeleton variant="rounded" width={210} height={60} />
    //         </div>
    //     );
    // }


  return (
      <div className="slideshow">
          {
          props.items.map((item, i) => (
              <div
                  key={item.alt}
                  className={
                    i === props.index
                        ? 'slideshow-slide active'
                        : 'slideshow-slide'
                  }
              >
                <img
                    src={item.url}
                    alt={item.alt}
                    className={item.scale ? 'scale' : null}
                />
              </div>
          ))
        }
        {props.showPrev
            ? (
                <span
                    onClick={() => props.onClickPrev()}
                    className="arrow arrow-prev"
                />
            ) : null
        }
        {props.showNext
            ? (
                <span
                    onClick={() => props.onClickNext()}
                    className="arrow arrow-next"
                />
            ) : null
        }
      </div>
  )
}

import React from 'react'
import './Wrapper.css'

function Wrapper(props){
  return (
    <div className='Wrapper' style={ props.background && {backgroundImage: `url(${props.background})`, backgroundSize:'cover'} }>
        {props.children}
    </div>
  )
}

export default Wrapper
import React, { useState, useEffect } from "react";
import {MapContainer, TileLayer, Circle, LayerGroup} from "react-leaflet";
import 'leaflet/dist/leaflet.css';

function CarMap({ location }) {
    const [map, setMap] = useState(null);
    const [position, setPosition] = useState(null);

    useEffect(() => {
        if (map) {
            map.setView(position, 13);
        }
    }, [position]);

    useEffect(() => {
        const fetchCoords = async () => {
            try {
                const response = await fetch(
                    `https://nominatim.openstreetmap.org/search?q=${location}&format=json&limit=1`
                );
                const data = await response.json();
                const { lat, lon } = data[0];
                setPosition([lat, lon]);
            } catch (error) {
                console.log(error);
            }
        };
        fetchCoords();
    }, [location]);

    const greenOptions = { color: '#fd4f01', fillColor: '#d93838' }

    return (
        <>
            {position && (
                <MapContainer center={position} zoom={13} whenCreated={setMap} style={{ height: "450px", width: "100%" }}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <LayerGroup>
                        <Circle
                            center={position}
                            pathOptions={greenOptions}
                            radius={2000}
                        />
                    </LayerGroup>
                </MapContainer>
            )}
        </>
    );

}

export default CarMap;

import React from 'react';
import {Outlet} from "react-router-dom";
import Navbar from "../../components/global/Navbar";
import Footer from "../../components/global/Footer";

const Layout = () => {

    return (
        <div className="layout">
            {/*Revoir le responsive*/}
            <Navbar  />

            <Outlet/>

            <Footer />


        </div>
    );
};

export default Layout;

import * as React from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import {Alert, AlertTitle, Container} from "@mui/material";
import Grid from "@mui/material/Grid";
import { styled } from '@mui/material/styles';
import {useNavigate} from "react-router-dom";

export default function ProfileAdressesContent() {

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const navigate = useNavigate();

    return (
        <Container maxWidth='lg' sx={{paddingTop:'20px', paddingBottom:'20px'}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <Item sx={{textAlign:'start',padding:'5px'}}>

                        <Button onClick={() => navigate('/user/profile')} size="large" sx={{color:"#959595",textTransform:'none',fontFamily:"MBCorpoATitle"}}>
                            Détails du compte
                        </Button>
                        <br/>
                        <Button size="large" sx={{color:"#959595",textTransform:'none',fontFamily:"MBCorpoATitle", background:'#e8e8e8'}}>
                            Adresses
                        </Button>
                        <br/>
                        <Button size="large" onClick={() => navigate('/user/profile/security')} sx={{color:"#959595",textTransform:'none',fontFamily:"MBCorpoATitle"}}>
                            Connexion et sécurité
                        </Button>
                    </Item>
                </Grid>
                <Grid item xs={12} md={9}>
                    <Item sx={{textAlign:'start'}}>
                        <Container maxWidth='lg'>
                            <h1 style={{fontFamily:"MBCorpoATitle"}}>Adresses</h1>
                            <span>Veuillez indiquer votre adresse ainsi que votre ville.</span>
                            <br/>
                            <br/>
                            <Alert severity="info">
                                <AlertTitle>Info</AlertTitle>
                                Cette fonctionnalité sera bientôt disponnible
                            </Alert>
                        </Container>
                    </Item>
                </Grid>
            </Grid>
        </Container>
    );
}
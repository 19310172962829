import React from "react";
import Leaflet from "leaflet";
import {MapContainer, Marker, Popup, TileLayer, useMap} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";

let DefaultIcon = Leaflet.icon({
    ...Leaflet.Icon.Default.prototype.options,
    iconUrl: icon,
    iconRetinaUrl: iconRetina,
    shadowUrl: iconShadow
});
Leaflet.Marker.prototype.options.icon = DefaultIcon;

function SetViewOnClick({ coords }) {
    const map = useMap();
    map.setView(coords, map.getZoom());

    return null;
}

function MapComp({ selectedMagasin, coords, zoom, magasins }) {

    return (
        <MapContainer
            key={selectedMagasin}
            center={coords}
            zoom={zoom}
            style={{ height: '500px' }}
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {magasins.map(magasin => (
                <Marker key={magasin.id} position={[magasin.latitude, magasin.longitude]}>
                    {magasin.nom ? (
                        <Popup>{magasin.nom}</Popup>
                    ) : (
                        <Popup>{magasin.firstname} {magasin.lastname}</Popup>
                    )}
                </Marker>
            ))}
            <SetViewOnClick coords={coords} />
        </MapContainer>

    );
}

export default MapComp;

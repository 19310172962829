import {useState, useEffect, useRef} from 'react';
import {Container, Divider, Grid, Skeleton} from '@mui/material';
import CarCard from '@/components/public/CarCard.js';
import {usedCarService} from "../../_services/usedCar.service";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Headline from "../../components/global/Headline";
import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import {carModelService} from "../../_services/carModel.service";

const UsedCar = () => {

    const [isCarLoading, setIsCarLoading] = useState(true);
    const [isCarModelsLoading, setIsCarModelsLoading] = useState(true);
    const [cars, setCars] = useState([]);
    const [search, setSearch] = useState("");
    const [models, setModels] = useState([]);
    const [minPrice, setMinPrice] = useState("");
    const [maxPrice, setMaxPrice] = useState("");
    const [model, setModel] = useState("");
    const [filteredCars, setFilteredCars] = useState([]);
    const flag = useRef(false)
    const { enqueueSnackbar } = useSnackbar();
    let navigate = useNavigate();

    useEffect(() => {
        if(flag.current === false)
        {
            setIsCarLoading(true);
            setIsCarModelsLoading(true);
            usedCarService.getUsedCars()
                .then((data) => {
                    setCars(data.data)
                    setFilteredCars(data.data)
                })
                .catch((err) =>{
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    navigate('/')
                })
                .finally(() => {
                    setIsCarLoading(false);
                })
                carModelService.getAllCarModels()
                    .then((data) => {
                        setModels(data);
                    })
                    .catch((err) =>{
                        enqueueSnackbar("Une erreur s'est produite !", {
                            variant: 'error', // or variant: warning | error | info
                            autoHideDuration: 5000,
                            anchorOrigin : {horizontal: "right", vertical: "top"}
                        })
                        navigate('/')
                    })
                    .finally(() => {
                        setIsCarModelsLoading(false);
                    })
        }
        return () => flag.current = true

    }, []);

    const handleSearch = (e) => {
        setSearch(e.target.value);
        setFilteredCars(
            cars.filter(
                (car) =>
                    car.title.toLowerCase().includes(e.target.value.toLowerCase()) ||
                    car.model.toLowerCase().includes(e.target.value.toLowerCase())
            )
        );
    };

    const handleMinPrice = (e) => {
        const min = parseFloat(e.target.value);
        if (isNaN(min)) {
            setMinPrice("");
            if (maxPrice !== '')
            {
                setFilteredCars(
                    cars.filter((car) => car.price <= maxPrice)
                )
                return;
            }else{
                setFilteredCars(cars)
                return;
            }
        } else {
            setMinPrice(min);
        }

        if (maxPrice !== '')
        {
            setFilteredCars(
                cars.filter((car) => car.price >= min && car.price <= maxPrice)
            );
        }else{
            setFilteredCars(
                cars.filter((car) => car.price >= min)
            );
        }
    };

    const handleMaxPrice = (e) => {
        const max = parseFloat(e.target.value);
        if (isNaN(max)) {
            setMaxPrice("");
            if (minPrice !== '')
            {
                setFilteredCars(
                    cars.filter((car) => car.price >= minPrice)
                );
                return;
            }else{
                setFilteredCars(cars)
                return;
            }
        } else {
            setMaxPrice(max);
        }

        if (minPrice !== '')
        {
            setFilteredCars(
                cars.filter((car) => car.price >= minPrice && car.price <= max)
            );
        }else{
            setFilteredCars(
                cars.filter((car) => car.price <= max)
            );
        }
    };

    const handleModel = (e) => {
        const selectedModel = e.target.value;
        setModel(selectedModel);
        setFilteredCars(
            cars.filter((car) => selectedModel === "" || car.model === selectedModel)
        );
    };

    if (isCarLoading || isCarModelsLoading) {
        return (
            <Container maxWidth='lg' sx={{paddingTop:'20px', paddingBottom:'20px', background:'white'}}>
                {/* For other variants, adjust the size with `width` and `height` */}
                <Skeleton variant="rounded" height={150} sx={{ m: 2 }} />
                <Skeleton variant="rounded" height={150} sx={{ m: 2 }} />
                <Skeleton variant="rounded" height={150} sx={{ m: 2 }} />
            </Container>
        );
    }

    return (
        <Box sx={{background:'white'}}>
            <Container maxWidth="md">
                <Headline
                    title={"Voitures D'occasion"}
                    paragraph={"Retrouver toutes nos voitures d'occasion remis à neuf"}
                    button={'Ajouter votre véhicule 🚘'}
                    buttonLink={'/user/occasion/add'}
                    styleBackground={"white"}
                    styleTitle={"black"}
                    styleParagraph={"black"}
                />
                {filteredCars ? (
                    <>
                        <Typography variant="h5" component="h2" align="left" sx={{color:'black'}}>
                            Filtres :
                        </Typography>
                        <Grid container spacing={2} >
                            <Grid item xs={12} md={12} lg={12}>
                                <TextField
                                    label="Rechercher"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={search}
                                    onChange={handleSearch}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <TextField
                                    select
                                    label="Modèle"
                                    fullWidth
                                    value={model}
                                    onChange={handleModel}
                                    variant="outlined"
                                >
                                    <MenuItem value="">Tous les modèles</MenuItem>
                                    {models.map((name) => (
                                        <MenuItem
                                            key={name.id}
                                            value={name.name}
                                        >
                                            {name.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <TextField
                                    label="Prix minimum"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={minPrice}
                                    onChange={handleMinPrice}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <TextField
                                    label="Prix maximum"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={maxPrice}
                                    onChange={handleMaxPrice}
                                />
                            </Grid>
                        </Grid>

                        {filteredCars.length > 0 ? (
                            <Grid container spacing={2}>
                                <Grid item key={'none'} xs={12} sm={12} md={12} lg={12}>
                                    {filteredCars.map((car) => (
                                        <Grid item key={car.id} xs={12} sm={12} md={12} lg={12}>
                                            <CarCard car={car} />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        ):(
                            <Grid container spacing={2}>
                                <Grid item key={'none'} xs={12} sm={12} md={12} lg={12}>
                                    <Typography variant="h5" component="h2" align="left" style={{color:'grey',textAlign:'center'}}>
                                        Aucune voiture disponnible 🚘
                                    </Typography>

                                    <Skeleton variant="rounded" height={150} sx={{ m: 2 }} />
                                    <Skeleton variant="rounded" height={150} sx={{ m: 2 }} />
                                    <Skeleton variant="rounded" height={150} sx={{ m: 2 }} />
                                </Grid>
                            </Grid>
                        )}
                    </>
                    ) : (
                        <Grid container spacing={2}>
                            <Grid item key={'none'} xs={12} sm={12} md={12} lg={12}>
                                <Typography variant="h5" component="h2" align="left" style={{color:'grey',textAlign:'center'}}>
                                Aucune voiture disponnible 🚘
                                </Typography>

                                <Skeleton variant="rounded" height={150} sx={{ m: 2 }} />
                                <Skeleton variant="rounded" height={150} sx={{ m: 2 }} />
                                <Skeleton variant="rounded" height={150} sx={{ m: 2 }} />
                            </Grid>
                        </Grid>
                    )}
            </Container>
        </Box>
    );
};

export default UsedCar;

import React, { useState } from 'react';
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {Divider, FormControl, Select} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {cartService} from "../../_services/cart.service";
import {useSnackbar} from "notistack";

export default function CartItem(props) {
    const [quantity, setQuantity] = useState(props.quantity);
    const { enqueueSnackbar } = useSnackbar();

    function handleChange(event) {
        setQuantity(parseInt(event.target.value));

        props.onQuantityChange(parseInt(event.target.value), props.id);

        cartService.changeCartQuantity(props.id, parseInt(event.target.value))
            .then(() => {

                // TODO : ajouter des loading partout

                enqueueSnackbar("La quantité à bien été modifié", {
                    variant: 'success', // or variant: warning | error | info
                    autoHideDuration: 5000,
                    anchorOrigin : {horizontal: "right", vertical: "top"}

                })

            })
    }

    const handleDeleteItem = (cartDetailId) => {

        props.onRemoveItem(cartDetailId);

        cartService.deleteCartItem(cartDetailId)
            .then(() => {

                // TODO : ajouter des loading partout

                enqueueSnackbar("L'article à bien été supprimé de votre panier", {
                    variant: 'success', // or variant: warning | error | info
                    autoHideDuration: 5000,
                    anchorOrigin : {horizontal: "right", vertical: "top"}

                })
            })

    };

    return (
        <Card sx={{ display: 'flex', margin:2, boxShadow:'none' }}>
            <CardMedia
                component="img"
                sx={{ width: 151, height:'fit-content',alignSelf:'center' }}
                image={process.env.REACT_APP_PUBLIC_URL+props.image}
                alt="Live from space album cover"
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', width:'100%' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5">
                        {props.name}  {props.id}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        {/*<Chip label="en stock" color="success" variant="outlined" />*/}
                        {props.price} €
                    </Typography>
                </CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                    <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-filled-label">Qté</InputLabel>
                        <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={quantity}
                            onChange={handleChange}
                        >
                            {
                                [1,2,3,4,5,6,7,8,9,10].map((value) => (
                                    <MenuItem value={value}>{value}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>

                    <Divider orientation="vertical" flexItem sx={{margin:2}} />

                    <Typography component="div" variant="p" >
                        <IconButton aria-label="delete"
                                    onClick={() => handleDeleteItem(props.id)}
                        >
                            <DeleteIcon fontSize="inherit" color={'error'} />
                        </IconButton>
                    </Typography>

                </Box>
            </Box>
        </Card>
    );
}
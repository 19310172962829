import * as React from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {accountService} from "@/_services/account.service";
import "@/css/login.css"
import {HelpOutline} from "@mui/icons-material";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Card, CardContent, Tooltip} from "@mui/material";
import {useEffect, useState} from "react";
import Loading from '@/_utils/Loading';

const ForgetPassword = () => {
    const [email, setEmail] = useState('')
    const [isUserLoading, setIsUserLoading] = useState(false);

    let navigate = useNavigate();
    const location = useLocation();
    const theme = createTheme();

    function handleEmailChange(event) {
        setEmail(event.target.value);
    }

    useEffect(() => {
        if (location.pathname === '/auth/forgetPassword') {
            document.body.classList.add('LoginbodyCover');
        }
        return () => {
            if (location.pathname === '/auth/forgetPassword') {
                document.body.classList.remove('LoginbodyCover');
            }
        }
    }, [location.pathname])

    const handleSubmit = (event) => {
        event.preventDefault()
        setIsUserLoading(true);

        accountService.forgetPassword(email)
            .then((res) => {
                const carConfirm = document.querySelector('.msg');
                carConfirm.style.display = 'block';
                carConfirm.innerHTML = "<div style='background: green; color: white'>Vous allez recevoir un mail avec un lien de rénitialisation de votre mot de passe</div>";
            })
            .catch((err) => {
                const carConfirm = document.querySelector('.msg');
                carConfirm.style.display = 'block';
                carConfirm.innerHTML = "<div style='background: red; color: white'>Aucun compte ne correspond à cette adresse mail !</div>";
            })
            .finally(() => {
                setIsUserLoading(false)
            });

    }

    return (
        <>
            {isUserLoading ? <Loading /> : null}
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="md">
                    <Box
                        sx={{
                            marginTop: 30,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            backgroundColor:'white'
                        }}
                    >
                        <Box sx={{ width: 1 }}>
                            <Card sx={{ backgroundColor:'black', borderRadius:'0px'}} >
                                <CardContent sx={{padding:'0px !important', margin:1 }}>
                                    <Typography sx={{ fontSize: 15, color:'white',margin:0  }} gutterBottom>
                                        Mot de passe oublié
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box sx={{ width: 1 }}>
                            <Card sx={{  borderRadius:'0px', boxShadow:'none'}} >
                                <CardContent sx={{padding:'0px !important', margin:1 }}>
                                    <Typography sx={{ fontSize: 30,margin:0,textAlign:'center' }} gutterBottom>
                                        Infinity-Life me ID
                                        <Tooltip title="L'infinity-Life me ID vous permet de disposer d'un accès central à nos services ainsi que d'un profil intégré dans lequel vous pouvez modifier vos données.">
                                            <HelpOutline color="secondary" fontSize="small" />
                                        </Tooltip>

                                    </Typography>
                                    <Typography sx={{ fontSize: 15, margin:0,textAlign:'center'  }} gutterBottom>
                                        Veuillez saisir votre adresse e-mail de récupération
                                    </Typography>
                                    <Typography className={'msg'} sx={{ fontSize: 15, margin:0,textAlign:'center',display:'none'  }} gutterBottom>

                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                type="email"
                                value={email}
                                label="Adresse mail de récupération"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={handleEmailChange}
                            />

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Réinitialiser
                            </Button>
                            <Grid sx={{justifyContent:'end'}} container>
                                <Grid >
                                    <Typography sx={{ fontSize: 15, margin:0, color:'black'}} >
                                        <Button variant="text" onClick={() => {navigate('/auth/login')}} style={{fontSize:12}}>
                                            Se connecter
                                        </Button>
                                    </Typography>

                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ width: 1 }}>
                            <Card sx={{ backgroundColor:'black', borderRadius:'0px'}} >
                                <CardContent sx={{padding:'0px !important', margin:1, textAlign:'end' }}>
                                    <Button variant="text" style={{fontSize:12, color:'white',margin:0}}>
                                        Mentions légales
                                    </Button>
                                </CardContent>
                            </Card>
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </>
    );
};

export default ForgetPassword;

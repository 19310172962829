import * as React from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {accountService} from "@/_services/account.service";
import {HelpOutline} from "@mui/icons-material";
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Card, CardContent, Tooltip} from "@mui/material";
import {useEffect, useState} from "react";
import Loading from '@/_utils/Loading';
import "@/css/login.css"

const Login = () => {
    const [isUserLoading, setIsUserLoading] = useState(false);
    const [formValue, setFormValue] = useState({
        email: "",
        password: ""
    })

    const { enqueueSnackbar } = useSnackbar();
    let navigate = useNavigate();
    const location = useLocation();
    const theme = createTheme();

    function handleEmailChange(event) {
        setFormValue({ ...formValue, email: event.target.value });
    }

    function handlePasswordChange(event) {
        setFormValue({ ...formValue, password: event.target.value });
    }

    useEffect(() => {
        if (location.pathname === '/auth/login') {
            document.body.classList.add('LoginbodyCover');
        }
        return () => {
            if (location.pathname === '/auth/login') {
                document.body.classList.remove('LoginbodyCover');
            }
        }
    }, [location.pathname])


    const handleSubmit = (event) => {
        event.preventDefault()
        setIsUserLoading(true);

        accountService.login(formValue)
            .then((res) => {
                accountService.saveToken(res.data.token)

                enqueueSnackbar("Vous êtes connecté !", {
                    variant: 'success', // or variant: warning | error | info
                    autoHideDuration: 5000,
                    anchorOrigin : {horizontal: "right", vertical: "top"}
                })

                navigate('/', {replace: true})
            })
            .catch((error) => {
                console.log(error)
                if (error.response.status === 401){
                    enqueueSnackbar("Votre adresse mail ou mot de passe ne sont pas correct !", {
                        variant: 'warning', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                }else{
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                }
            })
            .finally(() => {
                setIsUserLoading(false)
            });
    }


    return (
        <>
                {isUserLoading ? <Loading /> : null}
                <ThemeProvider theme={theme}>
                    <Container component="main" maxWidth="md">
                        <Box
                            sx={{
                                marginTop: 30,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                backgroundColor:'white'
                            }}
                        >
                            <Box sx={{ width: 1 }}>
                                <Card sx={{ backgroundColor:'black', borderRadius:'0px'}} >
                                    <CardContent sx={{padding:'0px !important', margin:1 }}>
                                        <Typography sx={{ fontSize: 15, color:'white',margin:0  }} gutterBottom>
                                            Se connecter
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Box>
                            <Box sx={{ width: 1 }}>
                                <Card sx={{  borderRadius:'0px', boxShadow:'none'}} >
                                    <CardContent sx={{padding:'0px !important', margin:1 }}>
                                        <Typography sx={{ fontSize: 30,margin:0,textAlign:'center' }} gutterBottom>
                                            Infinity-Life me ID
                                            <Tooltip title="L'infinity-Life me ID vous permet de disposer d'un accès central à nos services ainsi que d'un profil intégré dans lequel vous pouvez modifier vos données.">
                                                <HelpOutline color="secondary" fontSize="small" />
                                            </Tooltip>

                                        </Typography>
                                        <Typography sx={{ fontSize: 15, margin:0,textAlign:'center'  }} gutterBottom>
                                            Veuillez saisir votre adresse e-mail ou votre numéro de téléphone portable
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Box>
                            <Box component="form" onSubmit={handleSubmit} sx={{ m: 2}}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    type="email"
                                    value={formValue.email}
                                    label="Adresse mail"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    onChange={handleEmailChange}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Mot de passe"
                                    id="password"
                                    name="password"
                                    type="password"
                                    value={formValue.password}
                                    autoComplete="off"
                                    onChange={handlePasswordChange}
                                />

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Connexion
                                </Button>
                                <Button
                                    fullWidth
                                    variant="contained" onClick={() => {navigate('/')}}
                                    sx={{ mb: 2 }}
                                >
                                    Accueil
                                </Button>
                                <Typography sx={{ m:0}} >
                                    <Button variant="text" onClick={() => {navigate('/auth/forgetPassword')}} style={{fontSize:12}}>
                                        Mot de passe oublié ?
                                    </Button>
                                </Typography>
                                <Grid sx={{justifyContent:'end'}} container>
                                    <Grid >
                                        <Typography sx={{ fontSize: 15, margin:0, color:'black'}} >
                                            Pas encore de Infinity-Life me ID ?
                                            <Button variant="text" onClick={() => {navigate('/auth/register')}} style={{fontSize:12}}>
                                                S'enregistrer
                                            </Button>
                                        </Typography>

                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{ width: 1 }}>
                                <Card sx={{ backgroundColor:'black', borderRadius:'0px'}} >
                                    <CardContent sx={{padding:'0px !important', margin:1, textAlign:'end' }}>
                                        <Button variant="text" style={{fontSize:12, color:'white',margin:0}}>
                                            Mentions légales
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Box>
                        </Box>
                    </Container>
                </ThemeProvider>


        {/*<Container >*/}
        {/*    <Content className="popup">*/}
        {/*        <FlexboxGrid justify="center">*/}
        {/*            <FlexboxGrid.Item header={<h3>test</h3>} colspan={12} style={{ backgroundColor: "white"}}>*/}
        {/*                <Header className="headerTitle">*/}
        {/*                    Se connecter*/}
        {/*                </Header>*/}

        {/*                <Panel header={*/}

        {/*                    <h3 style={{fontFamily: 'auto', fontWeight: 'normal'}}>*/}
        {/*                            Infinity-Life ID*/}
        {/*                        <Whisper placement="bottom" controlId="control-id-focus" trigger="hover" speaker={tooltip}>*/}
        {/*                            <Button style={{border:'none', cursor:'pointer',appearance:'none',backgroundColor:'inherit'}}><HelpOutlineIcon color="blue" size="5em" /></Button>*/}
        {/*                        </Whisper>*/}
        {/*                    </h3>*/}


        {/*                } bordered>*/}
        {/*                    <span>Veuillez saisir votre adresse e-mail et votre mot de passe</span>*/}
        {/*                    <Form onChange={setFormValue} onSubmit={handleSubmit} style={{marginTop: '30px'}} fluid>*/}
        {/*                        <Form.Group>*/}
        {/*                            <Form.Control placeholder="Adresse e-mail*" name="email" type="text" value={formValue.email}/>*/}
        {/*                        </Form.Group>*/}
        {/*                        <Form.Group>*/}
        {/*                            <Form.Control placeholder="Mot de passse*"  name="password" type="password" value={formValue.password} autoComplete="off" />*/}
        {/*                        </Form.Group>*/}
        {/*                        <Form.Group>*/}
        {/*                            <ButtonToolbar>*/}
        {/*                                <Button type="submit" appearance="primary" block>Connectez-vous</Button>*/}
        {/*                                <p className="registerC">Pas encore de compte ? <a href="auth/register">S'enregistrer</a></p>*/}
        {/*                            </ButtonToolbar>*/}
        {/*                        </Form.Group>*/}
        {/*                    </Form>*/}
        {/*                </Panel>*/}
        {/*                <Footer className="footerTitle">*/}
        {/*                    Mentions légales*/}
        {/*                </Footer>*/}

        {/*            </FlexboxGrid.Item>*/}
        {/*        </FlexboxGrid>*/}
        {/*    </Content>*/}
        {/*</Container>*/}
        </>
    );
};

export default Login;

import React, {useEffect, useRef, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from "@mui/material/Toolbar";
import {Person} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction"
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import {useNavigate} from "react-router-dom";
import multiMonthPlugin from '@fullcalendar/multimonth'
import {maintenanceService} from "../../_services/maintenance.service";
import {useSnackbar} from "notistack";
import Loading from '@/_utils/Loading';
import {userService} from "@/_services/user.service";

const Home = () => {

    const [isDatasLoading, setIsDatasLoading] = useState(false);
    const [isUserLoading, setIsUserLoading] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [datas, setDatas] = React.useState([]);
    const [user, setUser] = useState([]);

    const navigate = useNavigate()
    const flag = useRef(false)
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if(flag.current === false)
        {
            setIsDatasLoading(true);
            setIsUserLoading(true);

            userService.getMe()
                .then((data) => {
                    setUser(data.data);
                })
                .catch((err) =>{
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    navigate('/')
                })
                .finally(() => {
                    setIsUserLoading(false);
                })

            maintenanceService.getAllMaintenances()
                .then((res) => {
                    setDatas(res.data)
                })
                .catch((error) => {
                    if (error.response.status === 401){
                        enqueueSnackbar("Votre adresse mail ou mot de passe ne sont pas correct !", {
                            variant: 'warning', // or variant: warning | error | info
                            autoHideDuration: 5000,
                            anchorOrigin : {horizontal: "right", vertical: "top"}
                        })
                    }else{
                        enqueueSnackbar("Une erreur s'est produite !", {
                            variant: 'error', // or variant: warning | error | info
                            autoHideDuration: 5000,
                            anchorOrigin : {horizontal: "right", vertical: "top"}
                        })
                    }
                })
                .finally(() => {
                    setIsDatasLoading(false)
                });
        }

        return () => flag.current = true

    }, []);


    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleDateClick = (arg) => { // bind with an arrow function
        alert(arg.dateStr)
    }

    const handleEventClick = (info) => { // bind with an arrow function

        const date = info.event._instance.range.start
        const data = []
        data.push({
            title: info.event.title,
            id: info.event.id,
            done: info.event.extendedProps.done,
            idClient:info.event.extendedProps.id_client,
            idGaragiste: info.event.extendedProps.id_garagiste,
            date: date
        })

        setModalData(data[0])
        setOpen(true)
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        background: 'white !important',
        border: '2px solid #000',
        boxShadow: 24,
        color:'black',
        p: 4,
    };

    return (
    <>
        {isDatasLoading || isUserLoading ? <Loading /> : null}
        <AppBar position="relative" sx={{background:'white', color:'black'}}>
            <Toolbar>
                <Person sx={{ mr: 2 }} />
                <Typography variant="h6" color="inherit" noWrap>
                    Planning Garagiste <b>{user.firstname} {user.lastname}</b>
                </Typography>
            </Toolbar>
        </AppBar>

        <Box sx={{mr: {xs:0, lg:2 },ml: {xs:0, lg:2 }, mt:2}}>
            <FullCalendar
                plugins={[ dayGridPlugin, interactionPlugin, timeGridPlugin, multiMonthPlugin ]}
                dateClick={handleDateClick}
                initialView="dayGridMonth"
                weekends={true}
                locale='fr'
                headerToolbar={{
                    end: 'dayGridMonth,multiMonthYear,dayGridWeek,dayGridDay'
                }}
                eventContent={renderEventContent}
                eventClick={handleEventClick}
                events={datas}
            />
        </Box>


        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {modalData?.title}
                </Typography>
                <br/>
                {modalData?.date.toLocaleDateString("fr") < new Date().toLocaleDateString("fr") ? (
                    <Typography variant={'subtitle'}>L'entretien avait débuté à 9 heures</Typography>
                ) : (
                    <Typography variant={'subtitle'}>L'entretien débutera à 9 heures</Typography>
                )}
                {(modalData?.date.toLocaleDateString("fr") === new Date().toLocaleDateString("fr") && !modalData.done) && (
                    <Button variant="contained" onClick={() => {navigate('/garagiste/maintenance/'+modalData?.id)}} sx={{mt:2}}>
                        Commencer
                    </Button>
                )}
            </Box>
        </Modal>
    </>
    );
};

function renderEventContent(eventInfo) {
    const date = eventInfo.event._instance.range.start

    return (
        <Box sx={{
             backgroundColor: eventInfo.event.extendedProps.done
                 ? 'green'
                 : date.toLocaleDateString("fr") < new Date().toLocaleDateString("fr")
                     ? 'red'
                     : 'orange',
             overflow:'hidden'
             }}
        >
            <Typography variant={'span'} gutterBottom>{eventInfo.event.title}</Typography>
        </Box>
    )
}

function isToday(date) {
    if (!date) {
        return false;
    }

    const today = new Date();
    const dateToCompare = date;

    return (
        !isNaN(dateToCompare.getTime()) &&
        dateToCompare.getDate() === today.getDate() &&
        dateToCompare.getMonth() === today.getMonth() &&
        dateToCompare.getFullYear() === today.getFullYear()
    );
}

function isDateBefore(date1, date2) {
    return date1 < date2;
}


export default Home;
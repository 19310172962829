export const HeadlineData = {
    exterior: {
      headlineTitle: 'Extérieur.',
      headlineParagraph: 'La Classe A en tant qu\'objet personnel et individuel - chaque client peut personnaliser entièrement son véhicule et le configurer pour qu\'il soit plus classique ou axé sur le style de vie. Les lignes de design et d\'équipement définissent ici la direction. Néanmoins, ils offrent beaucoup de possibilités pour d\'autres touches spéciales.'
    },
    interior: {
      headlineTitle: 'Intérieur.',
      headlineParagraph: 'L\'intérieur allie la sportivité à une sensation d\'espace encore plus généreuse. En même temps, il répond aux normes esthétiques les plus élevées et ajoute cela à une polyvalence de conception pour les personnes ayant un flair créatif.'
    },
    technology: {
      headlineTitle: 'Technologie.',
      headlineParagraph: ''
    },
    safety: {
      headlineTitle: 'Sécurité.',
      headlineParagraph: 'Infinity-Life Intelligent Drive combine des innovations en matière de sécurité et de commodité. Avec ces systèmes intelligents, Mercedes-Benz établit non seulement des normes en matière d\'ingénierie automobile, mais fait également un autre pas en avant important sur la voie d\'une conduite sans accident et autonome.'
    }
  }
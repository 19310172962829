import React, {useEffect, useRef, useState} from 'react';
import Button from '@mui/material/Button';

const Test = () => {
    const [message, setMessage] = useState('');

    const flag = useRef(false)


    useEffect(() => {

        if(flag.current === false) {


            return () => flag.current = true
        }

    }, []);



    return (
        <div>
            <Button variant={'outlined'} onClick={() => {alert('ici')}}>SOCKET</Button>
        </div>
    );
};

export default Test;

import React from 'react';
// Styles
import './InteriorPreview.css';


export default function InteriorPreview (props) {
  return (
      <div className="interior-preview">
          <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
          >
              <defs />
              <image
                  alt=''
                  title={props.interior?.label}
                  width="100%"
                  height="100%"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  xlinkHref={`${process.env.REACT_APP_PUBLIC_URL}/media/cars/interiors/${props.interior?.value}.jpeg`}
                  preserveAspectRatio="xMidYMid slice"
              />
          </svg>
      </div>
  );
};


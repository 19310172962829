import React from 'react';
import { Link } from "react-scroll";
import './SecNavbar.css';


const SecNavLinks = (props) => {
  
    return (
    <ul className={props.linksClass}>
            <li>
            <Link
              onClick={() => {props.isMobile && props.closeHamburgerMenu()}} 
              activeClass="active"
              to="AClassSaloon"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
                Classe A Berline
            </Link>
            </li>
            <li>
            <Link
              onClick={() => {props.isMobile && props.closeHamburgerMenu()}} 
              activeClass="active"
              to="Exterior."
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
                Extérieur
            </Link>
            </li>
            <li>
            <Link
              onClick={() => {props.isMobile && props.closeHamburgerMenu()}}
              activeClass="active"
              to="Interior."
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
                Intérieur
            </Link>
            </li>
            <li>
            <Link
              onClick={() => {props.isMobile && props.closeHamburgerMenu()}} 
              activeClass="active"
              to="Technology."
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
                Technologie
            </Link>
            </li>
            <li>
            <Link
              onClick={() => {props.isMobile && props.closeHamburgerMenu()}} 
              activeClass="active"
              to="Safety."
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
                Sécurité
            </Link>
            </li>
            <li >
            <Link
              onClick={() => {props.isMobile && props.closeHamburgerMenu()}} 
              activeClass="active"
              to="MercedesBenzInYourCountry"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
                Infinity-Life dans votre pays
            </Link>
            </li>
      </ul>
  )
}

export default SecNavLinks;
import React, {useEffect, useRef, useState} from 'react';
import Wrapper from "../../components/global/Wrapper";
import fondLarge from '@/assets/images/fondLarge.png'
import {
    Container,
    Divider,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    List,
    SwipeableDrawer,
    useMediaQuery
} from "@mui/material";
import Button from "@mui/material/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import {partAccessoryService} from "../../_services/partAccessory.service";
import Typography from "@mui/material/Typography";
import Modal from '@mui/material/Modal';
import {useTheme} from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import {accountService} from "../../_services/account.service";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import {cartService} from "../../_services/cart.service";
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import Chip from '@mui/material/Chip';
import CartItem from "@/components/admin/CartItem";
import Loading from '@/_utils/Loading';

const Accessoires = () => {

    const [isCartLoading, setIsCartLoading] = useState(false);
    const [isPartALoading, setIsPartALoading] = useState(false);
    const [datas, setDatas] = useState([]);
    const [cartDatas, setCartDatas] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [modalData, setModalData] = useState(null);
    const [state, setState] = React.useState({
        right: false,
    });
    const [quantity, setQuantity] = React.useState(null);
    const [total, setTotal] = useState(0);

    const flag = useRef(false)
    let navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const drawerWidth = isMobile ? '100%' : '35%';
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if(flag.current === false)
        {
            setIsPartALoading(true);

            partAccessoryService.getPartAccessories("Accessoires")
                .then((data) => {
                    setDatas(data.data);
                })
                .catch((err) =>{
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    navigate('/')
                })
                .finally(() => {
                    setIsPartALoading(false);
                })
        }

        return () => flag.current = true
    }, []);

    useEffect(() => {
        const newTotal = calculateTotal(cartDatas);
        setTotal(newTotal);
    }, [cartDatas]);

    const calculateTotal = (items) => {
        let newTotal = 0;
        items.forEach(item => {
            newTotal += item.partAccessory.price * item.quantity;
        });
        return newTotal;
    };

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const getMyCart = () => {
        setIsCartLoading(true);
        cartService.getMyCart()
            .then((data) => {
                setCartDatas(data.data)
            })
            .catch((err) =>{
                enqueueSnackbar("Une erreur s'est produite !", {
                    variant: 'error', // or variant: warning | error | info
                    autoHideDuration: 5000,
                    anchorOrigin : {horizontal: "right", vertical: "top"}
                })
                navigate('/')
            })
            .finally(() => {
                setIsCartLoading(false);
            })
    }

    const handleQuantityChange = (itemId, newQuantity) => {

        const updatedItems = cartDatas.map(item => {
            if (item.id === itemId) {
                return { ...item, quantity: newQuantity };
            }
            return item;
        });

        setCartDatas(updatedItems);
        const newTotal = calculateTotal(updatedItems);
        setTotal(newTotal);
    }

    const handleRemoveItem = (itemId) => {
        const newItems = cartDatas.filter(item => item.id !== itemId);
        setCartDatas(newItems);
        const newTotal = calculateTotal(newItems);
        setTotal(newTotal);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        color:'black'
    };

    const list = (anchor) => (
        <Box
            role="presentation"
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Typography variant="h5" gutterBottom sx={{textAlign:'center',margin:2}}>
                Votre Panier 🛒
            </Typography>
            <Divider />
            {
                cartDatas.length > 0 ? (
                    <>
                        <List>

                            {cartDatas.map((cartDetail) => (
                                <>
                                    <CartItem
                                        key={cartDetail.id}
                                        id={cartDetail.id}
                                        name={cartDetail.partAccessory.name}
                                        price={cartDetail.partAccessory.price}
                                        quantity={cartDetail.quantity}
                                        image={cartDetail.partAccessory.images[0].contentUrl}
                                        onQuantityChange={(newQuantity) => handleQuantityChange(cartDetail.id, newQuantity)}
                                        onRemoveItem={handleRemoveItem}
                                    />


                                    <Divider sx={{paddingBottom:5}}>
                                        <Chip label="en stock" color={'success'} />
                                    </Divider>
                                </>
                            ))}
                        </List>

                        <div style={{ position: 'fixed', bottom: 0, width:'-webkit-fill-available' }}>
                            <Button
                                sx={{width:'100%', borderRadius:'initial'}}
                                variant="contained"
                                endIcon={<ShoppingCartCheckoutIcon />}
                                onClick={() => navigate('/user/cart')}
                            >
                                Valider mon panier / Total : {total} €
                            </Button>
                        </div>
                    </>
                ) : (
                    <Box>
                        <section className="page_404">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12 ">
                                        <div className="col-sm-10 col-sm-offset-1" style={{textAlign:'center'}}>
                                            <div className="four_zero_four_bg">
                                            </div>

                                            <div className="contant_box_404">
                                                <h3 className="h2" style={{fontFamily:'MBCorpoATitle'}}>
                                                    Votre panier est vide
                                                </h3>
                                                <button onClick={() => {
                                                    window.location = '/accessoires'
                                                }} className="link_404" style={{fontFamily:'MBCorpoATitle'}}>Aller aux accessoires</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Box>
                )
            }
        </Box>
    );

    const addToCart = (itemId) => {

        if (!accountService.isLogged())
        {
            enqueueSnackbar("Connectez-vous pour accéder à votre panier 👋", {
                variant: 'warning', // or variant: warning | error | info
                autoHideDuration: 5000,
                anchorOrigin : {horizontal: "right", vertical: "top"}

            })

            navigate('/auth/login')
        }

        cartService.getMyCart()
            .then((data) => {
                setCartDatas(data.data)
                handleClose()
                setState({
                    right: true
                })

                cartService.postItemInCart(itemId)
                    .then((res) => {
                        getMyCart()

                        enqueueSnackbar("L'article a été ajouté dans votre panier ✨", {
                            variant: 'success', // or variant: warning | error | info
                            autoHideDuration: 5000,
                            anchorOrigin : {horizontal: "right", vertical: "top"}

                        })
                    })
                    .catch((err) => {
                        if (err.response.status)
                        {
                            enqueueSnackbar("Cet article est déjà présent dans votre panier", {
                                variant: 'warning', // or variant: warning | error | info
                                autoHideDuration: 5000,
                                anchorOrigin : {horizontal: "right", vertical: "top"}

                            })
                        }
                    })

            })
    };

    return (
        <>
            {isCartLoading || isPartALoading ? (<Loading />) : null}
            <Container maxWidth="xl">
                <Wrapper background={fondLarge}>
                    <div style={{background:'linear-gradient(to right,rgba(0,0,0,0.55) 0,rgba(0,0,0,0.55) 15%,rgba(0,0,0,0) 50%)'}}>
                        <div className='HeroPage' style={{height:'50vh'}}>
                            <div className='HeroPage__overlay'>
                                <div className='HeroPage__overlay__upperText' id='AClassSaloon' style={{width:'100%'}}>
                                    <h1 style={{fontSize:'3rem'}}>Accessoires d'origines</h1>
                                    <p>Des accessoires conçus pour votre Infinity-Life</p>
                                    <br/>
                                    {/*<Button variant="contained">Trouver un distributeur</Button>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </Wrapper>
            </Container>

            <a href={'#'} className='ImageHeadline'>
                <div className="ImageHeadline__container">
                    <div className='ImageHeadline__container__imagediv'>
                        <img src={'https://www.mercedes-benz.fr/passengercars/being-an-owner/accessories-configuator/accessories-presentation/_jcr_content/par/productinfotextimage/media2/slides/videoimageslide/image.MQ6.7.20201027100227.jpeg'} alt="" />
                    </div>
                    <div className='ImageHeadline__container__text'>
                        <h2 className='ImageHeadline__container__text__title'>
                            Des Accessoires conçus pour votre Infinity-Life.
                        </h2>
                        <p className='ImageHeadline__container__text__paragraph'>
                            Vous souhaitez avoir plus de place, plus de style et rouler en toute sérénité ?<br/>

                            Infinity-Life vous propose un large panel d'Accessoires pensés, développés et certifiés pour votre véhicule.
                        </p>
                    </div>
                    <div className='ImageHeadline__container__arrowdiv'>
                        <FontAwesomeIcon className='ImageHeadline__container__arrowdiv__arrow' icon={faAngleRight} />
                    </div>
                </div>
            </a>
            <Box
                sx={{
                    pt: 8,
                    pb: 6,
                    background:'white'
                }}
            >
                <Container maxWidth="xxl" sx={{margin:'3%', width:'auto'}}>
                    <Typography
                        component="h1"
                        variant="h4"
                        align="left"
                        color="text.primary"
                        gutterBottom
                        margin={5}
                        sx={{color:'black'}}
                    >
                        Vos Accessoires <b>Infinity-Life</b> :
                    </Typography>

                    <Container maxWidth="lg">
                        <Box>
                            <ImageList variant="masonry" cols={2} gap={20} sx={{columnCount: {xs:'inherit !important', lg:'3 !important'} }}>
                                {datas.map((item) => (
                                    <ImageListItem key={item.id}>
                                        <img
                                            src={process.env.REACT_APP_PUBLIC_URL+item.images[0].contentUrl}
                                            alt={item.images[0].contentUrl}
                                            loading="lazy"
                                        />
                                        <ImageListItemBar
                                            title={item.name}
                                            subtitle={item.description}
                                            actionIcon={
                                                <IconButton
                                                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                                    aria-label={`info about test`}
                                                    onClick={() => {
                                                        handleOpen();
                                                        setModalData(item);
                                                    }}
                                                >
                                                    <AddBoxIcon />
                                                </IconButton>
                                            }
                                        />
                                    </ImageListItem>
                                ))}
                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={style}>
                                        <Grid container spacing={2}>
                                            <Grid xs={12} md={8} lg={6}>
                                                <img
                                                    src={process.env.REACT_APP_PUBLIC_URL+modalData?.images[0].contentUrl}
                                                    alt={modalData?.images[0].contentUrl}
                                                    loading="lazy"
                                                    style={{width:'100%'}}
                                                />
                                            </Grid>
                                            <Grid xs={12} md={6} lg={6}>
                                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                                    {modalData?.name}
                                                </Typography>
                                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                                    {modalData?.price} €
                                                </Typography>
                                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                                    {modalData?.description}
                                                </Typography>
                                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                                    <Button
                                                        variant='contained'
                                                        onClick={() => addToCart(modalData?.id)}
                                                    >
                                                        Ajouter au panier
                                                    </Button>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Modal>
                            </ImageList>

                            <React.Fragment key={'right' }>
                                <SwipeableDrawer
                                    anchor={'right'}
                                    open={state['right']}
                                    onClose={toggleDrawer('right', false)}
                                    onOpen={toggleDrawer('right', true)}
                                    PaperProps={{
                                        sx: { width: drawerWidth }
                                }}
                                >
                                    {list('right')}
                                </SwipeableDrawer>
                            </React.Fragment>

                        </Box>
                    </Container>
                </Container>
            </Box>
        </>
    );
};
export default Accessoires;

import React from 'react';
import { motion } from "framer-motion";
import { useInView } from 'react-intersection-observer';

import './HeadlineAccount.css';
import Grid from "@mui/material/Grid";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";

const HeadlineAccount = () => {

  const [headlineRef, headlineInView] = useInView();

  return (
    <div className="Headline" id={'helpAccount'} >
        <div className="Headline__container">
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} md={6} lg={6}>
                    <motion.div
                      ref={headlineRef}
                      animate={{ y: headlineInView ? 0 : 40, opacity: headlineInView ? 1 : 0, animationDelay: 0.5}}
                      transition={{ duration: 0.5 }}
                    >
                      <h2  className='Headline__container__title'>Questions les plus fréquentes :</h2>
                        <Accordion sx={{background:'black', color:'white', borderColor:'white'}} >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{color:'white'}} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Comment suivre ma livraison ?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                    malesuada lacus ex, sit amet blandit leo lobortis eget.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion sx={{background:'black', color:'white', borderColor:'white'}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{color:'white'}} />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography>Demander un retour ou un remboursement</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                    malesuada lacus ex, sit amet blandit leo lobortis eget.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                    </motion.div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <motion.div
                        ref={headlineRef}
                        animate={{ y: headlineInView ? 0 : 40, opacity: headlineInView ? 1 : 0, animationDelay: 0.5}}
                        transition={{ duration: 0.5 }}
                        style={{textAlign:'center'}}
                    >
                        <img width={300} src="https://www.mercedes-benz.fr/content/france/fr/passengercars/my-area/my-dashboard/_jcr_content/root/responsivegrid/text_media_module/image.component.damq4.3264682894983.jpg/asset_meHome_teaser_img_V3.jpg" alt=""/>
                    </motion.div>
                </Grid>

            </Grid>
        </div>
    </div>
  )
}

export default HeadlineAccount
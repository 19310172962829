import React, {useLayoutEffect, useRef, useState} from 'react';
import {Route, Routes, useNavigate} from "react-router-dom";
import { ALayout} from '@/pages/User/RouterConfig';
import Error from "@/_utils/Error";
import Profile from "../Profile";
import ProfileAdresses from "../ProfileAdresses";
import ProfileSecurity from "../ProfileSecurity";
import ChangeEmail from "@/components/admin/ChangeEmail";
import ChangePassword from "@/components/admin/ChangePassword";
import Account from "@/pages/User/Account";
import Owning from "@/pages/User/Dashboard/Owning";
import Considering from "@/pages/User/Dashboard/Considering";
import AddPersonalCar from "@/pages/User/Dashboard/AddPersonalCar";
import EditPersonalCar from "@/pages/User/Dashboard/EditPersonalCar";
import DeletePersonalCar from "@/pages/User/Dashboard/DeletePersonalCar";
import Cart from "@/pages/User/Cart";
import OrderProcess from "../OrderProcess";
import NewAnnonceUsedCar from "../NewAnnonceUsedCar";
import UserAnnonce from "../UserAnnonce";
import UserMaintenance from "../UserMaintenance";
import RequestNewMaintenance from "../RequestNewMaintenance";
import {userService} from "../../../_services/user.service";
import {useSnackbar} from "notistack";
import {accountService} from "../../../_services/account.service";

const UserRouter = () => {
    const [isUserLoading, setIsUserLoading] = useState(false);
    const [userRole, setUserRole] = useState('');
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();
    const flag = useRef(false)

    useLayoutEffect(() => {
        if(flag.current === false) {
            const token = localStorage.getItem('token');
            if (token) {
                setIsUserLoading(true);
                userService.getMe()
                    .then((response) => {
                        setUserRole(response.data.roles)
                        console.log(response.data.roles[0])
                        if (response.data.roles[0] === 'ROLE_GARAGISTE') {
                            enqueueSnackbar("Vous allez être redirigé vers le panel garagiste", {
                                variant: 'success', // or variant: warning | error | info
                                autoHideDuration: 5000,
                                anchorOrigin: {horizontal: "right", vertical: "top"}
                            })
                            navigate('/garagiste')
                        }else if (response.data.roles[0] === 'ROLE_ADMIN'){
                            accountService.logout()
                        }
                    })
                    .catch((err) => {
                        enqueueSnackbar("Une erreur s'est produite !", {
                            variant: 'error', // or variant: warning | error | info
                            autoHideDuration: 5000,
                            anchorOrigin: {horizontal: "right", vertical: "top"}
                        })
                        navigate('/')
                    })
                    .finally(() => {
                        setIsUserLoading(false);
                    })
            }
        }
        return () => flag.current = true

    }, []);

    return (
        <Routes>
            <Route element={<ALayout/>}>
                <Route index element={<Profile />}/>
                <Route path="mes-annonces" element={<UserAnnonce/>}/>
                <Route path="mes-entretiens" element={<UserMaintenance/>}/>
                <Route path="requestNewMaintenance" element={<RequestNewMaintenance />}/>
                <Route path="profile">
                    <Route index element={<Profile />}/>
                    <Route path='adresses' element={<ProfileAdresses />}/>
                    <Route path='security' element={<ProfileSecurity />}/>
                    <Route path='changeEmail' element={<ChangeEmail />}/>
                    <Route path='changePassword' element={<ChangePassword />}/>
                </Route>
                <Route path="dashboard">
                    <Route index element={<Owning />}/>
                    <Route path='owning' element={<Owning />}/>
                    <Route path='considering' element={<Considering />}/>
                    <Route path='addPersonalCar' element={<AddPersonalCar />}/>
                    <Route path='editPersonalCar' element={<EditPersonalCar />}/>
                    <Route path='deletePersonalCar/:uid' element={<DeletePersonalCar />}/>
                </Route>
                <Route path="occasion">
                    <Route path='add' element={<NewAnnonceUsedCar />}/>
                </Route>
                <Route path="account">
                    <Route index element={<Account />}/>
                </Route>
                <Route path="cart">
                    <Route index element={<Cart />}/>
                    <Route path='cart' element={<Cart />}/>
                </Route>
                <Route path="order">
                    <Route path='process' element={<OrderProcess />}/>
                </Route>
                <Route path="*" element={<Error/>}/>
            </Route>
        </Routes>
    );
};

export default UserRouter;

import Axios from "./caller.service";

let getUsedCars = () => {
    return Axios.get('api/used_cars?order[id]=desc&statut=Stock')
}

let getUsedCarDetail = (id) => {
    return Axios.get('api/used_cars/'+id)
}

let getMyAnnounces = () => {
    return Axios.get('api/my/announces')
}

let reserveCar = (carId, userId) => {

    const formValue = {
        reservedBy: userId
    }

    return Axios.put('api/used_cars/'+carId, formValue)
}

let postNewAnnonceUsedCar = (formData) => {

    return Axios.post('api/used_cars', formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            'Accept': 'multipart/form-data'
        }
    })

}

let deleteAnnounce = (id) => {
    return Axios.delete('api/used_cars/'+id)
}

let updateUsedCarStatus = (idUsedCar, formValue) => {

    return Axios.put('api/used_cars/'+idUsedCar, formValue)
}

export const usedCarService = {
    getUsedCars, getUsedCarDetail, reserveCar, postNewAnnonceUsedCar, getMyAnnounces, deleteAnnounce, updateUsedCarStatus
}
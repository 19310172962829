import React, {useEffect, useRef, useState} from 'react';
import {useSnackbar} from "notistack";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {Card, CardContent, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CheckIcon from '@mui/icons-material/Check';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {HelpOutline} from "@mui/icons-material";
import InfoIcon from '@mui/icons-material/Info';
import {useLocation, useNavigate} from "react-router-dom";
import {accountService} from "@/_services/account.service";
import Loading from '@/_utils/Loading';

const USER_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const Register = () => {
    //TODO: register with first name and lastname
    const [email, setEmail] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [plainPassword, setPlainPassword] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [isUserLoading, setIsUserLoading] = useState(false);

    const theme = createTheme();

    const userRef = useRef();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/auth/register') {
            document.body.classList.add('LoginbodyCover');
        }
        return () => {
            if (location.pathname === '/auth/register') {
                document.body.classList.remove('LoginbodyCover');
            }
        }
    }, [location.pathname])

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setValidName(USER_REGEX.test(email));
    }, [email])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(plainPassword));
        setValidMatch(plainPassword === matchPwd);
    }, [plainPassword, matchPwd])

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsUserLoading(true);

        // if button enabled with JS hack
        const v1 = USER_REGEX.test(email);
        const v2 = PWD_REGEX.test(plainPassword);
        if (!v1 || !v2) {
            enqueueSnackbar("Les mot de passe ne corresspondent pas !", {
                variant: 'warning', // or variant: warning | error | info
                autoHideDuration: 5000,
                anchorOrigin : {horizontal: "right", vertical: "top"}
            })
            return;
        }

        accountService.register(email, plainPassword)
            .then(res => {

                enqueueSnackbar("votre inscription a bien été prise en compte !", {
                    variant: 'success', // or variant: warning | error | info
                    autoHideDuration: 5000,
                    anchorOrigin : {horizontal: "right", vertical: "top"}
                })

                enqueueSnackbar("Vous allez être redirigé vers la page connexion", {
                    variant: 'success', // or variant: warning | error | info
                    autoHideDuration: 5000,
                    anchorOrigin : {horizontal: "right", vertical: "top"}
                })

                setEmail('');
                setPlainPassword('');
                setMatchPwd('');

                navigate('/auth/login')
            })
            .catch(err => {
                if (err.response?.status === 403 ) {
                    enqueueSnackbar("Cette adresse mail est déjà utilisé !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 10000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                } else {
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                }
            })
            .finally(() => {
                setIsUserLoading(false)
            });
    }


    return (
        <ThemeProvider theme={theme}>
            {isUserLoading ? <Loading /> : null}

            <Container component="main" maxWidth="md">
                <Box
                    sx={{
                        marginTop: 15,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor:'white'
                    }}
                >
                    <Box sx={{ width: 1 }}>
                        <Card sx={{ backgroundColor:'black', borderRadius:'0px'}} >
                            <CardContent sx={{padding:'0px !important', margin:1 }}>
                                <Typography sx={{ fontSize: 15, color:'white',margin:0,textAlign:'center'  }} gutterBottom>
                                    S'enregistrer
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                    <Box sx={{ width: 1 }}>
                        <Card sx={{  borderRadius:'0px', boxShadow:'none'}} >
                            <CardContent sx={{padding:'0px !important', margin:1 }}>
                                <Typography sx={{ fontSize: 30,margin:0,textAlign:'center'  }} gutterBottom>
                                    Infinity-Life me ID
                                    <Tooltip title="L'infinity-Life me ID vous permet de disposer d'un accès central à nos services ainsi que d'un profil intégré dans lequel vous pouvez modifier vos données.">
                                        <HelpOutline color="secondary" fontSize="small" />
                                    </Tooltip>

                                </Typography>
                                <Typography sx={{ fontSize: 15, margin:0,textAlign:'center'  }} gutterBottom>
                                    Veuillez saisir votre adresse e-mail ou votre numéro de téléphone portable
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                    <Box component="form" className={'form'} sx={{ m: 2}} onSubmit={handleSubmit}>
                        <Box>
                            <CheckIcon sx={validName ? {display:"block"}:{display:"none"} } className={validName && "valid"} />
                            <AccessTimeIcon sx={validName || !email ?{display:"none"}:{display:"block"} } className={validName || !email ? "" : "invalid"} />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                type="email"
                                label="Adresse email"
                                name="username"
                                autoComplete="off"
                                ref={userRef}
                                onChange={(e) => setEmail(e.target.value)}
                                onFocus={() => setUserFocus(true)}
                                onBlur={() => setUserFocus(false)}
                                aria-invalid={validName ? 'false' : 'true'}
                                aria-describedby="uidnote"
                            />

                            <Typography variant={'subtitle1'} id="uidnote" className={userFocus && email && !validName ? "instructions" : "offscreen"}>
                                <InfoIcon />
                                L'adresse mail n'est pas au bon format
                            </Typography>


                        </Box>

                        <Box>
                            <CheckIcon sx={validPwd ? {display:"block"}:{display:"none"} } className={validPwd && "valid"} />
                            <AccessTimeIcon sx={validPwd || !plainPassword ? {display:"none"}:{display:"block"} } className={validPwd || !plainPassword ? "" : "invalid"} />

                            <TextField
                                margin="normal"
                                fullWidth
                                type="password"
                                id="password"
                                label="Mot de passe"
                                onChange={(e) => setPlainPassword(e.target.value)}
                                value={plainPassword}
                                required
                                aria-invalid={validPwd ? "false" : "true"}
                                aria-describedby="pwdnote"
                                onFocus={() => setPwdFocus(true)}
                                onBlur={() => setPwdFocus(false)}
                            />
                            <Typography variant={'subtitle1'} id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                                <InfoIcon />
                                8 à 24 caractères. <br/>
                                Doit inclure une minuscule, une majuscule <br/>
                                un nombre et un caractère spécial.
                            </Typography>
                        </Box>

                        <Box>
                            <CheckIcon sx={validMatch && matchPwd ? {display:"block"}:{display:"none"} }  className={validMatch && matchPwd ? "valid" : ""} />
                            <AccessTimeIcon sx={validMatch || !matchPwd ? {display:"none"}:{display:"block"} } className={validMatch || !matchPwd ? "" : "invalid"} />

                            <TextField
                                margin="normal"
                                fullWidth
                                label="Confirmer le Mot de passe"
                                name="password"
                                autoComplete="off"
                                type="password"
                                id="confirm_pwd"
                                onChange={(e) => setMatchPwd(e.target.value)}
                                value={matchPwd}
                                required
                                aria-invalid={validMatch ? "false" : "true"}
                                aria-describedby="confirmnote"
                                onFocus={() => setMatchFocus(true)}
                                onBlur={() => setMatchFocus(false)}
                            />

                            <Typography variant={'subtitle1'} id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                                <InfoIcon />
                                Les mot de passe doivent correspondent
                            </Typography>
                        </Box>

                        <Box >
                            <Button
                                disabled={!validName || !validPwd || !validMatch}
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                S'inscrire
                            </Button>
                            <Button
                                fullWidth
                                variant="contained" onClick={() => {navigate('/')}}
                                sx={{ mb: 2 }}
                            >
                                Accueil
                            </Button>
                            <Grid sx={{justifyContent:'end'}} container>
                                <Grid >
                                    <Typography sx={{ fontSize: 15, margin:0, color:'black'}} >
                                        Vous avez déjà un compte Infinity-Life me ID ?
                                        <Button variant="text" onClick={() => {navigate('/auth/login')}} style={{fontSize:12}}>
                                            Se connecter
                                        </Button>
                                    </Typography>

                                </Grid>
                            </Grid>
                        </Box>

                    </Box>
                    <Box sx={{ width: 1 }}>
                        <Card sx={{ backgroundColor:'black', borderRadius:'0px'}} >
                            <CardContent sx={{padding:'0px !important', margin:1, textAlign:'end' }}>
                                <Button variant="text" style={{fontSize:12, color:'white',margin:0}}>
                                    Mentions légales
                                </Button>
                            </CardContent>
                        </Card>
                    </Box>
                </Box>

            </Container>
        </ThemeProvider>
    );
};

export default Register;
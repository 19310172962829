import React from 'react';
import Lottie from "lottie-react";
import ErrorAnim from "@/images/json-animation/404-animation.json";
import Box from "@mui/material/Box";

const Error = () => {
    return (
        <Box sx={{width:'100%', textAlign:'-webkit-center'}}>
            <Lottie style={{width:'50%'}} animationData={ErrorAnim} />
        </Box>
    );
};

export default Error;

import React, {useEffect, useRef, useState} from 'react';
import Toolbar from "@mui/material/Toolbar";
import {Person} from "@mui/icons-material";
import {Alert, CardActions, Container, Grid, Paper, Skeleton, Typography} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import AddBoxIcon from "@mui/icons-material/AddBox";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import {maintenanceService} from "../../_services/maintenance.service";
import Chip from '@mui/material/Chip';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from '@mui/icons-material/Warning';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Modal } from '@mui/material';
import Box from '@mui/material/Box';
import {Html5Qrcode} from "html5-qrcode";
import Divider from '@mui/material/Divider';
import IconButton from "@mui/material/IconButton";

const UserAnnonce = () => {

    const [isMaintenanceLoading, setIsMaintenanceLoading] = useState(true);
    const [maintenances, setMaintenances] = useState([]);
    const [maintenance, setMaintenance] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [scanResult, setScanResult] = useState(null);
    const [confirmed, setConfirmed] = useState(false);

    const flag = useRef(false)
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        background: 'white !important',
        border: '2px solid #000',
        boxShadow: 24,
        color:'black',
        p: 4,
    };

    useEffect(() => {
        if(flag.current === false) {

            setIsMaintenanceLoading(true);

            maintenanceService.getMyMaintenances()
                .then((data) => {
                    setMaintenances(data.data);
                })
                .catch((err) =>{
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    navigate('/')
                })
                .finally(() => {

                    setIsMaintenanceLoading(false);
                })

        }
        return () => flag.current = true
    }, []);



    const handelConfirmRdv = (e, maintenance) => {
      e.preventDefault()
        // Ouvrir la modal de confirmation
        setIsModalOpen(true);
        setScanResult(null);
        setMaintenance(maintenance)

        // This method will trigger user permissions
        Html5Qrcode.getCameras({ facingMode: { exact: "environment" } })
            .then(devices => {
            /**
             * devices would be an array of objects of type:
             * { id: "id", label: "label" }
             */
            if (devices && devices.length) {
                var cameraId = devices[0].id;
                // .. use this to start scanning.
                const html5QrCode = new Html5Qrcode("reader");
                html5QrCode.start(
                    { facingMode: { exact: "environment"} },
                    {
                        fps: 10,    // Optional, frame per seconds for qr code scanning
                        qrbox: { width: 250, height: 250 },  // Optional, if you want bounded box UI
                    },
                    (decodedText, decodedResult) => {
                        // do something when code is read

                        html5QrCode.stop()
                            .then((ignore) =>{
                                maintenanceService.checkToken(decodedText)
                                    .then((res) => {

                                        if(res.data.length > 0)
                                        {
                                            maintenanceService.updateStatusMaintenance(maintenance.id,{status:'scanned'})
                                                .then((res) =>{
                                                    setScanResult('success')
                                                    setConfirmed(true)
                                                })
                                                .catch((err) =>{
                                                    console.log(err)
                                                    enqueueSnackbar("Une erreur s'est produite !", {
                                                        variant: 'error', // or variant: warning | error | info
                                                        autoHideDuration: 5000,
                                                        anchorOrigin : {horizontal: "right", vertical: "top"}
                                                    })
                                                })
                                        }else{
                                            setScanResult('error')
                                        }

                                    })
                                    .catch((err) =>{
                                        console.log(err)
                                        enqueueSnackbar("Une erreur s'est produite !", {
                                            variant: 'error', // or variant: warning | error | info
                                            autoHideDuration: 5000,
                                            anchorOrigin : {horizontal: "right", vertical: "top"}
                                        })
                                    })
                                    .finally(() => {
                                        setIsMaintenanceLoading(false);
                                    })
                            })

                    },
                    (errorMessage) => {
                        console.log(errorMessage)
                        // parse error, ignore it.
                    })
                    .catch((err) => {
                        console.log(err)
                        // Start failed, handle it.
                    });
            }
            }).catch(err => {
             console.log(err)
                // handle err
            });

    }

    const handleClose = () => {
        setIsModalOpen(false);
    };


    const handleError = (error) => {
        console.error('Error while scanning QR code:', error);
        enqueueSnackbar("Erreur lors du scann !", {
            variant: 'error', // or variant: warning | error | info
            autoHideDuration: 5000,
            anchorOrigin : {horizontal: "right", vertical: "top"}
        })
    };

    return (
        <>

            <AppBar position="relative" sx={{background:'white', color:'black', boxShadow:'none', borderBottom:'1px solid #e3e3e3'}}>
                <Toolbar>
                    <Person sx={{ mr: 2 }} />
                    <Typography variant="div" color="inherit" noWrap>
                        Mes entretiens
                    </Typography>
                    <Divider orientation="vertical" flexItem sx={{margin:2}} />
                    <Typography variant="div" color="inherit" noWrap>
                        <IconButton aria-label="delete" size="large" sx={{padding:'5px',borderRadius:'inherit'}}>
                            <AddBoxIcon onClick={() => {navigate('/user/requestNewMaintenance')} } fontSize="inherit"/>
                        </IconButton>
                    </Typography>
                </Toolbar>
            </AppBar>
            {isMaintenanceLoading ? (
                <Container maxWidth='lg' sx={{paddingTop:'20px', paddingBottom:'20px', background:'transparent'}}>
                    {/* For other variants, adjust the size with `width` and `height` */}
                    <Skeleton variant="rounded" height={200} sx={{ m: 2 }} />
                    <Skeleton variant="rounded" height={200} sx={{ m: 2 }} />
                    <Skeleton variant="rounded" height={200} sx={{ m: 2 }} />
                </Container>
            ) : (
                <Container maxWidth='lg' sx={{paddingTop:'20px', paddingBottom:'20px'}}>

                    {maintenances.length > 0 ? (

                        maintenances.map((maintenance) => {

                            const [day, month, year] = maintenance.date.split('/');
                            const newDate = new Date(`${month}/${day}/${year}`);

                            console.log(newDate.toLocaleDateString('fr'))
                            console.log(new Date().toLocaleDateString('fr'))

                            return (
                                    <Card sx={{m: 3}}>
                                        <CardContent>
                                            <Typography variant="h5" component="div" sx={{fontFamily: 'MBCorpoATitle'}}>
                                                {maintenance.title} | {maintenance.date}
                                            </Typography>

                                            {maintenance.done
                                                ? (<Typography variant="span" component="div" sx={{mb: 2, mt: 2}}> <Chip
                                                    sx={{background: 'green', color: 'white'}}
                                                    icon={<CheckCircleIcon sx={{color: '#fff !important'}}/>}
                                                    label="Maitenance effectué"/></Typography>)
                                                : newDate.toLocaleDateString('fr') < new Date().toLocaleDateString('fr')
                                                    ? (<Typography variant="span" component="div" sx={{mb: 2, mt: 2}}> <Chip
                                                        sx={{background: 'red', color: 'white'}}
                                                        icon={<WarningIcon sx={{color: '#fff !important'}}/>}
                                                        label="Maitenance non respecté"/></Typography>)
                                                    : (<Typography variant="span" component="div" sx={{mb: 2, mt: 2}}> <Chip
                                                        sx={{background: 'orange', color: 'white'}}
                                                        icon={<WarningIcon sx={{color: '#fff !important'}}/>}
                                                        label="Maitenance pas encore effectué"/></Typography>)
                                            }

                                            {maintenance.dataset && (
                                                <>
                                                    <TableContainer component={Paper}>
                                                        <Table aria-label="simple table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell colSpan={2}>
                                                                        Détail des pièces changées :
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {maintenance.dataset.map((item) => {
                                                                    const key = Object.keys(item)[0];
                                                                    const value = Object.values(item)[0];

                                                                    return (
                                                                        <TableRow
                                                                            key={key}
                                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                                        >
                                                                            <TableCell component="th" scope="row">
                                                                                {key}
                                                                            </TableCell>
                                                                            <TableCell align="right">{value} €</TableCell>
                                                                        </TableRow>
                                                                    )
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </>
                                            )}

                                            {maintenance.date === new Date().toLocaleDateString("fr") && (
                                                <Typography sx={{mb: 1.5, mt: 1.5, fontFamily: 'MBCorpoATitle'}}
                                                            color="text.secondary">

                                                    {
                                                        maintenance.status === 'pending' && !confirmed ? (

                                                            <Button variant="contained" onClick={(e) => {
                                                                handelConfirmRdv(e, maintenance);
                                                            }}>Confirmer le rendez-vous</Button>

                                                        ) : (

                                                            <Button variant="contained" disabled>Le rendez-vous est déjà
                                                                confirmé</Button>

                                                        )
                                                    }


                                                </Typography>
                                            )}
                                        </CardContent>
                                        <CardActions>
                                            {/*// TODO : Modifier une annonce*/}
                                            {/*<Button size="small" sx={{fontFamily:'MBCorpoATitle' }}>Modifier</Button>*/}
                                            {/*<Button onClick={() => handleDelete(car.id)} size="small" sx={{fontFamily:'MBCorpoATitle' }} color={'error'}>Supprimer</Button>*/}
                                        </CardActions>
                                    </Card>)
                            }
                        )
                    ) : (
                        <Grid item key={'none'} xs={12} sm={12} md={12} lg={12}>

                            <Card>
                                <CardContent>
                                    <Typography variant="h5" component="div" sx={{fontFamily:'MBCorpoATitle' }}>
                                        Vous n’avez pas d'entretiens de programmé ou d'historique
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button onClick={() => {navigate('/user/requestNewMaintenance')} } variant="outlined" startIcon={<AddBoxIcon />} sx={{fontFamily:'MBCorpoATitle' }}>
                                        Demander un entretien
                                    </Button>
                                </CardActions>
                            </Card>

                        </Grid>

                    )}


                    <Modal
                        open={isModalOpen}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Confirmation
                            </Typography>
                            <Typography id="modal-modal-title" variant="subtitle1" component="div">
                                Voulez-vous confirmer votre rendez-vous ? <br/>
                                <small>Pour confirmer vous devez être au garage</small>
                                {
                                    scanResult
                                    ? scanResult === 'success' ? (
                                            <Alert severity="success">Le rendez-vous est confirmé !</Alert>
                                        ) : scanResult === 'error' ? (
                                            <>
                                                <Alert severity="error">Le rendez-vous ne correspond pas à ce véhicule</Alert>
                                                <Button sx={{mt:1}} fullWidth variant={'outlined'} onClick={(e) => {handelConfirmRdv(e, maintenance)}}>Recommencer</Button>
                                            </>
                                        ) : (
                                            <>
                                                <Alert severity="error">Une erreur s'est produite</Alert>
                                                <Button sx={{mt:1}} fullWidth variant={'outlined'} onClick={(e) => {handelConfirmRdv(e, maintenance)}}>Recommencer</Button>
                                            </>
                                        )
                                    : <div id={'reader'}></div>
                                }
                            </Typography>
                        </Box>
                    </Modal>

                </Container>
            )}
        </>
    )
};

export default UserAnnonce;

// import logo from '@/logo.svg';
import {useNavigate, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import React, {useEffect, useRef, useState} from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import myImage from '@/images/EQE_STAGE.png';
import {carService} from "../../../_services/car.service";
import {useSnackbar} from "notistack";
import Loading from '@/_utils/Loading';
import Wrapper from "../../../components/global/Wrapper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import { Grid} from '@mui/material';

function CarDetailLayout() {
    const { model } = useParams(); // Récupération de l'ID dans l'URL avec useParams de react-router-dom

    const [car, setCar] = useState([]);
    const [isUserLoading, setIsUserLoading] = useState(true);

    const flag = useRef(false)
    let navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    function getRandomElement(arr) {
        const randomIndex = Math.floor(Math.random() * arr.length);
        return arr[randomIndex];
    }

    useEffect(() => {
        if(flag.current === false)
        {
            setIsUserLoading(true);
            carService.getCarsByCarKey(model)
                .then((data) => {
                    if (data.data.length === 0){
                        enqueueSnackbar("Le véhicule que vous souhaitez n'existe pas !", {
                            variant: 'warning', // or variant: warning | error | info
                            autoHideDuration: 5000,
                            anchorOrigin : {horizontal: "right", vertical: "top"}
                        })
                        navigate('/')
                    }else{
                        console.log(data.data[0])
                        setCar(data.data[0]);
                    }
                })
                .catch((error) => {
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                })
                .finally(() => {
                    setIsUserLoading(false)
                });
        }

        return () => flag.current = true
    }, [model]);

    return (
        <Box sx={{background:'white'}}>
            {isUserLoading ? <Loading /> : (
                <Box>
                    <Box sx={{
                        backgroundImage: `linear-gradient(0deg,#fff,rgba(0,0,0,.001) 50%),linear-gradient(90deg,#fff,rgba(0,0,0,.001) 50%), url(${myImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}>

                        <Box className='HeroPage'>
                            <Box className='HeroPage__overlay'>
                                <Box className='HeroPage__overlay__upperText' id='AClassSaloon' sx={{width:'100%'}}>
                                    <Typography variant="h1" gutterBottom className='HeroPage__overlay__upperText__h1' style={{color:"black"}}>Nouvelle {car.name}</Typography>
                                    <Typography variant="subtitle1" gutterBottom className='HeroPage__overlay__upperText__p' style={{color:"black"}}>à partir de <b>21 000 €</b></Typography>

                                    <Box>
                                        <Button variant="outlined" onClick={() => {navigate('/configurator?model='+car.carKey)}} sx={{mr:2, mb: {xs:2, lg:0}, color:'black', borderColor:'black'}}>Configurez votre {car.name}</Button>
                                        <Button variant="contained" onClick={() => {navigate('/configurator')}}>Trouver un véhicule neuf en stock</Button>
                                    </Box>

                                </Box>
                            </Box>
                        </Box>

                    </Box>

                    <Grid container spacing={2} sx={{textAlign:'center',p:5}}>
                        <Grid item xs={12} sm={6} md={3}>
                            <Typography variant="body2" sx={{ color: 'black',fontFamily:'MBCorpoATitle' }}>À partir de</Typography>
                            <Typography variant="h4" sx={{ color: 'black',fontFamily:'MBCorpoATitle' }}>18 g/km</Typography>
                            <Typography variant="body2" sx={{ color: 'black',fontFamily:'MBCorpoATitle' }}>Émissions de CO2 en cycle mixte WLTP[1]</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Typography variant="body2" sx={{ color: 'black',fontFamily:'MBCorpoATitle' }}>de</Typography>
                            <Typography variant="h4" sx={{ color: 'black',fontFamily:'MBCorpoATitle' }}>230 à 320 Nm</Typography>
                            <Typography variant="body2" sx={{ color: 'black',fontFamily:'MBCorpoATitle' }}>Couple maximal[4]</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Typography variant="body2" sx={{ color: 'black',fontFamily:'MBCorpoATitle' }}>Disponible en motorisation</Typography>
                            <Typography variant="h4" sx={{ color: 'black',fontFamily:'MBCorpoATitle' }}>Disponible en motorisation</Typography>
                            <Typography variant="body2" sx={{ color: 'black',fontFamily:'MBCorpoATitle' }}>Hybride Rechargeable et Thermique</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Typography variant="body2" sx={{ color: 'black',fontFamily:'MBCorpoATitle' }}>Jusqu’à</Typography>
                            <Typography variant="h4" sx={{ color: 'black',fontFamily:'MBCorpoATitle' }}>Jusqu'à 420 Litres</Typography>
                            <Typography variant="body2" sx={{ color: 'black',fontFamily:'MBCorpoATitle' }}>Volume de chargement</Typography>
                        </Grid>
                    </Grid>

                    <a href={'#'} className='ImageHeadline'>
                        <div className="ImageHeadline__container">
                            <div className='ImageHeadline__container__imagediv'>
                                <img src={'https://www.mercedes-benz.fr/passengercars/being-an-owner/accessories-configuator/accessories-presentation/_jcr_content/par/productinfotextimage/media2/slides/videoimageslide/image.MQ6.7.20201027100227.jpeg'} alt="" />
                            </div>
                            <div className='ImageHeadline__container__text'>
                                <h2 className='ImageHeadline__container__text__title'>
                                    L’extérieur de la Nouvelle {car.name}.
                                </h2>
                                <p className='ImageHeadline__container__text__paragraph'>
                                    De la nouvelle face avant progressive avec son habillage de calandre marquant à l'arrière élégant de la berline en passant par les projecteurs à LED Multibeam : la Nouvelle {car.name} peut être vue partout.
                                </p>
                            </div>
                            <div className='ImageHeadline__container__arrowdiv'>
                                <FontAwesomeIcon className='ImageHeadline__container__arrowdiv__arrow' icon={faAngleRight} />
                            </div>
                        </div>
                    </a>


                    <Box>
                        <Wrapper background={`${process.env.REACT_APP_PUBLIC_URL}/media/cars/models/model_${car.carKey}/model_${car.carKey}_${getRandomElement(car.carColors).value}_${getRandomElement(car.carWheels).value}.png`}>
                            <div style={{background:'linear-gradient(to right,rgba(0,0,0,0.55) 0,rgba(0,0,0,0.55) 15%,rgba(0,0,0,0) 50%)'}}>
                                <div className='HeroPage' style={{height:'50vh'}}>
                                    <div className='HeroPage__overlay'>
                                        <div className='HeroPage__overlay__upperText' id='AClassSaloon' style={{width:'100%'}}>
                                            <h1 style={{fontSize:'3rem'}}>{car.name}</h1>
                                            <blockquote><i style={{color:'white'}}>Le futur de l'automobile s'ouvre à vous</i></blockquote>
                                            <br/>
                                            <Button variant="contained" onClick={() => {navigate('/configurator?model='+car.carKey)}}>Configurer votre véhicule</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Wrapper>
                    </Box>
                </Box>
            )}
        </Box>
  );
}

export default CarDetailLayout;

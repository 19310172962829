import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
    Container,
    FormControl,
    InputLabel,
    Modal,
    Select, Stack
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {useNavigate} from "react-router-dom";
import {userService} from "@/_services/user.service";
import {styled} from '@mui/material/styles';
import Paper from "@mui/material/Paper";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import fond from '@/images/fond.png';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import TextField from '@mui/material/TextField';
import MenuItem from "@mui/material/MenuItem";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import {useSnackbar} from "notistack";
import {personalCarService} from "../../_services/personalCar.service";
import {carModelService} from "@/_services/carModel.service";

const AddPersonalContent = () => {

    const [user, setUser] = useState([]);
    const [models, setModels] = useState([]);
    const [open, setOpen] = useState(false);
    const [brand, setBrand] = useState("Infinity-Life");
    const [title, setTitle] = useState("");
    const [model, setModel] = useState("");
    const [mileage, setMileage] = useState(0);
    const [description, setDescription] = useState("");
    const [gearBox, setGearBox] = useState('');
    const [manufactureDate, setManufactureDate] = useState(dayjs(Date.now()));

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const flag = useRef(false)
    let navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if(flag.current === false) {
            //TODO : loading

            userService.getMe()
                .then((data) => {
                    setUser(data.data);
                })

            carModelService.getAllCarModels()
                .then((data) => {
                    setModels(data);
                })
        }
        return () => flag.current = true
    }, []);

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        background:'black'
    };

    function formatDate(newDate,typeClass = false) {
        const months = {
            0: 'Janvier',
            1: 'Février',
            2: 'Mars',
            3: 'Avril',
            4: 'Mai',
            5: 'Juin',
            6: 'Juillet',
            7: 'Août',
            8: 'Septembre',
            9: 'Octobre',
            10: 'Novembre',
            11: 'Décembre',
        }

        const d = new Date(newDate)
        const year = d.getFullYear()
        const date = d.getDate()
        const monthIndex = d.getMonth() + 1
        const monthNumber = monthIndex.toString().padStart(2,'0')
        const monthName = months[d.getMonth()]
        let formatted = ''
        if (!typeClass)
        {
            formatted = `${date} ${monthName} ${year}`
        } else {
            formatted = `${date}/${monthNumber}/${year}`
        }
        return formatted.toString()
    }

    const inputProps = {
        min: 0,
        max: 1000000
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const names = [
        'Manuelle',
        'Automatique'
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        const today = new Date();
        //TODO : FAIRE UNE VERIF

        if (manufactureDate > today) {
            enqueueSnackbar("La date ne peut pas être supérieure à aujourd'hui !", {
                variant: 'warning', // or variant: warning | error | info
                autoHideDuration: 5000,
                anchorOrigin: {horizontal: "right", vertical: "top"}
            })
            return
        }

        let body = {
            brand: brand,
            title: title,
            mileage: parseInt(mileage),
            manufactureDate: new Date(manufactureDate),
            description: description,
            gearbox: gearBox,
            model: 'api/car_models/'+model,
        }

        personalCarService.postPersonalCar(body)
            .then(res => {

                enqueueSnackbar("Votre véhicule personnel est ajouté !", {
                    variant: 'success', // or variant: warning | error | info
                    autoHideDuration: 5000,
                    anchorOrigin: {horizontal: "right", vertical: "top"}
                })

                //TODO : ça s'affiche pas psk il y pas le snackbarProvider sur le l'autre appli le rajouter app.js
                navigate('/user/dashboard/owning')
            })
            .catch(err => {
                console.log(err)
            })

    }

    return (

        <Box sx={{background:'#f4f4f4'}}>
            <Grid container sx={{ backgroundImage: `url(${fond})`,backgroundRepeat: 'no-repeat',backgroundSize:"cover", padding:'40px' }}>
                <Grid item xs={12} md={10} lg={10}>
                    <Item sx={{background:'transparent',boxShadow:'none', textAlign:'start'}}>
                        <Container maxWidth='xl' sx={{paddingTop:'40px', paddingBottom:'40px'}}>
                            <p style={{fontSize:'4rem', lineHeight:'4.5rem', fontFamily:'MBCorpoATitle', color:'black'}} >Enregistrer un véhicule</p>
                            <p style={{fontSize:'1.125rem', lineHeight:'1.75rem'}}>Infinity-Life me vous connecte à votre véhicule et votre véhicule vous connecte à tout ce qui est important pour vous.</p>
                        </Container>
                    </Item>
                </Grid>
                <Grid item xs={12} md={2} lg={2} sx={{alignSelf:'center'}}>
                    <Item sx={{background:'transparent',boxShadow:'none'}}>
                        <CarRepairIcon sx={{ fontSize: 100,color: 'black' }} />
                    </Item>
                </Grid>
            </Grid>

            <Container maxWidth='xl' sx={{paddingTop:'20px', paddingBottom:'20px',background:'#fff'}}>

                <Grid container sx={{padding:'40px'}}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Container maxWidth='xl' sx={{paddingTop:'40px', paddingBottom:'40px'}}>

                            <Typography variant="h6" sx={{color:'black'}} gutterBottom>
                                <b>Un véhicule. Un FIN.</b>
                            </Typography>

                            <Typography variant="body1" gutterBottom>
                                Un numéro d'identification unique (FIN) a été attribué à votre véhicule. Vous en aurez besoin pour ajouter votre Infinity-Life dans votre profil.
                            </Typography>

                            <Button onClick={handleOpen} sx={{textTransform:'none'}}>
                                <b>> Où puis-je trouver mon FIN</b>
                            </Button>
                            <Modal
                                keepMounted
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="keep-mounted-modal-title"
                                aria-describedby="keep-mounted-modal-description"
                            >
                                <Box sx={style}>
                                    <Typography id="keep-mounted-modal-title" variant="h6" component="h6">
                                        Text in a modal
                                    </Typography>
                                    <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                                        Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                                    </Typography>
                                </Box>
                            </Modal>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '50%' },
                                }}
                                autoComplete="off"
                                onSubmit={handleSubmit}
                            >
                                <TextField
                                    id="filled-multiline-flexible"
                                    label="Numéro d'identification du véhicule"
                                    variant="filled"
                                    name={'brand'}
                                    value={brand}
                                    onChange={(e) => setBrand(e.target.value)}
                                    required
                                />

                                <TextField
                                    id="filled-multiline-flexible"
                                    label="Modèle de votre véhicule"
                                    variant="filled"
                                    name={'title'}
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    required
                                />

                                <TextField
                                    id="filled-multiline-flexible"
                                    label="Kilomètrage du véhicule"
                                    variant="filled"
                                    type={'number'}
                                    name={'mileage'}
                                    value={mileage}
                                    inputProps={inputProps}
                                    onChange={(e) => setMileage(e.target.value)}
                                    required
                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Stack spacing={3}>
                                        <DesktopDatePicker
                                            label="Date de mis en circulation"
                                            inputFormat="DD/MM/YYYY"
                                            value={manufactureDate}
                                            onChange={(e) => setManufactureDate(e)}
                                            renderInput={(params) => <TextField  variant="filled" {...params} required />}
                                        />
                                    </Stack>
                                </LocalizationProvider>

                                <TextField
                                    id="filled-multiline-static"
                                    label="Description"
                                    multiline
                                    rows={4}
                                    defaultValue="Default Value"
                                    variant="filled"
                                    name={'description'}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    required
                                />

                                <FormControl variant="filled" sx={{display:'inline-flex', width:'50%', m:1}}>
                                    <InputLabel id="demo-simple-select-filled-label">Boite de vitesse</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        value={gearBox}
                                        onChange={(e) => setGearBox(e.target.value)}
                                        MenuProps={MenuProps}
                                        required
                                    >
                                        {names.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl variant="filled" sx={{display:'inline-flex', width:'50%', m:1}}>
                                    <InputLabel id="demo-simple-select-filled-label">Modèle</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        value={model}
                                        onChange={(e) => setModel(e.target.value)}
                                        MenuProps={MenuProps}
                                        required
                                    >
                                        {models.map((name) => (
                                            <MenuItem
                                                key={name.id}
                                                value={name.id}
                                            >
                                                {name.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>


                                <Box sx={{p:5, textAlign:'end', background:'#f4f4f4'}}>

                                    <Typography variant="body2">
                                        <Button variant="contained" color="primary" type="submit" sx={{borderRadius:'unset',textTransform:'initial'}}>
                                            Enregistrer votre véhicule
                                        </Button>
                                    </Typography>

                                </Box>

                            </Box>

                        </Container>
                    </Grid>
                </Grid>

            </Container>

        </Box>
    );
}

export default AddPersonalContent;
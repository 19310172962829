import {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import {usedCarService} from "../../_services/usedCar.service";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
    Container,
    Dialog, DialogContent, DialogTitle,
    Divider,
    ImageList,
    ImageListItem, Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import CarMap from '@/components/public/CarMap'
import {accountService} from "../../_services/account.service";
import Button from "@mui/material/Button";
import {useSnackbar} from "notistack";
import * as React from "react";

function UsedCarDetails() {
    const { id } = useParams(); // Récupération de l'ID dans l'URL avec useParams de react-router-dom

    const [isUsedCarLoading, setIsUsedCarLoading] = useState(true);
    const [car, setCar] = useState(null);
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isReserved, setIsReserved] = useState(false);

    let navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const flag = useRef(false)


    const handleImageClick = (image) => {
        setSelectedImage(image);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setIsUsedCarLoading(true);
        usedCarService.getUsedCarDetail(id)
            .then((data) => {
                setCar(data.data)
                console.log(data.data)
                const reservedBy = data.data.reservedBy;
                setIsReserved(reservedBy !== null);
            })
            .catch((err) =>{
                if (err.response.status === 404)
                {
                    enqueueSnackbar("La voiture que vous cherchez n'existe pas ou plus", {
                        variant: 'warning', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    navigate('/occasion')
                }else{
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    navigate('/')
                }

            })
            .finally(() => {
                setIsUsedCarLoading(false);
            })
    }, [id]);

    const handleReservation = async (carId) => {
        const userId = await accountService.getUserId();
        setIsUsedCarLoading(true);

        usedCarService.reserveCar(carId, userId)
            .then((res) => {
                setIsReserved(true);

                enqueueSnackbar("Votre nouvelle voiture d'occasion vient d'être réservé avec succès ✨", {
                    variant: 'success', // or variant: warning | error | info
                    autoHideDuration: 10000,
                    anchorOrigin : {horizontal: "right", vertical: "top"}

                })
            })
            .catch((err) =>{
                enqueueSnackbar("Une erreur s'est produite !", {
                    variant: 'error', // or variant: warning | error | info
                    autoHideDuration: 5000,
                    anchorOrigin : {horizontal: "right", vertical: "top"}
                })
                navigate('/')
            })
            .finally(() => {
                setIsUsedCarLoading(false);
            })
    };

    function srcset(image, size, rows = 1, cols = 1) {
        return {
            src: `${process.env.REACT_APP_PUBLIC_URL+image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
            srcSet: `${process.env.REACT_APP_PUBLIC_URL+image}?w=${size * cols}&h=${
                size * rows
            }&fit=crop&auto=format&dpr=2 2x`,
        };
    }

    if (isUsedCarLoading)
    {
        return (
            <Container maxWidth='lg' sx={{paddingTop:'20px', paddingBottom:'20px', background:'white'}}>
                {/* For other variants, adjust the size with `width` and `height` */}
                <Skeleton variant="rounded" height={250} sx={{ m: 2 }} />
                <Skeleton variant="rounded" height={100} sx={{ m: 2 }} />
                <Skeleton variant="rounded" height={100} sx={{ m: 2 }} />
                <Skeleton variant="rounded" height={100} sx={{ m: 2 }} />
                <Skeleton variant="rounded" height={100} sx={{ m: 2 }} />
                <Skeleton variant="rounded" height={100} sx={{ m: 2 }} />
            </Container>
        );
    }

    return (
        <Box sx={{background:'white'}}>
            <Box sx={{ display: 'flex' }}>
                <Box sx={{ flexGrow: 1, maxWidth: '800px', m: '0 auto' }}>
                    <Card>

                        <ImageList
                            sx={{}}
                            variant="quilted"
                            cols={4}
                            rowHeight={121}
                        >
                            {car.files.map((item, index) => (
                                <ImageListItem
                                    key={item.id}
                                    cols={index === 0 ? 2 : item.cols || 2}
                                    rows={index === 0 ? 2 : item.rows || 1}
                                >
                                    <img
                                        {...srcset(item.contentUrl, 121, item.rows, item.cols)}
                                        alt={item.contentUrl}
                                        loading="lazy"
                                        onClick={() => handleImageClick(item)}
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>

                        <Dialog open={open} onClose={handleClose}>
                            <DialogTitle>👀</DialogTitle>
                            <DialogContent>
                                <Grid container justify="center">
                                    <img style={{backgroundImage:'cover'}} src={process.env.REACT_APP_PUBLIC_URL+selectedImage?.contentUrl} alt={selectedImage?.id} />
                                </Grid>
                            </DialogContent>
                        </Dialog>

                        <CardContent>
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ flexGrow: 1 }}>
                                    <Typography gutterBottom variant="h4" component="div">
                                        {car.title}
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.secondary">
                                        {car.manufactureDate.slice(0, 4)} • {car.mileage} km • {car.fuel} • {car.gearbox}
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.secondary">
                                        <b>{car.price} €</b>
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.secondary">
                                        25/03/2023 à 22:39
                                    </Typography>

                                    {accountService.isLogged() ? (
                                        isReserved ? (
                                            <Button fullWidth variant="contained" disabled>
                                                Réservé
                                            </Button>
                                        ) : (
                                            <Button fullWidth variant="contained" color="primary" onClick={() => handleReservation(car.id)}>
                                                N'attendez pas plus, réservez là 😎
                                            </Button>
                                        )
                                    ) : null}

                                </Box>
                            </Box>
                            <Divider sx={{ my: 2 }} />
                            <Card sx={{ p: 2, boxShadow:'unset' }}>
                                <Typography variant="h5" component="h2" gutterBottom>
                                    Caractéristiques
                                </Typography>
                                <TableContainer component={Paper}>
                                    <Table sx={{ width: '100%' }}>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    Marque
                                                </TableCell>
                                                <TableCell align="right">{car.brand}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    Modèle
                                                </TableCell>
                                                <TableCell align="right">{car.model}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    Année-modèle
                                                </TableCell>
                                                <TableCell align="right">{car.manufactureDate.slice(0, 4)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    Mise en circulation
                                                </TableCell>
                                                <TableCell align="right">{car.manufactureDate.slice(0, 7)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    Kilométrage
                                                </TableCell>
                                                <TableCell align="right">{car.mileage} km</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    Carburant
                                                </TableCell>
                                                <TableCell align="right">{car.fuel}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    Boîte de vitesse
                                                </TableCell>
                                                <TableCell align="right">{car.gearbox}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>

                            <Grid item xs={12} sx={{ mt: 3 }}>
                                <Box>
                                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                        Description
                                    </Typography>
                                    <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
                                        {car.description}
                                    </Typography>
                                </Box>
                            </Grid>

                            <Divider />

                            <Grid item xs={12} sx={{ mt: 3 }}>
                                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                    Localisation
                                </Typography>
                                <Box sx={{ mt: 3 }}>
                                    <CarMap location={car.city} />
                                </Box>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        </Box>
    );
}

export default UsedCarDetails;
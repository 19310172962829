import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import {Container, FilledInput, FormControl, InputLabel} from "@mui/material";
import Grid from "@mui/material/Grid";
import { styled } from '@mui/material/styles';
import {useNavigate} from "react-router-dom";
import {userService} from "@/_services/user.service";
import {useEffect, useRef, useState} from "react";
import Loading from '@/_utils/Loading';
import {useSnackbar} from "notistack";

const ProfileContent = () => {

    const [isUserLoading, setIsUserLoading] = useState(false);
    const [user, setUser] = useState([]);
    const [form, setForm] = useState({
        lastname: null,
        firstname: null,
        email: null,
    });

    let navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const flag = useRef(false)

    const handleChange = (event) => {
        const key = event.target.name;
        const value = event.target.value;
        setForm({ ...form, [key]: value });
    };

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    useEffect(() => {
        if(flag.current === false)
        {
            setIsUserLoading(true);

            userService.getMe()
                .then((data) => {
                    setUser(data.data);
                })
                .catch((err) =>{
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    navigate('/')
                })
                .finally(() => {
                    setIsUserLoading(false);
                })
        }

        return () => flag.current = true
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsUserLoading(true);

        if (!form.firstname && !user.firstname){
            enqueueSnackbar("Le prénom est obligatoire !", {
                variant: 'warning', // or variant: warning | error | info
                autoHideDuration: 5000,
                anchorOrigin : {horizontal: "right", vertical: "top"}
            })
            setIsUserLoading(false);
            return;
        } else if(!form.lastname && !user.lastname){
            enqueueSnackbar("Le nom est obligatoire !", {
                variant: 'warning', // or variant: warning | error | info
                autoHideDuration: 5000,
                anchorOrigin : {horizontal: "right", vertical: "top"}
            })
            setIsUserLoading(false);
            return;
        }

        const body = {
            lastName: form.lastname ? form.lastname : user.lastname,
            firstName: form.firstname ? form.firstname : user.firstname,
        }

        userService.updateProfileAccount(user.id, body)
            .then(res => {

                enqueueSnackbar("Votre profile est à jour !", {
                    variant: 'success', // or variant: warning | error | info
                    autoHideDuration: 5000,
                    anchorOrigin : {horizontal: "right", vertical: "top"}
                })
            })
            .catch(err => {
                enqueueSnackbar("Une erreur s'est produite !", {
                    variant: 'error', // or variant: warning | error | info
                    autoHideDuration: 5000,
                    anchorOrigin : {horizontal: "right", vertical: "top"}
                })
                navigate('/')
            })
            .finally(() => {
                setIsUserLoading(false);
            })
    }

    return (
        <>
            <Container maxWidth='lg' sx={{paddingTop:'20px', paddingBottom:'20px'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <Item sx={{textAlign:'start',padding:'5px'}}>

                            <Button size="large" sx={{color:"#959595",textTransform:'none',fontFamily:"MBCorpoATitle", background:'#e8e8e8'}}>
                                Détails du compte
                            </Button>
                            <br/>
                            <Button onClick={() => navigate('/user/profile/adresses')} size="large" sx={{color:"#959595",textTransform:'none',fontFamily:"MBCorpoATitle"}}>
                                Adresses
                            </Button>
                            <br/>
                            <Button onClick={() => navigate('/user/profile/security')} size="large" sx={{color:"#959595",textTransform:'none',fontFamily:"MBCorpoATitle"}}>
                                Connexion et sécurité
                            </Button>
                        </Item>
                    </Grid>
                    {isUserLoading ? (<Loading />) : (
                        <Grid item xs={12} md={9}>
                            <Container maxWidth='lg' sx={{textAlign:'start', backgroundColor:'white', padding:'8px', borderRadius:'4px',boxShadow:'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)' }} >
                                <h1 style={{fontFamily:"MBCorpoATitle", color:'rgba(0, 0, 0, 0.6)'}}>Détails compte</h1>
                                <span style={{color:'rgba(0, 0, 0, 0.6)'}}>Veuillez fournir les données personnelles demandées.</span>

                                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth variant="filled">
                                                <InputLabel htmlFor="component-filled">Prénom</InputLabel>
                                                <FilledInput
                                                    id="component-filled"
                                                    name="firstname"
                                                    required
                                                    autoComplete="firstname"
                                                    onChange={handleChange}
                                                    defaultValue={user.firstname}
                                                    value={form.firstname}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth variant="filled">
                                                <InputLabel htmlFor="component-filled">Nom de famille</InputLabel>
                                                <FilledInput
                                                    id="component-filled"
                                                    required
                                                    label="Last Name"
                                                    name="lastname"
                                                    autoComplete="lastname"
                                                    onChange={handleChange}
                                                    defaultValue={user.lastname}
                                                    value={form.lastname}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Enregistrer
                                    </Button>
                                </Box>

                            </Container>
                        </Grid>
                    )}
                </Grid>
            </Container>
        </>
    );
}

export default ProfileContent;
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
    Card,
    CardContent,
    CardMedia,
    Container,
    FormControl,
    InputLabel,
    Select, List,
    ListItemText,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {useNavigate} from "react-router-dom";
import {userService} from "@/_services/user.service";
import {styled} from '@mui/material/styles';
import Paper from "@mui/material/Paper";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import fond from '@/images/fond.png';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import TextField from '@mui/material/TextField';
import MenuItem from "@mui/material/MenuItem";
import {useSnackbar} from "notistack";
import {personalCarService} from "../../_services/personalCar.service";
import myImage from "@/images/InfinityLogoBlack2.png";
import MapComp from "@/components/public/MapComp";
import {CalendarMonth, Garage} from "@mui/icons-material";
import Loading from '@/_utils/Loading';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import {maintenanceService} from "../../_services/maintenance.service";


const RequestNewMaintenance = () => {

    const [user, setUser] = useState([]);
    const [selectedGaragiste, setSelectedGaragiste] = useState('');
    const [zoom, setZoom] = useState(5);
    const [coords, setCoords] = useState([45.8534, 5.2488]);
    const [isUserLoading, setIsUserLoading] = useState(true);
    const [isGaragisteLoading, setIsGaragisteLoading] = useState(true);
    const [isPersonnalCarLoading, setIsPersonnalCarLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState('');
    const [personalCar, setPersonalCar] = useState(null);

    const [garagistes, setGaragistes] = useState([]);

    const flag = useRef(false)
    const { enqueueSnackbar } = useSnackbar();
    let navigate = useNavigate();

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    // Obtenir la date du lendemain
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowFormatted = tomorrow.toISOString().split('T')[0];

    useEffect(() => {
        if(flag.current === false) {

            userService.getMe()
                .then((data) => {
                    setUser(data.data);
                })
                .catch((err) =>{
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    navigate('/')
                })
                .finally(() => {

                    setIsUserLoading(false);
                })

            personalCarService.getMyPersonalCar()
                .then((data) => {
                    setPersonalCar(data.data);
                })
                .catch((err) => {
                    if (err.response.status === 404){
                        enqueueSnackbar("Vous n'avez pas de voiture personnel sur votre compte !", {
                            variant: 'warning', // or variant: warning | error | info
                            autoHideDuration: 5000,
                            anchorOrigin : {horizontal: "right", vertical: "top"}
                        })
                        navigate('/user/dashboard/owning')
                    }else{
                        enqueueSnackbar("Une erreur s'est produite !", {
                            variant: 'error', // or variant: warning | error | info
                            autoHideDuration: 5000,
                            anchorOrigin : {horizontal: "right", vertical: "top"}
                        })
                    }
                })
                .finally(() => setIsPersonnalCarLoading(false))

            userService.getAllGaragistes()
                .then((res) => {
                    const modifiedData = res.data.map(item => {
                        // Générer une latitude aléatoire en France
                        const latitude = Math.random() * (51.1242 - 41.3333) + 41.3333;

                        // Générer une longitude aléatoire en France
                        const longitude = Math.random() * (9.6625 - -5.2667) + -5.2667;

                        // Ajouter les nouvelles entrées "latitude" et "longitude"
                        return {
                            ...item,
                            latitude: latitude,
                            longitude: longitude
                        };
                    });
                    setGaragistes(modifiedData)
                })
                .catch((err) =>{
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    navigate('/')
                })
                .finally(() => {

                    setIsGaragisteLoading(false);
                })
        }
        return () => flag.current = true
    }, []);

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const handleChange = (e) => {
        const target = e.target.value;
        const magasin = garagistes.find((m) => m.id === target);
        setCoords([magasin.latitude, magasin.longitude]);
        setSelectedGaragiste(e.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault()

        setIsUserLoading(true);

        if (!selectedGaragiste || !selectedDate){
            enqueueSnackbar("Veuillez rensigner tous le formulaire d'entretien", {
                variant: 'warning', // or variant: warning | error | info
                autoHideDuration: 5000,
                anchorOrigin : {horizontal: "right", vertical: "top"}
            })
            setIsUserLoading(false);
            return
        }

        const garagiste = garagistes.find((m) => m.id === selectedGaragiste);

        const formValue = {
            date: selectedDate,
            title: "Maintenance "+personalCar.title,
            id_mechanic : garagiste.id,
        }

        maintenanceService.postMaintenance(formValue)
            .then((res) => {
                enqueueSnackbar("Votre entretien est confirmé !", {
                    variant: 'success', // or variant: warning | error | info
                    autoHideDuration: 5000,
                    anchorOrigin : {horizontal: "right", vertical: "top"}
                })
                navigate('/user/mes-entretiens/')
            })
            .catch((err) => {
                console.log(err)

                if (err.response.status === 403 && err.response.data.message === "Un entretien est deja reserve pour cette date")
                {
                    enqueueSnackbar("Un entretien est deja reserve pour cette date !", {
                        variant: 'warning', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    return
                } else if(err.response.status === 403 && err.response.data.message === "MAINTENANCE_LOWER_6")
                {
                    enqueueSnackbar("Vous avez deja effectue un entretien il y a moins de 6 mois sur ce véhicule, vous devez attendre au moins 6 mois avant de demander un autre entretien !", {
                        variant: 'warning', // or variant: warning | error | info
                        autoHideDuration: 15000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    return
                }

                enqueueSnackbar("Une erreur s'est produite !", {
                    variant: 'error', // or variant: warning | error | info
                    autoHideDuration: 5000,
                    anchorOrigin : {horizontal: "right", vertical: "top"}
                })
            })
            .finally(() => setIsUserLoading(false))

    }

    return (

        <Box sx={{background:'#f4f4f4'}}>
            <Grid container sx={{ backgroundImage: `url(${fond})`,backgroundRepeat: 'no-repeat',backgroundSize:"cover", padding:'40px' }}>
                <Grid item xs={12} md={10} lg={10}>
                    <Item sx={{background:'transparent',boxShadow:'none', textAlign:'start'}}>
                        <Container maxWidth='xl' sx={{paddingTop:'40px', paddingBottom:'40px'}}>
                            <p style={{fontSize:'4rem', lineHeight:'4.5rem', fontFamily:'MBCorpoATitle', color:'black'}} >Demande d'un entretien</p>
                            <p style={{fontSize:'1.125rem', lineHeight:'1.75rem'}}>Infinity-Life me vous connecte à votre véhicule et votre véhicule vous connecte à tout ce qui est important pour vous.</p>
                        </Container>
                    </Item>
                </Grid>
                <Grid item xs={12} md={2} lg={2} sx={{alignSelf:'center'}}>
                    <Item sx={{background:'transparent',boxShadow:'none'}}>
                        <CarRepairIcon sx={{ fontSize: 100,color: 'black' }} />
                    </Item>
                </Grid>
            </Grid>

            {isUserLoading || isGaragisteLoading || isPersonnalCarLoading ? (<Loading />) : null}

            <Container maxWidth='xl' sx={{paddingTop:'20px', paddingBottom:'20px',background:'#fff'}}>

                <Grid container >
                    <Grid item xs={12} md={12} lg={12}>
                        <Container maxWidth='xl' sx={{paddingTop:'40px', paddingBottom:'40px'}}>

                            <Typography variant="h6" sx={{color:'black'}} gutterBottom>
                                <b>Choisir un garagiste agréé par <u>Infinity-Life</u></b>
                            </Typography>

                            <Typography variant="body1" gutterBottom>
                                Vous devrez vous présenter le jour du rendez-vous à l'accueil pour les formalités avant que commence l'entretien.
                            </Typography>

                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '50%' },
                                }}
                                autoComplete="off"
                                onSubmit={handleSubmit}
                            >

                                <FormControl sx={{mt:3,mb:3, width:'100%' }}>
                                    <InputLabel id="magasin-select-label">Magasin</InputLabel>
                                    <Select
                                        labelId="magasin-select-label"
                                        id="magasin-select"
                                        value={selectedGaragiste}
                                        label={'Magasin'}
                                        onChange={handleChange}
                                    >
                                        {garagistes.map((garagiste) => (
                                            <MenuItem key={garagiste.id} value={garagiste.id}>{garagiste.email}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                {
                                    selectedGaragiste && (
                                        <>

                                            <MapComp selectedMagasin={selectedGaragiste} coords={coords} zoom={zoom} magasins={garagistes} />

                                            <Card sx={{
                                                mt:5,
                                                display: { xs: 'block', sm: 'flex', md:'flex', lg:'flex' }
                                            }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', width:'100%' }}>
                                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                                        <Typography component="div" variant="h5" sx={{mb:3}}>
                                                            Choix de votre date d'entretien :
                                                        </Typography>

                                                        <TextField
                                                            id="date"
                                                            label="Sélectionner une date"
                                                            type="date"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            inputProps={{
                                                                min: tomorrowFormatted,
                                                            }}
                                                            required
                                                            value={selectedDate}
                                                            onChange={handleDateChange}
                                                        />

                                                    </CardContent>
                                                </Box>
                                            </Card>

                                            <Card sx={{
                                                justifyContent:'space-between',
                                                mt:5,
                                                display: { xs: 'block', sm: 'flex', md:'flex', lg:'flex' }
                                            }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', width:'100%' }}>
                                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                                        <Typography component="div" variant="h5">
                                                            Récapitulatif :
                                                        </Typography>
                                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                                            Pour le choix de votre paiement et de livraison vous avez choisis :



                                                            <Typography variant={'subtitle1'} component={'div'}>

                                                            </Typography>

                                                        </Typography>
                                                        <List
                                                            sx={{
                                                                width: '100%',
                                                                maxWidth: 360,
                                                                bgcolor: 'background.paper',
                                                            }}
                                                        >
                                                            <ListItem>
                                                                <ListItemAvatar>
                                                                    <Avatar sx={{background: '#81b4ff'}}>
                                                                        <Garage />
                                                                    </Avatar>
                                                                </ListItemAvatar>
                                                                <ListItemText primary="Garagiste agréé" secondary={garagistes.find((m) => m.id === selectedGaragiste).email} />
                                                            </ListItem>
                                                            <Divider variant="inset" component="li" />
                                                            <ListItem>
                                                                <ListItemAvatar>
                                                                    <Avatar sx={{background: '#ffdea8'}}>
                                                                        <CalendarMonth />
                                                                    </Avatar>
                                                                </ListItemAvatar>
                                                                <ListItemText primary="Date d'entretien souhaité" secondary={selectedDate ? selectedDate : 'pas encore renseignée'} />
                                                            </ListItem>
                                                        </List>
                                                    </CardContent>
                                                </Box>
                                                <CardMedia
                                                    component="img"
                                                    sx={{ width: '100%' }}
                                                    image={myImage}
                                                    alt="Live from space album cover"
                                                />
                                            </Card>
                                        </>

                                    )
                                }


                                <Box sx={{p:5, textAlign:'end', background:'#f4f4f4',mt:2}}>

                                    <Typography variant="body2">
                                        <Button variant="contained" color="primary" type="submit" sx={{borderRadius:'unset',textTransform:'initial'}}>
                                            Confirmer votre rendez-vous
                                        </Button>
                                    </Typography>

                                </Box>

                            </Box>

                        </Container>
                    </Grid>
                </Grid>

            </Container>

        </Box>
    );
}

export default RequestNewMaintenance;
import * as React from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {accountService} from "@/_services/account.service";
import "@/css/login.css"
import {HelpOutline} from "@mui/icons-material";
import {useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Card, CardContent, Tooltip} from "@mui/material";
import {useEffect, useState} from "react";
import Loading from '@/_utils/Loading';

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const ResetPassword = () => {
    const [plainPassword, setPlainPassword] = useState('');
    const [matchPwd, setMatchPwd] = useState('');
    const [isUserLoading, setIsUserLoading] = useState(false);

    let navigate = useNavigate();
    const location = useLocation();
    const {token} = useParams()
    const { enqueueSnackbar } = useSnackbar();
    const theme = createTheme();

    //TODO: rajouter une vérif pour voir si le token existe

    useEffect(() => {
        if (location.pathname === '/auth/resetPassword/'+token) {
            document.body.classList.add('LoginbodyCover');
        }
        return () => {
            if (location.pathname === '/auth/resetPassword/'+token) {
                document.body.classList.remove('LoginbodyCover');
            }
        }
    }, [location.pathname])

    const handleSubmit = (event) => {
        event.preventDefault()

        setIsUserLoading(true);

        const carConfirm = document.querySelector('.errmsg');
        carConfirm.style.display = 'none';

        const v2 = PWD_REGEX.test(plainPassword);
        if (!v2) {
            const carConfirm = document.querySelector('.errmsg');
            carConfirm.style.display = 'block';
            carConfirm.innerHTML = "<div style='color: red'>Votre mot de passe ne respecte pas les règles : <ul><li>- 8 charactères minimum</li> <li>- Une minuscule, une majuscule</li><li>- Un chiffre</li><li>- Un caractère spécial</li></ul></div>";
            setIsUserLoading(false);
            return;
        }else if (plainPassword !== matchPwd){
            const carConfirm = document.querySelector('.errmsg');
            carConfirm.style.display = 'block';
            carConfirm.innerHTML = "<div style='color: red'>Les mot de passe ne correspondent pas</div>";
            setIsUserLoading(false);
            return;
        }

        /* inteligence reset password */

        accountService.resetPassword(token, plainPassword)
            .then((res) => {
                const carConfirm = document.querySelector('.errmsg');
                carConfirm.style.display = 'block';
                carConfirm.innerHTML = "<div style='background: green; color: white'>Votre mot de passe est bien à jour.</div>";

                enqueueSnackbar("Votre mot de passe est bien à jour.", {
                    variant: 'success', // or variant: warning | error | info
                    autoHideDuration: 5000,
                    anchorOrigin : {horizontal: "right", vertical: "top"}
                })

                navigate('/auth/login')
            })
            .catch((err) => {
                const carConfirm = document.querySelector('.errmsg');
                carConfirm.style.display = 'block';
                carConfirm.innerHTML = "<div style='background: red; color: white'>Votre compte n'à pas été retrouvé.</div>";

                enqueueSnackbar("Votre identifiant n'a pas été retouvé !", {
                    variant: 'error', // or variant: warning | error | info
                    autoHideDuration: 5000,
                    anchorOrigin : {horizontal: "right", vertical: "top"}
                })
                navigate('/')
            })
            .finally(() => {
                setIsUserLoading(false)
            });

    }

    return (
        <>
            {isUserLoading ? <Loading /> : null}
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="md">
                    <Box
                        sx={{
                            marginTop: 30,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            backgroundColor:'white'
                        }}
                    >
                        <Box sx={{ width: 1 }}>
                            <Card sx={{ backgroundColor:'black', borderRadius:'0px'}} >
                                <CardContent sx={{padding:'0px !important', margin:1 }}>
                                    <Typography sx={{ fontSize: 15, color:'white',margin:0  }} gutterBottom>
                                        Réinitialiser votre mot de passe
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box sx={{ width: 1 }}>
                            <Card sx={{  borderRadius:'0px', boxShadow:'none'}} >
                                <CardContent sx={{padding:'0px !important', margin:1 }}>
                                    <Typography sx={{ fontSize: 30,margin:0,textAlign:'center' }} gutterBottom>
                                        Infinity-Life me ID
                                        <Tooltip title="L'infinity-Life me ID vous permet de disposer d'un accès central à nos services ainsi que d'un profil intégré dans lequel vous pouvez modifier vos données.">
                                            <HelpOutline color="secondary" fontSize="small" />
                                        </Tooltip>

                                    </Typography>
                                    <Typography sx={{ fontSize: 15, margin:0,textAlign:'center'  }} gutterBottom>
                                        Veuillez saisir votre adresse e-mail de récupération
                                    </Typography>
                                    <Typography className={'errmsg'} sx={{ fontSize: 15, margin:0,textAlign:'center',display:'none'  }} gutterBottom>

                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box component="form" onSubmit={handleSubmit} sx={{ m:2 }}>
                            <TextField
                                margin="normal"
                                fullWidth
                                type="password"
                                id="password"
                                label="Mot de passe"
                                onChange={(e) => setPlainPassword(e.target.value)}
                                value={plainPassword}
                                required
                            />

                            <TextField
                                margin="normal"
                                fullWidth
                                label="Confirmer le Mot de passe"
                                name="password"
                                autoComplete="off"
                                type="password"
                                id="confirm_pwd"
                                onChange={(e) => setMatchPwd(e.target.value)}
                                value={matchPwd}
                                required
                            />

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Enregistrer
                            </Button>
                            <Grid sx={{justifyContent:'end'}} container>
                                <Grid >
                                    <Typography sx={{ fontSize: 15, margin:0, color:'black'}} >
                                        <Button variant="text" onClick={() => {navigate('/auth/login')}} style={{fontSize:12}}>
                                            Se connecter
                                        </Button>
                                    </Typography>

                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ width: 1 }}>
                            <Card sx={{ backgroundColor:'black', borderRadius:'0px'}} >
                                <CardContent sx={{padding:'0px !important', margin:1, textAlign:'end' }}>
                                    <Button variant="text" style={{fontSize:12, color:'white',margin:0}}>
                                        Mentions légales
                                    </Button>
                                </CardContent>
                            </Card>
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </>
    );
};

export default ResetPassword;

import hi1image from '@/assets/images/headlines/headline_a_class_design.jpg'
import hi2image from '@/assets/images/headlines/headline_a_class_interior.jpg'
import hi3image from '@/assets/images/headlines/headline_a_class_technology.jpg'
import hi4image from '@/assets/images/headlines/headline_safety.jpg'

export const ImageHeadlineData = {
    design: {
      link: '#',
      headlineTitle: 'Conception de classe A',
      headlineParagraph: 'En savoir plus sur le',
      imageURL: hi1image
    },
    interior: {
      link: '#',
      headlineTitle: 'Intérieur Classe A',
      headlineParagraph: 'En savoir plus sur le',
      imageURL: hi2image
    },
    technology: {
      link: '#',
      headlineTitle: 'Technologie de classe A',
      headlineParagraph: 'En savoir plus sur le',
      imageURL: hi3image
    },
    safety: {
      link: '#',
      headlineTitle: 'Sécurité',
      headlineParagraph: 'En savoir plus sur la',
      imageURL: hi4image
    }
}

import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AccountContent from "@/components/admin/AccountContent";
import InventoryIcon from '@mui/icons-material/Inventory';

const Account = () => {
    return (
    <>
        <AppBar position="relative" sx={{background:'white', color:'black'}}>
            <Toolbar>
                <InventoryIcon sx={{ mr: 2 }} />
                <Typography variant="h6" color="inherit" noWrap>
                    Mes commandes
                </Typography>
            </Toolbar>
        </AppBar>


        <AccountContent />


    </>
        );
};

export default Account;
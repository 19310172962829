import * as React from 'react';
import Headline from "../../components/global/Headline";
import CarsTabsTest from "../../components/global/CarsTabsTest";
export default function Home() {


    return (
        <>
            <Headline
                title={"Nos modèles"}
                paragraph={""}
                styleBackground={"white"}
                styleTitle={"black"}
                children={<CarsTabsTest  />}
            />

        </>
    );
}
import React, {useState} from 'react';
import { Typography, Container, TextField, Button, Box } from '@mui/material';
import {useSnackbar} from "notistack";
import {contactService} from "@/_services/contact.service";
import {useNavigate} from "react-router-dom";
import Loading from '@/_utils/Loading';

const Contact = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isContactLoading, setIsContactLoading] = useState(false);

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = (event) => {
        event.preventDefault();
        // Logic to handle form submission
        setIsContactLoading(true);

        if (!name || !email || !message){
            enqueueSnackbar('Tous les champs sont obligatoires !', {
                variant: 'warning', // or variant: warning | error | info
                autoHideDuration: 5000,
                anchorOrigin : {horizontal: "right", vertical: "top"}

            })
            setIsContactLoading(false);
            return
        }

        const formData = {
            name: name,
            email: email,
            message: message,
        };

        contactService.postContact(formData)
            .then((data) => {
                enqueueSnackbar('Votre message a bien éte envoyé !', {
                    variant: 'success', // or variant: warning | error | info
                    autoHideDuration: 5000,
                    anchorOrigin : {horizontal: "right", vertical: "top"}

                })
            })
            .catch((err) =>{
                console.log(err)
                enqueueSnackbar("Une erreur s'est produite !", {
                    variant: 'error', // or variant: warning | error | info
                    autoHideDuration: 5000,
                    anchorOrigin : {horizontal: "right", vertical: "top"}
                })
            })
            .finally(() => {
                setIsContactLoading(false);
                setName('');
                setEmail('');
                setMessage('');
            })

    };

    return (
        <Box sx={{background:'white',color:'black'}}>
            {isContactLoading ? <Loading /> : null}
            <Container maxWidth="md" sx={{pt:5,pb:5}}>
                <Typography variant="h4" component="h1" sx={{ marginBottom: '32px' }}>
                    Contactez-nous
                </Typography>
                <small>Toutes les questions seront traitées dans un délai de 48 heures maximum.</small>
                <form onSubmit={handleSubmit}>
                    <TextField
                        id="name"
                        label="Nom"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        required
                    />
                    <TextField
                        id="email"
                        label="Adresse e-mail"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={email}
                        type={'email'}
                        onChange={(event) => setEmail(event.target.value)}
                        required
                    />
                    <TextField
                        id="message"
                        label="Message"
                        variant="outlined"
                        multiline
                        rows={4}
                        fullWidth
                        margin="normal"
                        value={message}
                        onChange={(event) => setMessage(event.target.value)}
                        required
                    />
                    <Button variant="contained" type="submit">
                        Envoyer
                    </Button>
                </form>
            </Container>
        </Box>

    );
};

export default Contact;

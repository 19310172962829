import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
    Container,
    FormControl,
    InputLabel,
    Modal,
    Select, Skeleton, Stack
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {useNavigate} from "react-router-dom";
import {userService} from "@/_services/user.service";
import {styled, useTheme} from '@mui/material/styles';
import Paper from "@mui/material/Paper";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import fond from '@/images/fond.png';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import TextField from '@mui/material/TextField';
import MenuItem from "@mui/material/MenuItem";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import {personalCarService} from "@/_services/personalCar.service";
import {carModelService} from "@/_services/carModel.service";
import {useSnackbar} from "notistack";

const EditPersonalContent = () => {

    const [user, setUser] = useState([]);
    const [models, setModels] = useState([]);
    const [personalCar, setPersonalCar] = useState(null);
    const [open, setOpen] = useState(false);
    const [brand, setBrand] = useState("Infinity-Life");
    const [title, setTitle] = useState("");
    const [model, setModel] = useState("");
    const [mileage, setMileage] = useState(0);
    const [description, setDescription] = useState("");
    const [gearBox, setGearBox] = useState('');
    const [manufactureDate, setManufactureDate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isModelLoading, setIsModelLoading] = useState(true);
    const [isCarLoading, setIsCarLoading] = useState(true);

    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const flag = useRef(false)
    let navigate = useNavigate();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        if(flag.current === false)
        {
            setIsLoading(true);
            setIsModelLoading(true);
            setIsCarLoading(true);

            userService.getMe()
                .then((data) => {
                    setUser(data.data);
                })
                .catch((err) =>{
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    navigate('/')
                })
                .finally(() => {
                    setIsLoading(false);
                })

            carModelService.getAllCarModels()
                .then((data) => {
                    setModels(data);
                })
                .catch((err) =>{
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    navigate('/')
                })
                .finally(() => {
                    setIsModelLoading(false);
                })

            personalCarService.getMyPersonalCar()
                .then((data) => {
                    setPersonalCar(data.data);
                })
                .catch((err) => {
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    navigate('/')
                })
                .finally(() => setIsCarLoading(false))

        }

        return () => flag.current = true
    }, []);

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        background:'black'
    };

    function formatDate(newDate,typeClass = false) {
        const months = {
            0: 'Janvier',
            1: 'Février',
            2: 'Mars',
            3: 'Avril',
            4: 'Mai',
            5: 'Juin',
            6: 'Juillet',
            7: 'Août',
            8: 'Septembre',
            9: 'Octobre',
            10: 'Novembre',
            11: 'Décembre',
        }
        const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']

        const d = new Date(newDate)
        const year = d.getFullYear()
        const date = d.getDate()
        const monthIndex = d.getMonth() + 1
        const monthNumber = monthIndex.toString().padStart(2,'0')
        const monthName = months[d.getMonth()]
        const dayName = days[d.getDay()] // Thu
        const dayNumber = d.getDay() // Thu
        let formatted = ''
        if (!typeClass)
        {
            formatted = `${date} ${monthName} ${year}`
        } else {
            formatted = `${date}/${monthNumber}/${year}`
        }
        return formatted.toString()
    }

    const inputProps = {
        min: 0,
        max: 1000000
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const names = [
        'Manuelle',
        'Automatique',
        'Electrique'
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();

        const today = new Date();
        //TODO : FAIRE UNE VERIF

        if (manufactureDate > today) {
            enqueueSnackbar("La date ne peut pas être supérieure à aujourd'hui !", {
                variant: 'warning', // or variant: warning | error | info
                autoHideDuration: 5000,
                anchorOrigin: {horizontal: "right", vertical: "top"}
            })
            return
        }

        let body = {
            id : personalCar.id,
            brand: !brand ? personalCar.brand : brand,
            title: !title ? personalCar.title : title,
            mileage: !mileage ? personalCar.mileage : parseInt(mileage),
            manufactureDate: !manufactureDate ? personalCar.manufactureDate : new Date(manufactureDate),
            description: !description ? personalCar.description : description,
            gearbox: !gearBox ? personalCar.gearbox : gearBox,
            model: !model ? 'api/car_models/'+personalCar.model.id : 'api/car_models/'+model
        }

        personalCarService.editPersonalCar(body)
            .then(res => {

                enqueueSnackbar("votre véhicule personnel est à jour !", {
                    variant: 'success', // or variant: warning | error | info
                    autoHideDuration: 5000,
                    anchorOrigin : {horizontal: "right", vertical: "top"}
                })

                navigate('/user/dashboard/owning')
            })
            .catch(err => {
                enqueueSnackbar("Une erreur s'est produite !", {
                    variant: 'error', // or variant: warning | error | info
                    autoHideDuration: 5000,
                    anchorOrigin : {horizontal: "right", vertical: "top"}
                })
            })
            .finally(() => {
                setIsCarLoading(false);
            })
    }

    if (isLoading || isModelLoading || isCarLoading) {
        return (
            <Container maxWidth='lg' sx={{paddingTop:'20px', paddingBottom:'20px', background:'transparent'}}>
                {/* For other variants, adjust the size with `width` and `height` */}
                <Skeleton variant="rounded" height={150} sx={{ m: 2 }} />
                <br/>
                <br/>
                <br/>
                <Skeleton variant="rounded" height={80} sx={{ m: 2 }} />
                <Skeleton variant="rounded" height={80} sx={{ m: 2 }} />
                <Skeleton variant="rounded" height={80} sx={{ m: 2 }} />
                <Skeleton variant="rounded" height={80} sx={{ m: 2 }} />
                <Skeleton variant="rounded" height={80} sx={{ m: 2 }} />

            </Container>
        );
    }

    return (

        <Box sx={{background:'#f4f4f4'}}>
            <Grid container sx={{ backgroundImage: `url(${fond})`,backgroundRepeat: 'no-repeat',backgroundSize:"cover", padding:'40px' }}>
                <Grid item xs={12} md={10} lg={10}>
                    <Item sx={{background:'transparent',boxShadow:'none', textAlign:'start'}}>
                        <Container maxWidth='xl' sx={{paddingTop:'40px', paddingBottom:'40px'}}>
                            <p style={{fontSize:'4rem', lineHeight:'4.5rem', fontFamily:'MBCorpoATitle', color:'black'}} >Edition de votre véhicule personnel</p>
                            <p style={{fontSize:'1.125rem', lineHeight:'1.75rem'}}>Infinity-Life me vous connecte à votre véhicule et votre véhicule vous connecte à tout ce qui est important pour vous.</p>
                        </Container>
                    </Item>
                </Grid>
                <Grid item xs={12} md={2} lg={2} sx={{alignSelf:'center'}}>
                    <Item sx={{background:'transparent',boxShadow:'none'}}>
                        <CarRepairIcon sx={{ fontSize: 100,color: 'black' }} />
                    </Item>
                </Grid>
            </Grid>

            <Container maxWidth='xl' sx={{paddingTop:'20px', paddingBottom:'20px',background:'#fff'}}>

                <Grid container sx={{padding:'40px'}}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Container maxWidth='xl' sx={{paddingTop:'40px', paddingBottom:'40px'}}>

                            <Typography variant="h6" sx={{color:'black'}} gutterBottom>
                                <b>Un véhicule. Un FIN.</b>
                            </Typography>

                            <Typography variant="body1" gutterBottom>
                                Un numéro d'identification unique (FIN) a été attribué à votre véhicule. Vous en aurez besoin pour ajouter votre Infinity-Life dans votre profil.
                            </Typography>

                            <Button onClick={handleOpen} sx={{textTransform:'none'}}>
                                <b> Où puis-je trouver mon FIN</b>
                            </Button>

                            <Modal
                                keepMounted
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="keep-mounted-modal-title"
                                aria-describedby="keep-mounted-modal-description"
                            >
                                <Box sx={style}>
                                    <Typography id="keep-mounted-modal-title" variant="h6" component="h6">
                                        Text in a modal
                                    </Typography>
                                    <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                                        Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                                    </Typography>
                                </Box>
                            </Modal>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: {xs:'100%', lg:'50%'} },
                                }}
                                autoComplete="off"
                                onSubmit={handleSubmit}
                            >
                                <TextField
                                    id="filled-multiline-flexible"
                                    label="Numéro d'identification du véhicule"
                                    variant="filled"
                                    name={'brand'}
                                    fullWidth
                                    value={brand ? brand : personalCar.brand}
                                    onChange={(e) => setBrand(e.target.value)}
                                    required
                                />

                                <TextField
                                    id="filled-multiline-flexible"
                                    label="Modèle de votre véhicule"
                                    variant="filled"
                                    name={'title'}
                                    value={title ? title : personalCar.title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    required
                                />

                                <TextField
                                    id="filled-multiline-flexible"
                                    label="Kilomètrage du véhicule"
                                    variant="filled"
                                    type={'number'}
                                    name={'mileage'}
                                    fullWidth
                                    value={mileage ? mileage : personalCar.mileage}
                                    inputProps={inputProps}
                                    onChange={(e) => setMileage(e.target.value)}
                                    required
                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Stack spacing={3}>
                                        <DesktopDatePicker
                                            label="Date de mis en circulation"
                                            inputFormat="DD/MM/YYYY"
                                            value={manufactureDate ? manufactureDate : dayjs(personalCar.manufactureDate) }
                                            onChange={(e) => setManufactureDate(e)}
                                            renderInput={(params) => <TextField variant="filled" {...params} required fullWidth/>}
                                        />
                                    </Stack>
                                </LocalizationProvider>

                                <TextField
                                    id="filled-multiline-static"
                                    label="Description"
                                    multiline
                                    rows={4}
                                    variant="filled"
                                    fullWidth
                                    name={'description'}
                                    value={description ? description : personalCar.description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    required
                                />

                                <FormControl variant="filled" sx={{m:1, width: {xs:'100%', lg:'50%'}}}>
                                    <InputLabel id="demo-simple-select-filled-label">Boite de vitesse</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        value={gearBox ? gearBox : personalCar.gearbox}
                                        onChange={(e) => setGearBox(e.target.value)}
                                        fullWidth
                                        MenuProps={MenuProps}
                                        required
                                    >
                                        {names.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl variant="filled" sx={{ m:1, width: {xs:'100%', lg:'50%'}}}>
                                    <InputLabel id="demo-simple-select-filled-label">Modèle</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        value={model ? model : personalCar.model.id}
                                        onChange={(e) => setModel(e.target.value)}
                                        fullWidth
                                        MenuProps={MenuProps}
                                        required
                                    >
                                        {models.map((name) => (
                                            <MenuItem
                                                key={name.id}
                                                value={name.id}
                                            >
                                                {name.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>


                                <Box sx={{p:5, textAlign:'end', background:'#f4f4f4'}}>

                                    <Typography variant="body2">
                                        <Button variant="contained" color="primary" type="submit" sx={{borderRadius:'unset',textTransform:'initial'}}>
                                            Enregistrer votre véhicule
                                        </Button>
                                    </Typography>

                                </Box>

                            </Box>

                        </Container>
                    </Grid>
                </Grid>

            </Container>

        </Box>
    );
}

export default EditPersonalContent;
import React, {useLayoutEffect, useRef, useState} from 'react';
import {Route, Routes, useNavigate} from "react-router-dom";
import Error from "@/_utils/Error";
import {Layout, Home, Contact} from "@/pages/Public";
import Pieces from "./Pieces";
import Accessoires from "./Accessoires";
import UsedCars from "./UsedCars";
import UsedCarDetails from "./UsedCarDetails";
import Configurator from "./Configurator";
import CarOrderConfirm from "./CarOrderConfirm";
import AllCars from "./AllCars";
import History from "./History";
import CarDetailLayout from "./Layout/CarDetailLayout";
import {userService} from "../../_services/user.service";
import {useSnackbar} from "notistack";
import {Box} from "@mui/material";
import {accountService} from "../../_services/account.service";

const PublicRouter = () => {

    const [isUserLoading, setIsUserLoading] = useState(false);
    const [userRole, setUserRole] = useState('');
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();
    const flag = useRef(false)

    useLayoutEffect(() => {
        if(flag.current === false) {
            const token = localStorage.getItem('token');
            if (token) {
                setIsUserLoading(true);
                userService.getMe()
                    .then((response) => {
                        setUserRole(response.data.roles)
                        if (response.data.roles[0] === 'ROLE_GARAGISTE') {
                            enqueueSnackbar("Vous allez être redirigé vers le panel garagiste", {
                                variant: 'success', // or variant: warning | error | info
                                autoHideDuration: 5000,
                                anchorOrigin: {horizontal: "right", vertical: "top"}
                            })
                            navigate('/garagiste')
                        }else if (response.data.roles[0] === 'ROLE_ADMIN'){
                            accountService.logout()
                        }
                    })
                    .catch((err) => {
                        enqueueSnackbar("Une erreur s'est produite !", {
                            variant: 'error', // or variant: warning | error | info
                            autoHideDuration: 5000,
                            anchorOrigin: {horizontal: "right", vertical: "top"}
                        })
                        navigate('/')
                    })
                    .finally(() => {
                        setIsUserLoading(false);
                    })
            }
        }
        return () => flag.current = true

    }, []);

    if(isUserLoading)
    {
        return (
            <div class="overlay">
                <div class="overlayDoor"></div>
                <div class="overlayContent">
                    <div class="loader">
                        <div class="inner"></div>
                    </div>
                    <Box sx={{fontSize:'xxx-large', mt:5, fontFamily:'MBCorpoATitle'}}>Infinity-Life</Box>
                </div>
            </div>
        )
    }

    return (
        <div>
            <Routes>
                <Route element={<Layout/>}>

                    <Route index element={<Home/>}/>

                    <Route path="home" element={<Home/>}/>
                    <Route path="contact" element={<Contact/>}/>
                    <Route path="pieces" element={<Pieces />}/>
                    <Route path="accessoires" element={<Accessoires />}/>
                    <Route path="occasion" element={<UsedCars />}/>
                    <Route path="occasion/:id" element={<UsedCarDetails />}/>
                    <Route path="configurator" element={<Configurator />}/>
                    <Route path="carOrder/confirm/:id" element={<CarOrderConfirm />}/>
                    <Route path="car/all" element={<AllCars />}/>
                    <Route path="car/:model" element={<CarDetailLayout />}/>
                    <Route path="history" element={<History/>}/>

                    <Route path="*" element={<Error/>}/>
                </Route>
            </Routes>
        </div>
    );
};

export default PublicRouter;

import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from "@mui/material/Toolbar";
import {Person} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import ProfileSecurityContent from "@/components/admin/ProfileSecurityContent";

const ProfileSecurity = () => {
    return (
    <>
        <AppBar position="relative" sx={{background:'white', color:'black'}}>
            <Toolbar sx={{ml:10}}>
                <Person sx={{ mr: 2 }} />
                <Typography variant="h6" color="inherit" noWrap>
                    Profile
                </Typography>
            </Toolbar>
        </AppBar>


        <ProfileSecurityContent />


    </>
        );
};

export default ProfileSecurity;
import Axios from "./caller.service";

let getAllExpertises = () => {
    return Axios.get('api/used_cars')
}

let getExpertiseById = (id) => {
    return Axios.get('api/expertises/'+id)
}

let updateExpertise = (idMaintenance, formValue) => {

    return Axios.put('api/expertises/'+idMaintenance, formValue)
}

export const expertiseService = {
    getAllExpertises, getExpertiseById, updateExpertise
}
import React, {useEffect, useRef, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from "@mui/material/Toolbar";
import {Person} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import {userService} from "@/_services/user.service";
import { Card, CardContent, CardMedia, Grid, Container, Button,Modal, Box, Skeleton} from '@mui/material';
import iris from '@/images/noCar.png';
import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CloseIcon from '@mui/icons-material/Close';
import {expertiseService} from "../../_services/expertise.service";
import Chip from '@mui/material/Chip';
import {Divider} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';

const AllExpertises = () => {

    const [isUserLoading, setIsUserLoading] = useState(false);
    const [user, setUser] = useState([]);
    const [isDatasLoading, setIsDatasLoading] = useState(false);
    const [datas, setDatas] = React.useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [modalData, setModalData] = useState(null);

    const navigate = useNavigate()
    const flag = useRef(false)
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        if (flag.current === false) {
            setIsDatasLoading(true);
            setIsUserLoading(true);

            userService.getMe()
                .then((data) => {
                    setUser(data.data);
                })
                .catch((err) => {
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin: {horizontal: "right", vertical: "top"}
                    })
                    navigate('/')
                })
                .finally(() => {
                    setIsUserLoading(false);
                })

            expertiseService.getAllExpertises()
                .then((res) => {
                    setDatas(res.data)
                    console.log(res.data)
                })
                .catch((error) => {

                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                })
                .finally(() => {
                    setIsDatasLoading(false)
                });
        }

        return () => flag.current = true

    }, []);


    const handleCardDismiss = (cardId) => {

        console.log(cardId)
        navigate('/garagiste/start/expertise/'+cardId)

    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleCancel = (expertise) => {

        setIsDatasLoading(true);

        expertiseService.updateExpertise(expertise.expertise.id, {status : 'cancel'})
            .then(res => {
                // TODO : envoie mail pour informer que l'expertise est annulée
                enqueueSnackbar("L'expertise est annulée", {
                    variant: 'success', // or variant: warning | error | info
                    autoHideDuration: 5000,
                    anchorOrigin : {horizontal: "right", vertical: "top"}
                })
                setCancel(true)
            })
            .catch(err => {
                enqueueSnackbar("Une erreur s'est produite !", {
                    variant: 'error', // or variant: warning | error | info
                    autoHideDuration: 5000,
                    anchorOrigin : {horizontal: "right", vertical: "top"}
                })
                navigate('/')
            })
            .finally(() => {
                setIsDatasLoading(false);
            })

        closeModal()
    };

    return (
        <>
            <AppBar position="relative" sx={{background: 'white', color: 'black'}}>
                <Toolbar>
                    <Person sx={{mr: 2}}/>
                    <Typography variant="h6" color="inherit" noWrap>
                        Toutes les Expertises
                    </Typography>
                </Toolbar>
            </AppBar>
            {isDatasLoading || isUserLoading ?
                (
                    <Container maxWidth='lg' sx={{paddingTop:'20px', paddingBottom:'20px', background:'transparent'}}>
                        <Box sx={{display:'flex', justifyContent:'space-evenly'}}>
                            <Skeleton variant="rounded" width={300} height={300} sx={{ m: 2 }} />
                            <Skeleton variant="rounded" width={300} height={300} sx={{ m: 2 }} />
                            <Skeleton variant="rounded" width={300} height={300} sx={{ m: 2 }} />
                            <Skeleton variant="rounded" width={300} height={300} sx={{ m: 2 }} />
                        </Box>
                    </Container>
                ) : (
                    <Container maxWidth='lg' sx={{paddingTop:'20px', paddingBottom:'20px'}}>

                        {datas.length > 0 ? (
                            <>
                                <Grid container spacing={2}>
                                    {datas.map(card => (
                                        <Grid item xs={6} sm={3} key={card.id}>
                                            <Card>
                                                <CardMedia
                                                    component="img"
                                                    height="140"
                                                    image={iris}
                                                    alt={card.title}
                                                />
                                                <CardContent>
                                                    <Typography variant="h6" component="div" sx={{textAlign:'center'}}>
                                                        {card.title}
                                                    </Typography>
                                                    <div style={{ marginTop: '10px', display:'flex', justifyContent: 'space-around', alignItems:'center' }}>
                                                        {
                                                            (card.expertise.done && card.expertise.status === 'confirmed') ? (
                                                                <Divider sx={{pt:2}}>
                                                                    <Chip label={'Expertise effectué'} color="success" size="small"/>
                                                                </Divider>
                                                            ) : (card.expertise.status === 'cancel' || cancel) ? (
                                                                <Divider sx={{pt:2}}>
                                                                    <Chip label={'Expertise Annulée'} color="error" size="small"/>
                                                                </Divider>
                                                            ) : (
                                                                <>
                                                                    <Tooltip title="Commencer l'expertise" placement="top">
                                                                        <IconButton aria-label="delete" variant="contained" sx={{background:'blue', color:'white'}} onClick={() => handleCardDismiss(card.expertise.id)}>
                                                                            <PlayArrowIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Chip label={'A faire'} color="primary" size="small"/>
                                                                    <Tooltip title="Refuser l'expertise" placement="top">
                                                                        <IconButton aria-label="delete" variant="contained" sx={{background:'red', color:'white'}} onClick={() => {
                                                                            openModal();
                                                                            setModalData(card);
                                                                        }}>
                                                                            <CloseIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </>
                                                            )
                                                        }

                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>

                                <Modal
                                    open={isModalOpen}
                                    onClose={closeModal}
                                >
                                    <Box sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: 400,
                                        bgcolor: 'background.paper',
                                        border: '2px solid #000',
                                        boxShadow: 24,
                                        p: 4,
                                        color:'black'
                                    }}>
                                        <Typography variant="h6" component="h2">Confirmation</Typography>
                                        <Typography variant="body1">Êtes-vous sûr de vouloir effectuer cette action ?</Typography>
                                        <Box sx={{mt:2}}>
                                            <Button variant="contained" onClick={closeModal} sx={{mr:2}}>Annuler</Button>
                                            <Button variant="contained" color="primary" onClick={() => handleCancel(modalData)}>Confirmer</Button>
                                        </Box>
                                    </Box>
                                </Modal>
                            </>
                        ) : (
                            <Grid container spacing={2} sx={{background:'white'}}>
                                <Grid item key={'none'} xs={12} sm={12} md={12} lg={12}>
                                    <Typography variant="h5" component="h2" align="left" style={{color:'grey',textAlign:'center'}}>
                                        Aucune Expertise disponnible 🚘
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}



                    </Container>
                )}
        </>
    )
}

export default AllExpertises;
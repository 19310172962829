import {Navigate} from "react-router-dom";
import {accountService} from "@/_services/account.service";

const AuthGuard = ({children}) => {
    let logged = accountService.isLogged()

    if(!logged){
        return <Navigate to="/auth/login"/>
    }

    return children
};

export default AuthGuard;

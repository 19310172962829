import Axios from "./caller.service";
let getCars = () => {
    return Axios.get('api/cars')
}

let getCarsByModel = (model) => {
    return Axios.get('api/cars?model.name='+model)
}

let getCarsByCarKey = (carKey) => {
    return Axios.get('api/cars?carKey='+carKey)
}

let getCarsConsidering = () => {
    return Axios.get('api/user/carOrders')
}

let getCarByRef = (ref) => {
    return Axios.get('api/user/car_orders/'+ref)
}

let getCarsPackByValue = (value) => {
    return Axios.get('api/car_packs?value='+value)
}

let postCarOrder = (formData) => {
    return Axios.post('api/user/carOrder', formData)
}

let validAgence = (id, formValue) => {
    return Axios.put('api/car_orders/'+id, formValue)
}

export const carService = {
    getCars, postCarOrder, validAgence, getCarByRef, getCarsPackByValue, getCarsConsidering, getCarsByModel, getCarsByCarKey
}
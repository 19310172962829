import React, {useEffect, useRef, useState} from 'react';
import { styled } from '@mui/material/styles';
import {
    List,
    ListItem,
    ListItemText,
    Typography,
    Paper,
    Box,
    Button, Container, Skeleton
} from '@mui/material';
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {cartService} from "../../_services/cart.service";
import CardMedia from "@mui/material/CardMedia";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";

const Root = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));

const Left = styled('div')(({ theme }) => ({
    flex: '1 1 0',
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        marginBottom: theme.spacing(3),
        width:'100%'
    },
}));

const Right = styled('div')(({ theme }) => ({
    flex: '0 1 300px',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.grey[100],
    [theme.breakpoints.down('sm')]: {
        flex: '1 1 0',
        width:'100%'
    },
}));

const Total = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const Cart = () => {
    const [isCartLoading, setIsCartLoading] = useState(true);
    const [items, setItems] = useState([]);
    let [empty, setEmpty] = useState(true)

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate()
    const flag = useRef(false)

    useEffect(() => {
        if(flag.current === false)
        {
            setIsCartLoading(true);
            cartService.getMyCart()
                .then((data) => {
                    if (data.data.length !== 0){
                        setItems(data.data)
                        setEmpty(false)
                    }
                })
                .catch((err) =>{
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    navigate('/')
                })
                .finally(() => {
                    setIsCartLoading(false);
                })
        }

        return () => flag.current = true
    }, []);

    const getTotal = (items) => {
        return items.reduce((accumulator, item) => {
            return accumulator + item.partAccessory.price * item.quantity;
        }, 0);
    };

    if (isCartLoading)
    {
        return (
            <Container maxWidth='xl' sx={{paddingTop:'20px', paddingBottom:'20px', background:'white'}}>
                {/* For other variants, adjust the size with `width` and `height` */}
                <Box sx={{display:'flex', justifyContent:'space-evenly'}}>
                    <Skeleton variant="rounded"  height={150} sx={{width:'80%', m: 2 }} />
                    <Skeleton variant="rounded" height={150} sx={{width:'20%', m: 2 }} />
                </Box>
                <Skeleton variant="rounded" height={150} sx={{ m: 2 }} />
            </Container>
        );
    }

    return (
        <Box>
            {empty ? (
                <>
                    <AppBar position="relative" sx={{background:'white', color:'black'}}>
                        <Toolbar sx={{ml:10}}>
                            <ShoppingCartIcon sx={{ mr: 2 }} />
                            <Typography variant="h6" color="inherit" noWrap>
                                Détail de votre panier
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <Root>
                        <section className="page_404" style={{width:'100%'}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12 ">
                                        <div className="col-sm-10 col-sm-offset-1" style={{textAlign:'center'}}>
                                            <Box className="four_zero_four_bg" sx={{backgroundRepeat:'no-repeat', backgroundSize: { xs:'cover', lg:'contain'}}} />

                                            <div className="contant_box_404">
                                                <h3 className="h2" style={{fontFamily:'MBCorpoATitle'}}>
                                                    Votre panier est vide
                                                </h3>
                                                <button onClick={() => {
                                                    navigate('/accessoires')
                                                }} className="link_404" style={{fontFamily:'MBCorpoATitle'}}>Aller aux accessoires et pièces</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Root>

                </>
            ) : (
            <>
                <AppBar position="relative" sx={{background:'white', color:'black'}}>
                    <Toolbar sx={{ml:10}}>
                        <ShoppingCartIcon sx={{ mr: 2 }} />
                        <Typography variant="h6" color="inherit" noWrap>
                            Détail de votre panier <span style={{fontSize:'0.75rem'}}>({items.length} articles)</span>
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Root>
                    <Left>
                        <Paper sx={{backgroundColor:'transparent', boxShadow:'none'}} elevation={3}>
                            <List>
                                {items.map((item) => (
                                    <>
                                        <ListItem key={item.id} sx={{boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)', mb:5}}>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 200, height:'fit-content',alignSelf:'center' }}
                                                image={process.env.REACT_APP_PUBLIC_URL+item.partAccessory.images[0].contentUrl}
                                                alt="Live from space album cover"
                                            />
                                            <ListItemText sx={{margin:2}} primary={item.partAccessory.name} secondary={`${item.partAccessory.price}€ - Qty: ${item.quantity}`} />
                                        </ListItem>
                                    </>
                                ))}
                            </List>
                        </Paper>
                    </Left>
                    <Right>
                        <Typography variant="h5" gutterBottom>
                            Total
                        </Typography>
                        <Box>
                            <Total variant="subtitle1" gutterBottom>
                                {getTotal(items)} €
                            </Total>
                            <ButtonWrapper>
                                <Button variant="contained" color="primary" onClick={() => navigate('/user/order/process')}>
                                    Choisir ma livraison
                                </Button>
                            </ButtonWrapper>
                        </Box>
                    </Right>
                </Root>
            </>
            ) }

        </Box>
    );
};

export default Cart;

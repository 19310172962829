import React, {useEffect, useRef, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from "@mui/material/Toolbar";
import {Person} from "@mui/icons-material";
import {useNavigate, useParams} from "react-router-dom";
import {maintenanceService} from "../../_services/maintenance.service";
import {useSnackbar} from "notistack";
import Loading from '@/_utils/Loading';
import {userService} from "@/_services/user.service";
import { Container,Grid, Paper, Typography, IconButton } from '@mui/material';
import iris from '@/images/noCar.png';
import Button from '@mui/material/Button';
import Box from "@mui/material/Box";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import DeleteIcon from '@mui/icons-material/Delete';

const StartMaintenance = () => {

    const [isDatasLoading, setIsDatasLoading] = useState(true);
    const [isUserLoading, setIsUserLoading] = useState(true);
    const [maintenance, setMaintenance] = React.useState([]);
    const [user, setUser] = useState([]);
    const [inputs, setInputs] = useState([]);

    const {id} = useParams()
    const navigate = useNavigate()
    const flag = useRef(false)
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if(flag.current === false)
        {
            setIsDatasLoading(true);
            setIsUserLoading(true);

            userService.getMe()
                .then((data) => {
                    setUser(data.data);
                })
                .catch((err) =>{
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    navigate('/')
                })
                .finally(() => {
                    setIsUserLoading(false);
                })

            maintenanceService.getMaintenanceById(id)
                .then((res) => {
                    setMaintenance(res.data)

                    if (res.data.done){
                        enqueueSnackbar("L'entretien a déjà été effectué ", {
                            variant: 'success', // or variant: warning | error | info
                            autoHideDuration: 5000,
                            anchorOrigin : {horizontal: "right", vertical: "top"}
                        })
                        navigate('/garagiste')
                    }

                    if (res.data.status !== 'scanned'){
                        enqueueSnackbar("Le client n'a pas scannée le QR Code de confirmation de rendez-vous", {
                            variant: 'warning', // or variant: warning | error | info
                            autoHideDuration: 5000,
                            anchorOrigin : {horizontal: "right", vertical: "top"}
                        })

                        navigate('/garagiste/maintenance/'+res.data.id)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    navigate('/garagiste')
                })
                .finally(() => {
                    setIsDatasLoading(false)
                });
        }

        return () => flag.current = true

    }, []);


    const handleAddInput = () => {
        setInputs([...inputs, { key: '', value: '' }]);
    };

    const handleInputChange = (index, field, value) => {
        const updatedInputs = [...inputs];
        updatedInputs[index][field] = value;
        setInputs(updatedInputs);
    };

    const handleRemoveInput = (index) => {
        const updatedInputs = [...inputs];
        updatedInputs.splice(index, 1);
        setInputs(updatedInputs);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Insérer ici le code pour valider et insérer les inputs
        if (inputs.length > 0) {
            setIsDatasLoading(true);

            // Création du dataset
            const dataset = inputs.reduce((acc, input) => {
                acc.push({ [input.key]: input.value });
                return acc;
            }, []);

            const formValue = {
                dataset: dataset,
                done: true
            }

            maintenanceService.updateStatusMaintenance(maintenance.id, formValue)
                .then((res) =>{

                    enqueueSnackbar("Entretien effectué avec succès", {
                        variant: 'success', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    navigate('/garagiste')

                })
                .catch((err) =>{
                    console.log(err)
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                })
                .finally(() => {
                    setIsDatasLoading(false);
                })

        }
    };

    const inputProps = {
        min: 0,
        max: 1000000
    };

    return (
    <>
        <AppBar position="relative" sx={{background:'white', color:'black'}}>
            <Toolbar sx={{ml:10}}>
                <Person sx={{ mr: 2 }} />
                <Typography variant="h6" color="inherit" noWrap>
                    Entretien en cours
                </Typography>
            </Toolbar>
        </AppBar>
        {isDatasLoading || isUserLoading ? <Loading /> : (
            <Container maxWidth="xl" sx={{paddingTop:'20px', paddingBottom:'20px', background:'transparent'}}>
                <Box component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Paper elevation={3} sx={{ padding: '20px' }}>
                                <Typography variant="h6" gutterBottom>
                                    Liste de toutes les pièces changées
                                </Typography>
                                {inputs.map((input, index) => (
                                    <Grid container spacing={2} key={index} sx={{mt:2,mb:2}}>
                                        <Grid item xs={6}>
                                            {/*<TextField*/}
                                            {/*    label=""*/}
                                            {/*    onChange={(e) => handleInputChange(index, 'key', e.target.value)}*/}
                                            {/*    fullWidth*/}
                                            {/*    margin="normal"*/}
                                            {/*    required*/}
                                            {/*/>*/}

                                            <FormControl  variant="outlined" fullWidth>
                                                <InputLabel htmlFor="outlined-adornment-piece">Pièce changée</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-piece"
                                                    onChange={(e) => handleInputChange(index, 'key', e.target.value)}
                                                    label="Pièce changée"
                                                    value={input.key}
                                                    required
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {/*<TextField*/}
                                            {/*    label="Prix"*/}
                                            {/*    value={input.value}*/}
                                            {/*    onChange={(e) => handleInputChange(index, 'value', e.target.value)}*/}
                                            {/*    fullWidth*/}
                                            {/*    margin="normal"*/}
                                            {/*    required*/}
                                            {/*/>*/}

                                            <FormControl  variant="outlined" fullWidth>
                                                <InputLabel htmlFor="outlined-adornment-amount">Prix</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-amount"
                                                    onChange={(e) => handleInputChange(index, 'value', e.target.value)}
                                                    value={input.value}
                                                    type={'number'}
                                                    required
                                                    label="Prix"
                                                    inputProps={inputProps}
                                                    endAdornment={<InputAdornment position="end">€</InputAdornment>}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton
                                                onClick={() => handleRemoveInput(index)}
                                                aria-label="delete"
                                                color="error"
                                                size="large"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                ))}
                                <Button variant={'outlined'} onClick={handleAddInput}>Ajouter une pièce changée</Button>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Paper elevation={3} sx={{ padding: '20px' }}>
                                <Typography variant="h6" gutterBottom>
                                    Véhicule à entretenir (<small>{(maintenance.title).replace('Maintenance ','')}</small>)
                                </Typography>

                                <img
                                    src={iris}
                                    style={{width:'100%'}}
                                    alt="infinity"
                                />

                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={3} sx={{ padding: '20px' }}>
                                <Button variant="contained" type={'submit'} disabled={inputs.length === 0}>
                                    Valider
                                </Button>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        )}

    </>
    );
};



export default StartMaintenance;
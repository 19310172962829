import React from 'react';
import historyV from '@/assets/videos/history.mp4'
import Ac12 from '@/assets/images/Ac12.png';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useTheme} from '@mui/material/styles';
import {Grid } from '@mui/material';
import CarRepairIcon from "@mui/icons-material/CarRepair";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
const History = () => {
    const theme = useTheme();

    let navigate = useNavigate();

    const brandStory = `Infinity-Life est une marque de voitures qui repousse les limites de la technologie et du design. Notre vision est de créer des véhicules qui offrent une expérience de conduite sans précédent tout en préservant l'environnement. Chaque voiture Infinity-Life est conçue avec une attention méticuleuse aux détails et à l'innovation, en utilisant les dernières avancées technologiques pour offrir une performance exceptionnelle et une connectivité intelligente.

  L'engagement envers nos clients est au cœur de notre philosophie. Nous cherchons à créer des liens étroits avec chaque personne qui choisit Infinity-Life comme partenaire de mobilité. Nous sommes fiers de proposer un service clientèle exceptionnel et une expérience personnalisée à chaque étape du processus d'achat et de propriété.

  Avec Infinity-Life, vous pouvez vous attendre à la meilleure qualité et à une conduite sans compromis. Notre équipe de designers et d'ingénieurs travaille sans relâche pour repousser les limites de l'innovation et pour offrir des voitures qui suscitent l'admiration. Nous sommes une marque qui croit en l'avenir de la mobilité électrique et nous investissons constamment dans la recherche et le développement pour façonner la prochaine génération de voitures électriques de luxe.

  Rejoignez-nous dans notre voyage vers un avenir où le luxe, la technologie et la durabilité se rejoignent pour créer une expérience de conduite inégalée. Bienvenue chez Infinity-Life, où chaque instant passé au volant est une découverte inspirante.`;

    return (
        <>
            <Box sx={{position: 'relative', paddingBottom: '56.25%', paddingTop: theme.spacing(2), height: 0, overflow: 'hidden'}}>
                <video style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} autoPlay muted loop>
                    <source src={historyV} type="video/mp4" />
                </video>
            </Box>

            <Container maxWidth="md" sx={{marginTop: theme.spacing(4), marginBottom: theme.spacing(4)}}>
                <Typography variant="h4" component="h1" sx={{ marginBottom: theme.spacing(2), fontWeight: 'bold', textAlign: 'center'}}>
                    L'histoire d'Infinity-Life
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: theme.spacing(2), fontWeight: 'bold', textAlign: 'center'}}>
                    {brandStory}
                </Typography>
                <hr/>
            </Container>
            <Grid container spacing={2} sx={{mt:5,mb:5}}>
                <Grid item xs={12} lg={5}>
                    <img
                        src={Ac12}
                        alt="Infinity-Life Car"
                        style={{ marginBottom: theme.spacing(2), maxWidth: '100%', height: 'auto' }}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(4),}}>
                        <h1>Chez Infinity-Life</h1>
                        <br/>nous sommes pionniers de la mobilité durable. Nous investissons massivement dans la recherche et le développement de technologies respectueuses de l'environnement, avec pour objectif ultime de réduire notre empreinte carbone et de contribuer à un avenir plus propre et plus vert.
                        <br/>
                        <br/>
                        <Button
                            variant="outlined"
                            sx={{ border: '1px solid rgb(255 255 255 / 50%)', color: '#ffffff'}}
                            startIcon={<CarRepairIcon />}
                            size="large"
                            onClick={() => {navigate('/car/all')}}
                        >
                            Découvrir tous nos modèles
                        </Button>
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default History;

import React, {useEffect, useRef} from 'react';
// Styles
import './Preview.css';
// Components
import Slideshow from '@/components/public/configurator/Slideshow';


export default function Preview(props) {

  const flag = useRef(false)

  useEffect(() => {
    if(flag.current === false) {
      getItems()
      getIndex()
    }
    return () => flag.current = true
  }, [])

  function getIndex() {
    return props.models.findIndex(model =>
        model.carKey === props.config?.model
    )
  }

  function getItems() {
    return props.models.map(model => ({
      alt: model.name,
      url: `${process.env.REACT_APP_PUBLIC_URL}/media/cars/models/model_${model.carKey}/model_${model.carKey}_${props.config.color}_${props.config.wheels}.png`,
      scale: ['x'].includes(model.carKey)
    }))
  }

  function selectedModel() {

    return props.models.find(model =>
        model.carKey === props.config.model
    )
  }

  function selectedType() {
    return selectedModel()?.carPacks?.find(type =>
        type.value === props.config.car_type
    )
  }

  function specs() {
    return selectedType()?.specs;
  }

  const handleOnClickPrev = () => {
    const newIndex = getIndex() > 0
        ? getIndex() - 1
        : props.models.length - 1;

    return props.onChangeModel(props.models?.[newIndex]?.carKey);
  };

  const handleOnClickNext = () => {
    const newIndex = getIndex() < props.models.length - 1
        ? getIndex() + 1
        : 0;

    return props.onChangeModel(props.models?.[newIndex]?.carKey);
  };

    return (
        <div className="preview">
          <Slideshow
              items={getItems()}
              index={getIndex()}
              showPrev={props.showAllModels}
              showNext={props.showAllModels}
              onClickPrev={() => handleOnClickPrev()}
              onClickNext={() => handleOnClickNext()}
          />
          {
            props.showSpecs ? (
                <ul className="specs">
                  <li>
                    <span className="specs-value">{specs()?.range ?? ' - '}km</span>
                    <span className="specs-label">Autonomie Carburant <small>(avec un plein complet)</small></span>
                  </li>
                  <li>
                    <span className="specs-value">{specs()?.top_speed ?? ' - '}mph</span>
                    <span className="specs-label">Vitesse maximale</span>
                  </li>
                  <li>
                    <span className="specs-value">{specs()?.acceleration_time ?? ' - '}s</span>
                    <span className="specs-label">0-100 km/h</span>
                  </li>
                </ul>
            ) : null
          }
        </div>
    );
};


import React, {useEffect, useRef, useState} from 'react';
import {useSnackbar} from "notistack";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {Alert, Card, CardContent, Stack, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {HelpOutline} from "@mui/icons-material";
import {useLocation, useNavigate} from "react-router-dom";
import {accountService} from "@/_services/account.service";
import {userService} from "@/_services/user.service";
import Loading from '@/_utils/Loading';

const USER_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ChangeEmail = () => {
    const [user, setUser] = useState([]);
    const [isUserLoading, setIsUserLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');

    const location = useLocation();
    const theme = createTheme();
    const userRef = useRef();
    const errRef = useRef();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const flag = useRef(false)

    useEffect(() => {
        if (location.pathname === '/user/profile/changeEmail') {
            document.body.classList.add('LoginbodyCover');
            var elements = document.getElementsByClassName('admin_body')
            for (var i = 0; i < elements.length; i++) {
                elements[i].style.background = 'none';
            }

        }
        return () => {
            if (location.pathname === '/user/profile/changeEmail') {
                document.body.classList.remove('LoginbodyCover');
                var elements = document.getElementsByClassName('admin_body')
                for (var i = 0; i < elements.length; i++) {
                    elements[i].style.background = '#f8f8f8';
                }
            }
        }
    }, [location.pathname])

    useEffect(() => {
        if(flag.current === false)
        {
            setIsUserLoading(true);

            userService.getMe()
                .then((data) => {
                    setUser(data.data);
                })
                .catch((err) =>{
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    navigate('/')
                })
                .finally(() => {
                    setIsUserLoading(false);
                })
        }

        return () => flag.current = true
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const em = USER_REGEX.test(email)
        const cem = USER_REGEX.test(confirmEmail)

        if (!em || !cem)
        {
            enqueueSnackbar("Les deux adresses mail ne correspondent pas !", {
                variant: 'error', // or variant: warning | error | info
                autoHideDuration: 5000,
                anchorOrigin : {horizontal: "right", vertical: "top"}
            })
            return
        }

        let body = {
            email: email ? email : user.email,
        }

        userService.updateProfileAccount(user.id, body)
            .then(res => {

                enqueueSnackbar("Votre adresse mail à bien été modifié", {
                    variant: 'success', // or variant: warning | error | info
                    autoHideDuration: 10000,
                    anchorOrigin : {horizontal: "right", vertical: "top"}
                })
                enqueueSnackbar("Vous allez être redirigé vers la page de connexion", {
                    variant: 'success', // or variant: warning | error | info
                    autoHideDuration: 10000,
                    anchorOrigin : {horizontal: "right", vertical: "top"}
                })
                accountService.logout()
            })
            .catch(err => {
                if (err.response?.status === 403 ) {
                    enqueueSnackbar("Cette adresse mail est déjà utilisé !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 10000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                }else {
                    enqueueSnackbar("Cette adresse mail est déjà utilisé !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 10000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                }
            })
            .finally(() => {
                setIsUserLoading(false)
            });
    }

    return (
        <>
            {isUserLoading ? (<Loading />) : null}
                <ThemeProvider theme={theme}>
                    <Container component="main" maxWidth="md">
                        <Box
                            sx={{
                                marginTop: 15,
                                marginBottom: 15,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                backgroundColor:'white'
                            }}
                        >
                            <Box sx={{ width: 1 }}>
                                <Card sx={{ backgroundColor:'black', borderRadius:'0px'}} >
                                    <CardContent sx={{padding:'0px !important', margin:1 }}>
                                        <Typography sx={{ fontSize: 15, color:'white',margin:0,textAlign:'center'  }} gutterBottom>
                                            Modifier votre Adresse Mail
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Box>
                            <Box sx={{ width: 1 }}>
                                <Card sx={{  borderRadius:'0px', boxShadow:'none'}} >
                                    <CardContent sx={{padding:'0px !important', margin:1 }}>
                                        <Typography sx={{ fontSize: 30,margin:0,textAlign:'center'  }} gutterBottom>
                                            Infinity-Life me ID
                                            <Tooltip title="L'infinity-Life me ID vous permet de disposer d'un accès central à nos services ainsi que d'un profil intégré dans lequel vous pouvez modifier vos données.">
                                                <HelpOutline color="secondary" fontSize="small" />
                                            </Tooltip>

                                        </Typography>
                                        <Typography sx={{ fontSize: 15, margin:0,textAlign:'center'  }} gutterBottom>
                                            Veuillez saisir votre nouvelle adresse e-mail et la confirmer
                                        </Typography>
                                        <Typography sx={{ fontSize: 15, margin:0}} gutterBottom>
                                            <Stack sx={{ width: '100%'}} >
                                                <Alert severity="warning" sx={{justifyContent:'center'}}>Si vous modifier votre adresse mail vous allez devoir vous reconnecter</Alert>
                                            </Stack>
                                        </Typography>

                                    </CardContent>
                                </Card>
                            </Box>
                            <Box component="form" className={'form'} sx={{ mt: 1, maxWidth:'75%' }} onSubmit={handleSubmit}>

                                <TextField
                                    margin="normal"
                                    fullWidth
                                    type="email"
                                    id="email"
                                    label="Nouvelle adresse Mail"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    required
                                />

                                <TextField
                                    margin="normal"
                                    fullWidth
                                    label="Confirmer votre adresse mail"
                                    name="confirmEmail"
                                    type="email"
                                    id="confirm_email"
                                    onChange={(e) => setConfirmEmail(e.target.value)}
                                    value={confirmEmail}
                                    required
                                />

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Enregistrer
                                </Button>
                            </Box>
                            <Box sx={{ width: 1 }}>
                                <Card sx={{ backgroundColor:'black', borderRadius:'0px'}} >
                                    <CardContent sx={{padding:'0px !important', margin:1, textAlign:'end' }}>
                                        <Button variant="text" style={{fontSize:12, color:'white',margin:0}}>
                                            Mentions légales
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Box>
                        </Box>
                    </Container>
                </ThemeProvider>
        </>
    );
};

export default ChangeEmail;
import React, {useEffect, useRef, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from "@mui/material/Toolbar";
import {Person} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import {userService} from "@/_services/user.service";
import {Card, CardContent, CardMedia, Grid, Container, Box, Skeleton} from '@mui/material';
import iris from '@/images/noCar.png';
import {maintenanceService} from "../../_services/maintenance.service";
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import {isSameDay} from '@/_utils/utils';

const AllMaintenances = () => {

    const [isUserLoading, setIsUserLoading] = useState(false);
    const [user, setUser] = useState([]);
    const [isDatasLoading, setIsDatasLoading] = useState(false);
    const [datas, setDatas] = React.useState([]);

    const navigate = useNavigate()
    const flag = useRef(false)
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        if (flag.current === false) {
            setIsDatasLoading(true);
            setIsUserLoading(true);

            userService.getMe()
                .then((data) => {
                    setUser(data.data);
                })
                .catch((err) => {
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin: {horizontal: "right", vertical: "top"}
                    })
                    navigate('/')
                })
                .finally(() => {
                    setIsUserLoading(false);
                })

            maintenanceService.getAllMaintenances()
                .then((res) => {
                    console.log(res.data)
                    setDatas(res.data)
                })
                .catch((error) => {
                    if (error.response.status === 401){
                        enqueueSnackbar("Votre adresse mail ou mot de passe ne sont pas correct !", {
                            variant: 'warning', // or variant: warning | error | info
                            autoHideDuration: 5000,
                            anchorOrigin : {horizontal: "right", vertical: "top"}
                        })
                    }else{
                        enqueueSnackbar("Une erreur s'est produite !", {
                            variant: 'error', // or variant: warning | error | info
                            autoHideDuration: 5000,
                            anchorOrigin : {horizontal: "right", vertical: "top"}
                        })
                    }
                })
                .finally(() => {
                    setIsDatasLoading(false)
                });
        }

        return () => flag.current = true

    }, []);


    const handleCardDismiss = (cardId) => {

        console.log(cardId)

    };

    return (
        <>
            <AppBar position="relative" sx={{background: 'white', color: 'black'}}>
                <Toolbar>
                    <Person sx={{mr: 2}}/>
                    <Typography variant="h6" color="inherit" noWrap>
                        Toutes les Maintenances
                    </Typography>
                </Toolbar>
            </AppBar>
            {isDatasLoading || isUserLoading ?
                (
                    <Container maxWidth='lg' sx={{paddingTop:'20px', paddingBottom:'20px', background:'transparent'}}>
                        <Box sx={{display:'flex', justifyContent:'space-evenly'}}>
                            <Skeleton variant="rounded" width={300} height={300} sx={{ m: 2 }} />
                            <Skeleton variant="rounded" width={300} height={300} sx={{ m: 2 }} />
                            <Skeleton variant="rounded" width={300} height={300} sx={{ m: 2 }} />
                            <Skeleton variant="rounded" width={300} height={300} sx={{ m: 2 }} />
                        </Box>
                    </Container>
                ) : (
                     <Container maxWidth='lg' sx={{paddingTop:'20px', paddingBottom:'20px'}}>
                {datas.length > 0 ? (
                    <>
                        <Grid container spacing={2}>
                            {datas.map(card => {
                                const [day, month, year] = card.date.split('/');
                                const newDate = new Date(`${month}/${day}/${year}`);

                                return (
                                    <Grid item xs={6} sm={3} key={card.id}>
                                        <Card>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image={iris}
                                                alt={card.title}
                                            />
                                            <CardContent>
                                                <Typography variant="h6" component="div" sx={{textAlign:'center'}}>
                                                    {card.title}
                                                </Typography>
                                                <Divider sx={{pt:2}}>
                                                    {
                                                        card.done ? (<Chip label="Maintenance effectué" color="success" />) : (new Date(card.date) < new Date() && isSameDay(new Date(card.date),new Date())) ? (<Chip label="Maintenance pas encore effectué" color="warning"/>) : (<Chip label="Maintenance non effectué" color="error"/>)
                                                    }
                                                </Divider>

                                                <Divider sx={{pt:2}}>
                                                    <Chip label={card.date} color="primary"/>
                                                </Divider>

                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </>
                ) : (
                    <Grid container spacing={2} sx={{background:'white'}}>
                        <Grid item key={'none'} xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="h5" component="h2" align="left" style={{color:'grey',textAlign:'center'}}>
                                Aucune Maintenance disponnible 🚘
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Container>
                )}
        </>
    )
}

export default AllMaintenances;
import '@/App.css';
import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";
import PublicRouter from "@/pages/Public/PublicRouter";
import UserRouter from "@/pages/User/RouterConfig/UserRouter";
import GaragisteRouter from "@/pages/Garagiste/RouterConfig/GaragisteRouter";
import AuthRouter from "@/pages/Auth/AuthRouter";
import AuthGuard from "@/_helpers/AuthGuard";
import React, {useEffect, useState} from "react";
import {SnackbarProvider} from "notistack";
import BasicSnackbar from "./components/common/BasicSnackbar";
import {userService} from "./_services/user.service";
import {Box} from "@mui/material";

function App() {

    const [isUserLoading, setIsUserLoading] = useState(false);
    const [severityPost, setSeverityPost] = useState('info');
    const [messagePost, setMessagePost] = useState('info');
    const [open, setOpen] = useState(false);
    const [userRole, setUserRole] = useState('');

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsUserLoading(true);
            userService.getMe()
                .then((response) => {
                    console.log(response.data.roles)
                    setUserRole(response.data.roles)
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    setIsUserLoading(false);
                })
        }
    }, []);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    function ScrollToTop() {
        const {pathname} = useLocation();

        useEffect(() => {
            const element = document.getElementById('top');
            element.scrollIntoView({behavior: 'smooth'});
        }, [pathname]);

        return null;
    }

    if(isUserLoading)
    {
        return (
            <div class="overlay">
                <div class="overlayDoor"></div>
                <div class="overlayContent">
                    <div class="loader">
                        <div class="inner"></div>
                    </div>
                    <Box sx={{fontSize:'xxx-large', mt:5, fontFamily:'MBCorpoATitle'}}>Infinity-Life</Box>
                </div>
            </div>
        )
    }

    return (

        <SnackbarProvider maxSnack={3}>
            <BasicSnackbar
                vertical={'top'}
                horizontal={'right'}
                open={open}
                onClose={handleClose}
                severity={severityPost}
                message={messagePost}

            />
            <div className="App">
                <BrowserRouter>
                    <ScrollToTop/>
                    <div id="top"></div>
                    <Routes>

                        <Route path="/*" element={<PublicRouter/>}/>

                        <Route path="/user/*" element={
                            <AuthGuard>
                                <UserRouter/>
                            </AuthGuard>
                        }/>

                        <Route path="/garagiste/*" element={
                            <AuthGuard>
                                <GaragisteRouter/>
                            </AuthGuard>
                        }/>

                        <Route path="/auth/*" element={
                            <AuthRouter/>
                        }/>

                    </Routes>
                </BrowserRouter>
            </div>
        </SnackbarProvider>
    );
}

export default App;

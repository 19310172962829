import React, {useEffect, useRef, useState} from 'react';
import Toolbar from "@mui/material/Toolbar";
import {Person} from "@mui/icons-material";
import {CardActions, Container, Grid, Skeleton, Typography} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import AddBoxIcon from "@mui/icons-material/AddBox";
import {usedCarService} from "../../_services/usedCar.service";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";

const UserAnnonce = () => {

    const [usedCars, setUsedCars] = useState([]);
    const [isDatasLoading, setIsDatasLoading] = useState(true);

    const flag = useRef(false)
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        if(flag.current === false) {
            setIsDatasLoading(true);
            usedCarService.getMyAnnounces()
                .then((data) => {
                    setUsedCars(data.data);
                })
                .catch((err) =>{
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    navigate('/')
                })
                .finally(() => {
                    setIsDatasLoading(false);
                })
        }
        return () => flag.current = true
    }, []);

    const handleDelete = (id) => {
        setIsDatasLoading(true);
        usedCarService.deleteAnnounce(id)
            .then((data) => {

                enqueueSnackbar('Votre annonce est supprimée !', {
                    variant: 'success', // or variant: warning | error | info
                    autoHideDuration: 5000,
                    anchorOrigin : {horizontal: "right", vertical: "top"}

                })

                setUsedCars(usedCars.filter((x) => x.id !== id));
            })
            .catch((err) =>{
                enqueueSnackbar("Une erreur s'est produite !", {
                    variant: 'error', // or variant: warning | error | info
                    autoHideDuration: 5000,
                    anchorOrigin : {horizontal: "right", vertical: "top"}
                })
                navigate('/')
            })
            .finally(() => {
                setIsDatasLoading(false);
            })
    }

    return (
        <>
            <AppBar position="relative" sx={{background:'white', color:'black'}}>
                <Toolbar sx={{ml:10}}>
                    <Person sx={{ mr: 2 }} />
                    <Typography variant="h6" color="inherit" noWrap>
                        Mes annonces
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container maxWidth='lg' sx={{paddingTop:'20px', paddingBottom:'20px'}}>

                {isDatasLoading ? (
                    <Container maxWidth='lg' sx={{paddingTop:'20px', paddingBottom:'20px', background:'transparent'}}>
                        {/* For other variants, adjust the size with `width` and `height` */}
                        <Skeleton variant="rounded" height={200} sx={{ m: 2 }} />
                        <Skeleton variant="rounded" height={200} sx={{ m: 2 }} />
                        <Skeleton variant="rounded" height={200} sx={{ m: 2 }} />
                    </Container>
                ): (
                    <>
                        {usedCars.length > 0 ? (
                            usedCars.map((car) => (
                                <Card sx={{m:3}}>
                                    <CardContent>
                                        <Typography variant="h5" component="div" sx={{fontFamily:'MBCorpoATitle' }}>
                                            {car.brand} | {car.city} | {car.fuel} | {car.price} €
                                        </Typography>
                                        <Typography sx={{ mb: 1.5, fontFamily:'MBCorpoATitle'  }} color="text.secondary">
                                            {car.description.slice(0, 200)} {car.description.length > 201 && '...'}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button onClick={() => handleDelete(car.id)} size="small" sx={{fontFamily:'MBCorpoATitle' }} color={'error'}>Supprimer</Button>
                                    </CardActions>
                                </Card>
                            ))
                        ) : (
                            <Grid item key={'none'} xs={12} sm={12} md={12} lg={12}>

                                <Card>
                                    <CardContent>
                                        <Typography variant="h5" component="div" sx={{fontFamily:'MBCorpoATitle' }}>
                                            Vous n’avez aucune annonce en ligne
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button onClick={() => navigate('/user/occasion/add')} variant="outlined" startIcon={<AddBoxIcon />} sx={{fontFamily:'MBCorpoATitle' }}>
                                            Déposer une annonce
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        )}
                    </>
                )}
            </Container>
        </>
    )
};

export default UserAnnonce;

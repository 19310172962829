import * as React from 'react';
import Wrapper from "../../components/global/Wrapper";
import HeroPage from "../../components/global/HeroPage";
import SecNavbar from "../../components/global/SecNavbar";
import Headline from "../../components/global/Headline";
import Carrousel from "../../components/global/Carrousel";
import {Carrousel1} from "../../data/Carrousel1";
import CarsTabsTest from "../../components/global/CarsTabsTest";
import {useInView} from "react-intersection-observer";
import {useEffect, useState} from "react";
import ImageHeadline from "../../components/global/ImageHeadline";
import {ImageHeadlineData} from "../../data/ImageHeadlineData";
import {HeadlineData} from "../../data/HeadlineData";
import {Carrousel2} from "../../data/Carrousel2";
import {Carrousel3} from "../../data/Carrousel3";
import {Carrousel4} from "../../data/Carrousel4";
import Gallery from "../../components/global/Gallery";
import myImage from "@/assets/images/clacoupe.png";

export default function Home() {

    const {ref, inView } = useInView();
    const [stickNavbar, setStickNavbar] = useState();


    useEffect(() => {

        if(!inView){
            setStickNavbar(true);
        }else{
            setStickNavbar(false);
        }

    },[inView])

    let newClasses = 'SecNavbar';

    if(stickNavbar){
        newClasses += ' stickNavbar';
    }

    return (
        <>
            {/*<Header/>*/}
            {/*<PrincipalNavbar/>*/}
            {/*<Presentation />*/}
            {/*<HomeCarousel />*/}

            <Wrapper background={myImage} >
                <div ref={ref}>
                    <HeroPage/>
                </div>
                <SecNavbar stickyClass={newClasses} />
            </Wrapper>

            <Headline
                title={"Actualité"}
                paragraph={""}
                styleBackground={"black"}
                styleTitle={"white"}
                children={<Carrousel slidesData={Carrousel1} slidesNumber={3} />}
            />

            <Headline
                id={'allModels'}
                title={"Nos modèles"}
                paragraph={""}
                styleBackground={"white"}
                styleTitle={"black"}
                children={<CarsTabsTest  />}
            />

            <Headline title={HeadlineData.exterior.headlineTitle} paragraph={HeadlineData.exterior.headlineParagraph} />
            <Carrousel slidesData={Carrousel1} slidesNumber={3} />
            <ImageHeadline imgheadlineData={ImageHeadlineData.design} />
            <Headline title={HeadlineData.interior.headlineTitle} paragraph={HeadlineData.interior.headlineParagraph} />
            <Carrousel slidesData={Carrousel2} slidesNumber={6} />
            <ImageHeadline imgheadlineData={ImageHeadlineData.interior} />
            <Headline title={HeadlineData.technology.headlineTitle} paragraph={HeadlineData.technology.headlineParagraph} />
            <Carrousel slidesData={Carrousel3} slidesNumber={4} />
            <ImageHeadline imgheadlineData={ImageHeadlineData.technology} />
            <Headline title={HeadlineData.safety.headlineTitle} paragraph={HeadlineData.safety.headlineParagraph} />
            <Carrousel slidesData={Carrousel4} slidesNumber={4} />
            <ImageHeadline imgheadlineData={ImageHeadlineData.safety} />

            <Gallery />

        </>
    );
}
import c2_image1 from '@/assets/images/carrousels/carrousel2-1.jpg';
import c2_image2 from '@/assets/images/carrousels/carrousel2-2.jpg';
import c2_image3 from '@/assets/images/carrousels/carrousel2-3.jpg';
import c2_image4 from '@/assets/images/carrousels/carrousel2-4.jpg';
import c2_image5 from '@/assets/images/carrousels/carrousel2-5.jpg';
import c2_image6 from '@/assets/images/carrousels/carrousel2-6.jpg';


export const Carrousel2 = [
    {
      title: 'Cockpit et volant',
      paragraph: 'La section avant frappante dégage plus que jamais un air de confiance en soi et d\'attrait en même temps. Le look énergique typique et donc la valeur de reconnaissance sont soulignés par la nouvelle génération de phares - avec une apparence high-tech impressionnante.',
      imageURL: c2_image1
    },
    {
      title: 'Portes',
      paragraph: 'Le design axé sur la surface donne à la vue latérale de la Classe A Berline son aura particulière. Très peu d\'évidements dans la carrosserie allongée - un design puriste qui suscite des émotions. Avec l\'élégante ligne de toit comme point fort supplémentaire.',
      imageURL: c2_image2
    },
    {
      title: 'Console centrale',
      paragraph: 'Les feux arrière en deux parties soulignent la partie arrière claire qui met l\'accent sur la largeur. Les réflecteurs arrière ont été déplacés vers le pare-chocs arrière modulaire en deux parties. Selon l\'équipement choisi, ce pare-chocs arrière est disponible avec un diffuseur noir ou avec une baguette décorative chromée et des sorties d\'échappement chromées.',
      imageURL: c2_image3
    },
    {
      title: 'Concept de commande et d\'affichage',
      paragraph: 'Les feux arrière en deux parties soulignent la partie arrière claire qui met l\'accent sur la largeur. Les réflecteurs arrière ont été déplacés vers le pare-chocs arrière modulaire en deux parties. Selon l\'équipement choisi, ce pare-chocs arrière est disponible avec un diffuseur noir ou avec une baguette décorative chromée et des sorties d\'échappement chromées.',
      imageURL: c2_image4
    },
    {
      title: 'L\'éclairage intérieur',
      paragraph: 'Les feux arrière en deux parties soulignent la partie arrière claire qui met l\'accent sur la largeur. Les réflecteurs arrière ont été déplacés vers le pare-chocs arrière modulaire en deux parties. Selon l\'équipement choisi, ce pare-chocs arrière est disponible avec un diffuseur noir ou avec une baguette décorative chromée et des sorties d\'échappement chromées.',
      imageURL: c2_image5
    },
    {
      title: 'MBUX',
      paragraph: 'Les feux arrière en deux parties soulignent la partie arrière claire qui met l\'accent sur la largeur. Les réflecteurs arrière ont été déplacés vers le pare-chocs arrière modulaire en deux parties. Selon l\'équipement choisi, ce pare-chocs arrière est disponible avec un diffuseur noir ou avec une baguette décorative chromée et des sorties d\'échappement chromées.',
      imageURL: c2_image6
    },
  ];
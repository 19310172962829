import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Card, CardContent, CardMedia, Container, Divider, Skeleton} from "@mui/material";
import Grid from "@mui/material/Grid";
import {useNavigate} from "react-router-dom";
import {userService} from "@/_services/user.service";
import {styled, useTheme} from '@mui/material/styles';
import Paper from "@mui/material/Paper";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import iris from '@/images/noCar.png';
import fond from '@/images/fond.png';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import SettingsIcon from '@mui/icons-material/Settings';
import CardActionArea from '@mui/material/CardActionArea';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import {personalCarService} from "@/_services/personalCar.service";
import Toolbar from "@mui/material/Toolbar";
import {ListAltOutlined, Person} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AppBar from "@mui/material/AppBar";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import "@/css/slick/slick.css";
import {useSnackbar} from "notistack";


const OwningContent = () => {

    const [user, setUser] = useState([]);
    const [personalCar, setPersonalCar] = useState(null);
    const [isUserLoading, setIsUserLoading] = useState(true);
    const [isCarLoading, setIsCarLoading] = useState(true);

    const flag = useRef(false)
    const theme = useTheme();
    let navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if(flag.current === false)
        {
            setIsUserLoading(true);
            setIsCarLoading(true);

            userService.getMe()
                .then((data) => {
                    setUser(data.data);
                })
                .catch((err) =>{
                    enqueueSnackbar("Une erreur s'est produite !", {
                        variant: 'error', // or variant: warning | error | info
                        autoHideDuration: 5000,
                        anchorOrigin : {horizontal: "right", vertical: "top"}
                    })
                    navigate('/')
                })
                .finally(() => {
                    setIsUserLoading(false);
                })

            personalCarService.getMyPersonalCar()
                .then((data) => {
                    setPersonalCar(data.data);
                })
                .catch((err) =>{
                    console.log(err)
                    if (err.response.status !== 404)
                    {
                        enqueueSnackbar("Une erreur s'est produite !", {
                            variant: 'error', // or variant: warning | error | info
                            autoHideDuration: 5000,
                            anchorOrigin : {horizontal: "right", vertical: "top"}
                        })
                    }
                })
                .finally(() => {
                    setIsCarLoading(false);
                })
        }

        return () => flag.current = true
    }, []);

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    function formatDate(newDate) {
        const months = {
            0: 'Janvier',
            1: 'Février',
            2: 'Mars',
            3: 'Avril',
            4: 'Mai',
            5: 'Juin',
            6: 'Juillet',
            7: 'Août',
            8: 'Septembre',
            9: 'Octobre',
            10: 'Novembre',
            11: 'Décembre',
        }
        const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']

        const d = new Date(newDate)
        const year = d.getFullYear()
        const date = d.getDate()
        const monthIndex = d.getMonth()
        const monthName = months[d.getMonth()]
        const dayName = days[d.getDay()] // Thu
        const dayNumber = d.getDay() // Thu
        const formatted = `${date} ${monthName} ${year}`

        return formatted.toString()
    }

    if (isUserLoading || isCarLoading) {
        return (
            <Container maxWidth='lg' sx={{paddingTop:'20px', paddingBottom:'20px', background:'transparent'}}>
                {/* For other variants, adjust the size with `width` and `height` */}
                <Skeleton variant="rounded" height={200} sx={{ m: 2 }} />
                <Box sx={{display:'flex', justifyContent:'space-evenly'}}>
                    <Skeleton variant="circular" width={50} height={50} sx={{ m: 2 }} />
                    <Skeleton variant="circular" width={50} height={50} sx={{ m: 2 }} />
                    <Skeleton variant="circular" width={50} height={50} sx={{ m: 2 }} />
                </Box>
                <Skeleton variant="rounded" height={150} sx={{ m: 2 }} />
            </Container>
        );
    }

    return (
        <>
            <AppBar position="relative" sx={{background:'white', color:'black', boxShadow:'none', borderBottom:'1px solid #e3e3e3'}}>
                <Toolbar>
                    <Person sx={{ mr: 2 }} />
                    <Typography variant="div" color="inherit" noWrap>
                        Tableau de bord
                    </Typography>
                    <Divider orientation="vertical" flexItem sx={{margin:2}} />
                    <Typography variant="div" color="inherit" noWrap>
                            {personalCar ? (
                                    <>
                                        <IconButton aria-label="delete" size="large" sx={{padding:'5px',borderRadius:'inherit'}}>
                                            <EditIcon sx={{color:'blue'}} onClick={() => {navigate('/user/dashboard/editPersonalCar')} } fontSize="inherit"/>
                                        </IconButton>
                                        <IconButton aria-label="delete" size="large" sx={{padding:'5px',borderRadius:'inherit'}}>
                                            <DeleteForeverIcon color={'error'} onClick={() => {navigate('/user/dashboard/deletePersonalCar/'+personalCar.id)} } fontSize="inherit"/>
                                        </IconButton>
                                    </>
                                ) : (
                                <IconButton aria-label="delete" size="large" sx={{padding:'5px',borderRadius:'inherit'}}>
                                    <AddBoxIcon onClick={() => {navigate('/user/dashboard/addPersonalCar')} } fontSize="inherit"/>
                                </IconButton>
                                )
                            }
                    </Typography>
                </Toolbar>
            </AppBar>

            <Box sx={{background:'#f4f4f4'}}>
                <Grid container spacing={2} >
                    <Grid item xs={12} md={12} lg={12} sx={{}}>

                        <Item sx={{textAlign:'start',padding:'5px', boxShadow:'0 0 2px 0 rgba(0,0,0,0.1),0 4px 8px 0 rgba(0,0,0,0.1)'}}>

                            <Button size="large" sx={{color:"#959595",textTransform:'none',fontFamily:"MBCorpoATitle", background:'#e8e8e8'}}>
                                Mes véhicules
                            </Button>
                            <Button onClick={() => navigate('/user/dashboard/considering')} size="large" sx={{color:"#959595",textTransform:'none',fontFamily:"MBCorpoATitle"}}>
                                Votre prochain véhicule
                            </Button>

                        </Item>
                    </Grid>
                </Grid>

                {personalCar ? (
                    <>
                        <Container maxWidth='xl'  sx={{paddingTop:'20px', paddingBottom:'20px',backgroundRepeat: 'no-repeat', backgroundImage: `url(${fond})`, backgroundSize: 'contain'}}>

                            <Slider {...settings}>
                                <div>
                                    <img
                                        src={iris}
                                        style={{width:'inherit'}}
                                    />
                                    <div style={{textAlign:'center'}}>
                                        <p>configuration</p>
                                        <p>{personalCar.model.name}</p>
                                        <p>{personalCar.brand}</p>
                                    </div>
                                </div>
                            </Slider>

                        </Container>

                        <Container maxWidth='xl' sx={{paddingTop:'20px', paddingBottom:'20px',backgroundImage : {fond} }}>

                            <Box>
                                <Grid container spacing={3}>
                                    <Grid item xs={4} md={4} lg={4}>
                                        <Item sx={{display:'flex',justifyContent:'end',alignItems:'center',background:'transparent',boxShadow:'none'}}>
                                            <ElectricCarIcon sx={{fontSize:'1rem', marginRight:1}} />
                                            <span><b>{personalCar.mileage} kilomètre</b></span>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={4} md={4} lg={4}>
                                        <Item sx={{display:'flex',justifyContent:'center',alignItems:'center',background:'transparent',boxShadow:'none'}}>
                                            <ListAltOutlined sx={{fontSize:'1rem', marginRight:1}} />
                                            <span><b>{personalCar.model.name}</b></span>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={4} md={4} lg={4}>
                                        <Item sx={{display:'flex',justifyContent:'start',alignItems:'center',background:'transparent',boxShadow:'none'}}>
                                            <SettingsIcon sx={{fontSize:'1rem', marginRight:1}} />
                                            <span><b>{personalCar.gearbox}</b></span>
                                        </Item>
                                    </Grid>
                                </Grid>
                                <Typography component="p" sx={{textAlign:'center',fontSize:'0.75rem',color:'#767676'}}>
                                    Consommation combinée: 4,6 l/100km (WLTP); Émissions totales de CO₂ (combinées): 120 g/km (WLTP)
                                </Typography>
                            </Box>

                            <Box>
                                <Grid item xs={12} md={6}>
                                    <CardActionArea component="div" >
                                        <Card sx={{}}>
                                            <CardContent sx={{}}>
                                                <Typography component="span" variant="subtitle1" sx={{fontSize:'1rem',lineHeight:'1.5rem', color:'#767676'}}>
                                                    <div className='citdiv'>
                                                        <blockquote>
                                                            {personalCar.description}
                                                            <cite><i>{user.firstname}</i> - description de ma petite voiture</cite>
                                                        </blockquote>
                                                    </div>
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </CardActionArea>
                                </Grid>
                            </Box>

                        </Container>
                    </>
                ) : (
                    <Container maxWidth='lg' sx={{paddingTop:'20px', paddingBottom:'20px',backgroundImage: `url(${fond})`,backgroundRepeat: 'no-repeat' }}>
                        <Box>
                            <Grid item xs={12} md={6}>
                                <CardActionArea component="a" href="#">
                                    <Card sx={{ display: {lg:'flex',xs:'block'} }}>
                                        <CardContent sx={{ flex: 1 }}>
                                            <Typography component="span" variant="h5" sx={{fontSize:'1rem',lineHeight:'1.5rem', color:'#767676', marginRight:2, fontFamily:'MBCorpoATitle'}}>
                                                Ajouter votre véhicule
                                            </Typography>
                                            <br/>
                                            <br/>
                                            <Typography component="span" variant="h5" sx={{fontSize:'1rem',lineHeight:'1.5rem', color:'#767676'}}>
                                                <b>Infinity-Life me</b> vous connecte à votre véhicule et connecte votre véhicule à tout ce qui est important.
                                            </Typography>
                                        </CardContent>
                                        <CardMedia sx={{textAlignLast:'center',alignSelf:'center', margin:1}}>
                                            <Button
                                                variant="contained"
                                                sx={{borderRadius:'inherit'}}
                                                startIcon={<CarRepairIcon />}
                                                size="large"
                                                onClick={() => {navigate('/user/dashboard/addPersonalCar')}}
                                            >
                                                Ajouter un véhicule personnel
                                            </Button>
                                        </CardMedia>
                                    </Card>
                                </CardActionArea>
                            </Grid>
                        </Box>
                    </Container>
                )}

            </Box>
        </>
    );
}

export default OwningContent;